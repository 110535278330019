import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";

export class InstrumentationDesignEngineering extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
          Instrumentation Design Training, Course Online in India
          </title>
          <meta name="description"
            content="MECCI Engineers provides the best online & offline Instrumentation Design Engineering Training Courses in India, Sudan, Nigeria,   Oman, Qatar, UAE & more countries"/>
          <meta
            name="keywords"
            content="instrumentation Design Engineering Training, instrumentation Engineering Training Institute, instrumentation design Engineering Course Instrumentation Design Training, instrumentation Design Engineering Course, instrumentation Design Engineering Course, instrumentation design engineering Courses,  Courses in instrumentation design Engineering, instrumentation Systems Designing Course"
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/instrumentation-design-engineering"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Instrumentation Design Engineering Course"
            imgsrc="assets/images/course/course-title-img/instrumentation-design-engineering-course.jpg"
            alt="instrumentation-design-engineering-course"
            title="Instrumentation Design Engineering Course"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  Instrumentation term is commonly used in engineering, which
                  means measurement and control for industrial process systems.
                  Various processes in petrochemical, Oil & Gas, Power
                  industries need to be maintained at controlled levels to get
                  the desired product. It is commonly done by controlling such
                  process variables as pressure, temperature, and liquid level;
                  using measurement devices (instrument) with typical control
                  systems.
                </p>
                <p>
                  Instrumentation plays a significant role in both gathering
                  information from the field and changing the field parameters
                  An Instrumentation engineer is responsible for designing,
                  developing, installing, managing and/or maintaining equipment
                  which is used to monitor and control engineering systems,
                  machinery and processes. Instrumentation engineer ensure that
                  these systems and processes operate effectively, efficiently
                  and safely.
                </p>
                <hr />
                <Table
                  caption="Offered Courses in Instrumentation Design Engineering"
                  disctitle="Course Related to Instrumentation Detail Design"
                  disc1="Instrumentation Design Engineering (Full Time)"
                  pdf1="assets/images/course/courses-pdf/instrumentation-design-and-detailed engineering.pdf"
                  duration1="45 Days"
                  disc2="Instrumentation Design Engineering (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/instrumentation-design-and-detailed engineering.pdf"
                  duration2="3 Months"
                  disc3="Instrumentation Design Engineering (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/instrumentation-design-and-detailed engineering.pdf"
                  duration3="8 Days"
                  disc4="Instrumentatin Design Engineering (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/instrumentation-design-and-detailed engineering.pdf"
                  duration4="1 Year"
                />

                <hr />

                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul>
                  <li>Overview of EPC Industry and Scope.</li>
                  <li>
                    Role of Instrumentation Engineer in various types of
                    Industry.
                  </li>
                  <li>
                    Knowing Client requirements and collection of specific data
                    for projects.
                  </li>
                  <li>Relevant Codes & Standards.</li>
                  <li>
                    Basic Design requirement based on the type of plant e.g.
                    Chemical, Petrochemical, Pharmaceutical Industrial, power
                    plant etc.
                  </li>
                  <li>
                    Selection of Instruments for Controlling Flow, Temperature,
                    level and Pressure.
                  </li>
                  <li>
                    Vendor's details and specification for all Instruments used
                    to control Flow, Level, Temperature and Pressure
                  </li>
                  <li>Installation and maintenance Tips of all Instruments.</li>
                  <li>Instrument Index.</li>
                  <li>Instrument Location Plan Details.</li>
                  <li>Process Data sheets and Specifications.</li>
                  <li>Instrument Data Sheets.</li>
                  <li>Instrument Wiring Layout.</li>
                  <li>Logic Diagrams, Loop Wiring Diagram</li>
                  <li>Loop Drawing, Hook-Up Drawing</li>
                  <li>JB Layout, Cable Tray Layout</li>
                  <li>Cable Schedule</li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>EPC Project Based Training.</li>
                  <li>
                    Providing all Training Material and technical Drawing,
                    documents and case study materials.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default InstrumentationDesignEngineering;
