import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";

export class ERPSAPHANATraining extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best ERP SAP HANA Training Course</title>
          <meta
            name="description"
            content="Best ERP SAP HANA Training Course"
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/erp-sap-hana-training"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Best ERP SAP HANA Training Course"
            imgsrc="assets/images/course/course-title-img/erp-sap-hana-training-course.jpg"
            alt="ERP-SAP-HANA-Training"
            title="Best ERP SAP HANA Training Course"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  Are you Looking Best Training institute for ERP SAP HANA
                  Course in Noida / Delhi? We provide end to end learning on SAP
                  HANA ERP with deeper dives for creating a great career for
                  every profile. Learn how to use ERP SAP HANA from beginner
                  level to advanced techniques which is taught by experienced
                  working professionals.
                </p>
                <p>
                  ERP SAP HANA Training course provide Modeler, Administration
                  View, Navigator, Data Reporting, Data Provisioning or Data
                  Loading, User Management and Security, COPA Accelerator, ABAP
                  to SAP HANA etc.
                </p>

                <hr />
                <Table
                  caption="Offered Courses in ERP SAP HANA Training course"
                  disctitle="Course Related to ERP SAP HANA Training"
                  disc1="ERP SAP HANA Training course (Full Time)"
                  pdf1="assets/images/course/courses-pdf/erp-sap-hana-training.pdf"
                  duration1="45 Days"
                  disc2="ERP SAP HANA Training course (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/erp-sap-hana-training.pdf"
                  duration2="3 Months"
                  disc3="ERP SAP HANA Training course (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/erp-sap-hana-training.pdf"
                  duration3="8 Days"
                  disc4="ERP SAP HANA Training course (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/erp-sap-hana-training.pdf"
                  duration4="6 Months"
                />
                <hr />
                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul>
                  <li>Introduction to ERP SAP Simple Finance HANA</li>
                  <li>SAP HANA Deployment Options and Architecture</li>
                  <li>Overview of Simple Finance</li>
                  <li>Interfaces for Central Finance</li>
                  <li>GL and Cost Elements</li>
                  <li>Managing Ledgers</li>
                  <li>New Asset Accounting</li>
                  <li>CO-Module in Simple Finance</li>
                  <li>Interfaces SAP HANA</li>
                  <li>Cash Management</li>
                  <li>Integrated Business Planning for Finance</li>
                  <li>Project Preparation Phase</li>
                  <li>Migration to Simple Finance Add-on</li>
                  <li>Installation Phase Activities</li>
                  <li>Customization Phase â€“ Asset Accounting (New)</li>
                  <li>Customization Phase â€“ Controlling</li>
                  <li>Data Migration Phase</li>
                  <li>Post Processing Phase</li>
                  <li>Reporting Options</li>
                  <li>
                    Execute SAP Simple Finance HANA Optimized Reporting &
                    Transactions
                  </li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>
                    Help employees clearly understand what training programs can
                    speed up their career progress in their desired direction.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ERPSAPHANATraining;
