import React, { Component } from "react";
import { Link } from "react-router-dom";
import mecciService, {
  nonItTraining
} from "../pages/CourseDataLink";
export class SidebarNonItTraining extends Component {
  render() {
    return (
      <>
        <div className="colleft accordion"
          data-toggle="collapse"
          data-target="#nonittra"
        >
        <h3>NON - IT TRAINING</h3>
        <div id="nonittra" className="collapse">
        <ul className="listunstyled">
		    <li><Link to={`/${nonItTraining[0].link}`} target="_blank">{nonItTraining[0].title}</Link></li>
			<li><Link to={`/${nonItTraining[1].link}`} target="_blank">{nonItTraining[1].title} </Link></li>
			<li><Link to={`/${nonItTraining[2].link}`} target="_blank">{nonItTraining[2].title}</Link></li>
			<li><Link to={`/${nonItTraining[3].link}`} target="_blank">{nonItTraining[3].title} </Link></li>
			<li><Link to={`/${nonItTraining[4].link}`} target="_blank">{nonItTraining[4].title}  </Link></li>
			<li><Link to={`/${nonItTraining[5].link}`} target="_blank">{nonItTraining[5].title}</Link></li>

			<li><Link to={`/${nonItTraining[6].link}`} target="_blank">{nonItTraining[6].title}</Link></li>
			<li><Link to={`/${nonItTraining[7].link}`} target="_blank">{nonItTraining[7].title} </Link></li>
			<li><Link to={`/${nonItTraining[8].link}`} target="_blank">{nonItTraining[8].title}</Link></li>
			<li><Link to={`/${nonItTraining[9].link}`} target="_blank">{nonItTraining[9].title} </Link></li>
			<li><Link to={`/${nonItTraining[10].link}`} target="_blank">{nonItTraining[10].title}  </Link></li>
			<li><Link to={`/${nonItTraining[11].link}`} target="_blank">{nonItTraining[11].title}</Link></li>
			<li><Link to={`/${nonItTraining[12].link}`} target="_blank">{nonItTraining[12].title}</Link></li>
			<li><Link to={`/${nonItTraining[13].link}`} target="_blank">{nonItTraining[13].title}</Link></li>
			<li><Link to={`/${nonItTraining[14].link}`} target="_blank">{nonItTraining[14].title}</Link></li>
			{/* <li><Link to={`/${nonItTraining[15].link}`} target="_blank">{nonItTraining[15].title}</Link></li> */}
			
		</ul>

          </div>
      </div>
      </>
    );
  }
}

export default SidebarNonItTraining;
