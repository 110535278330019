import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import GallaryData from "../pages/GallaryData";
import { Helmet } from "react-helmet";
export class Gallary extends Component {
  render() {
    const thumb = {
      marginTop: "5px",
      marginBottom: "5px",
    };

    return (
      <>
      <Helmet>
          <link rel="canonical" href="https://www.mecciengineer.com/gallary"/>
        </Helmet>
        <Helmet>
          <title>Gallary</title>
          <meta name="description" content="Gallary" />
        </Helmet>
        
        <div className="container-fluid">
          <PageTitle
            page_header_title="Gallary"
            imgsrc="assets/images/course/course-title-img/blog-gallery.jpg"
            alt="gallary"
            title="Gallary"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="row">
                {GallaryData.map((gallary) => (
                  <div
                    className="col-lg-6 col-md-6 col-xs-6 {thumb}"
                    key={gallary.id}
                  >
                    <a
                      className="thumbnail"
                      href="#"
                      data-image-id=""
                      data-toggle="modal"
                      data-title={gallary.id}
                      data-image={gallary.imgsrc}
                      data-target="#image-gallery"
                    >
                      <img
                        className="img-thumbnail"
                        src={gallary.imgsrc}
                        alt={gallary.id}
                      />
                    </a>
                  </div>
                ))}
              </div>
              <div
                className="modal fade"
                id="image-gallery"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title" id="image-gallery-title"></h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                      >
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <img
                        id="image-gallery-image"
                        className="img-responsive col-md-12"
                        src=""
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary float-left"
                        id="show-previous-image"
                      >
                        <i className="fa fa-arrow-left"></i>
                      </button>

                      <button
                        type="button"
                        id="show-next-image"
                        className="btn btn-secondary float-right"
                      >
                        <i className="fa fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Gallary;
