import React,{Component} from "react";
import { Link } from "react-router-dom";
import Form from "../components/Contact";
import mecciService, {epcTraining, corporateServices,itTraining,nonItTraining} from "../pages/CourseDataLink";
class Footer extends React.Component{
    render(){
        return(
<>


<footer>
<div className="container-fluid">
<div className="row">
	<div className="col-md-2 col-sm-6">
	   <h3>MECCI SERVICES</h3>
		<ul className="listunstyled">
			<li><Link to={mecciService[0].link} target="_blank">{mecciService[0].title}</Link></li>
			<li><Link to={mecciService[1].link} target="_blank">{mecciService[1].title} </Link></li>
			<li><Link to={mecciService[2].link} target="_blank">{mecciService[2].title}</Link></li>
			<li><Link to={mecciService[3].link} target="_blank">{mecciService[3].title} </Link></li>
			<li><Link to={mecciService[4].link} target="_blank">{mecciService[4].title}  </Link></li>
			<li><Link to={mecciService[5].link} target="_blank">{mecciService[5].title}</Link></li>
		</ul>
	</div>
	<div className="col-md-2 col-sm-6">
			<h3>CORPORATE SERVICES</h3>
			<ul className="listunstyled">
		    <li><Link to={corporateServices[0].link} target="_blank">{corporateServices[0].title}</Link></li>
			<li><Link to={corporateServices[1].link} target="_blank">{corporateServices[1].title} </Link></li>
			<li><Link to={corporateServices[2].link} target="_blank">{corporateServices[2].title}</Link></li>
			<li><Link to={corporateServices[3].link} target="_blank">{corporateServices[3].title} </Link></li>
			<li><Link to={corporateServices[4].link} target="_blank">{corporateServices[4].title}  </Link></li>
			<li><Link to={corporateServices[5].link} target="_blank">{corporateServices[5].title}</Link></li>
			
		</ul>
	</div>
	<div className="col-md-2 col-sm-6">
			<h3>IT TRAINING</h3>
			<ul className="listunstyled">
		    <li><Link to={itTraining[0].link} target="_blank">{itTraining[0].title}</Link></li>
			<li><Link to={itTraining[1].link} target="_blank">{itTraining[1].title} </Link></li>
			<li><Link to={itTraining[2].link} target="_blank">{itTraining[2].title}</Link></li>
			<li><Link to={itTraining[3].link} target="_blank">{itTraining[3].title} </Link></li>
			<li><Link to={itTraining[4].link} target="_blank">{itTraining[4].title}  </Link></li>
			<li><Link to={itTraining[5].link} target="_blank">{itTraining[5].title}</Link></li>
			<li><Link to={itTraining[6].link} target="_blank">{itTraining[6].title}</Link></li>
			<li><Link to={itTraining[7].link} target="_blank">{itTraining[7].title}</Link></li>
			
		</ul>
	</div>
	<div className="col-md-2 col-sm-6">
			<h3>NON IT TRAINING</h3>
			<ul className="listunstyled epc2">
		    <li><Link to={nonItTraining[0].link} target="_blank">{nonItTraining[0].title}</Link></li>
			<li><Link to={nonItTraining[1].link} target="_blank">{nonItTraining[1].title} </Link></li>
			<li><Link to={nonItTraining[2].link} target="_blank">{nonItTraining[2].title}</Link></li>
			<li><Link to={nonItTraining[3].link} target="_blank">{nonItTraining[3].title} </Link></li>
			<li><Link to={nonItTraining[4].link} target="_blank">{nonItTraining[4].title}  </Link></li>
			<li><Link to={nonItTraining[5].link} target="_blank">{nonItTraining[5].title}</Link></li>

			<li><Link to={nonItTraining[6].link} target="_blank">{nonItTraining[6].title}</Link></li>
			<li><Link to={nonItTraining[7].link} target="_blank">{nonItTraining[7].title} </Link></li>
			<li><Link to={nonItTraining[8].link} target="_blank">{nonItTraining[8].title}</Link></li>
			<li><Link to={nonItTraining[9].link} target="_blank">{nonItTraining[9].title} </Link></li>
			<li><Link to={nonItTraining[10].link} target="_blank">{nonItTraining[10].title}  </Link></li>
			<li><Link to={nonItTraining[11].link} target="_blank">{nonItTraining[11].title}</Link></li>
			<li><Link to={nonItTraining[12].link} target="_blank">{nonItTraining[12].title}</Link></li>
			<li><Link to={nonItTraining[13].link} target="_blank">{nonItTraining[13].title}</Link></li>
			<li><Link to={nonItTraining[14].link} target="_blank">{nonItTraining[14].title}</Link></li>
			{/* <li><Link to={nonItTraining[15].link} target="_blank">{nonItTraining[15].title}</Link></li> */}
			
		</ul>
	</div>
	<div className="col-md-2 col-sm-6">
	<h3>EPC TRAINING </h3>
		<ul className="listunstyled epc">
		    <li><Link to={epcTraining[0].link} target="_blank">{epcTraining[0].title}</Link></li>
			<li><Link to={epcTraining[1].link} target="_blank">{epcTraining[1].title} </Link></li>
			<li><Link to={epcTraining[2].link} target="_blank">{epcTraining[2].title}</Link></li>
			<li><Link to={epcTraining[3].link} target="_blank">{epcTraining[3].title} </Link></li>
			<li><Link to={epcTraining[4].link} target="_blank">{epcTraining[4].title}  </Link></li>
			<li><Link to={epcTraining[5].link} target="_blank">{epcTraining[5].title}</Link></li>
			<li><Link to={epcTraining[6].link} target="_blank">{epcTraining[6].title}</Link></li>
			<li><Link to={epcTraining[7].link} target="_blank">{epcTraining[7].title} </Link></li>
			<li><Link to={epcTraining[8].link} target="_blank">{epcTraining[8].title}</Link></li>
			<li><Link to={epcTraining[9].link} target="_blank">{epcTraining[9].title} </Link></li>
			<li><Link to={epcTraining[10].link} target="_blank">{epcTraining[10].title}  </Link></li>
			<li><Link to={epcTraining[11].link} target="_blank">{epcTraining[11].title}</Link></li>
			<li><Link to={epcTraining[12].link} target="_blank">{epcTraining[12].title}</Link></li>
		</ul>
	</div>
	<div className="col-md-2 col-sm-6">
		<h3><Link to="9://goo.gl/maps/TEfDKQw254D27x2t7" target="_blank" className="fadd">MECCI Engineers Pvt. Ltd.</Link></h3>
		<p><i className="fa fa-map-pin"></i> E-49, 1st Floor, Sector 3,
		<br/> Noida - 201301, U.P. (India)<br/>
		Near Sector - 16 Metro Station</p>
		<p className="contact"><i className="fa fa-phone"></i> 0120-4157540</p>
		<p className="contact"><i className="fa fa-mobile-phone"></i> <Link to="tel:+91-9910988623" className="fadd">+91-9910988623</Link></p>
		<p className="contact"><i className="fa fa-whatsapp"></i> <Link to="tel:+91-9958153868" className="fadd">+91-9958153868</Link></p>
		<p className="contact"><i className="fa fa-envelope"></i> <Link to="mailto:info@mecciengineer.com" className="fadd">info@mecciengineer.com</Link></p>
	</div>
	<div className="socialMedia">
		<Link to={mecciService[5].link} target="_blank" rel="nofollow noopener noreferrer">
		<img src="assets/images/facebook.png" alt="Best Best Training Institute for IT training in Noida" /></Link>
		<Link to={mecciService[5].link} target="_blank" rel="nofollow noopener noreferrer">
		<img src="assets/images/twitter.png" alt="Best Best Training Institute for IT training in Noida" /></Link>
		<Link to={mecciService[5].link} target="_blank" rel="nofollow noopener noreferrer">
		<img src="assets/images/google.png" alt="Best Best Training Institute for IT training in Noida" /></Link>
		<Link to={mecciService[5].link} target="_blank" rel="nofollow noopener noreferrer">
		<img src="assets/images/linkedin.png" alt="Best Best Training Institute for IT training in Noida" /></Link>
		<Link to={mecciService[5].link} target="_blank" rel="nofollow noopener noreferrer">
		<img src="assets/images/pinterest.png" alt="Best Best Training Institute for IT training in Noida" /></Link>
	</div>
	<div className="clearfix"></div>
	<div className="cright">
	  Copyrights <sup>&copy;</sup> 2021. MECCI Engineers Pvt. Ltd. All rights reserved. The certification names are the trademarks of their respective owners.&#8594;
	 <Link to={mecciService[5].link} title="Disclaimer" target="_blank" style={{color: '#fe990c'}} >View disclaimer</Link> 
    </div>
</div>
<div className="footer_callnow">
	<div>
	<div className="textwidget">
	   <Link to="tel:+919910988623">
	   <span style={{fontSize: '22px'}}>Call Now: +919910988623</span></Link>
	</div>
	</div> 
</div>
</div>
</footer>
<a href="https://api.whatsapp.com/send?phone=+919958153868&amp;text=Hi!%20Can%20I%20connect%20with%20MECCI%20ENGINEER" target="_blank" className="tap"><i className="fa fa-whatsapp my-tap"></i>
</a>

<div className="clickToEnquiry">
	<a href="#enquiryLight" className="clickLight">
		<img src="assets/images/enquiry.png" alt="Best Training Institute for Best Training Institute for IT Courses in Noida" /><span>Enquiry Now</span>
	</a>
</div>

<div id="overlay" className="blank"></div>
<div id="lightBox" className="blank">
	<Link to=""  className="closeLight">
		<img src="assets/images/close.png" alt="Best Training Institute for IT training institute & Company in Noida"/>
	</Link>
	<div className="lightContent" id="enquiryLight">
	<h2>Fill This Form For Online Professional Training Courses and Certification</h2>
		<div id="enquiryContent">
		<Form />
		</div>
	</div>
</div>

<div id="backgroundPopup"></div>
<div id="toPopup" style= {{height:'570px'}}>
<div className="close"></div>
    <div id="popup_content">
        <div className="mailLight">
        <div className="form_heading" id="heading"> Course Enquiry</div>
        <div className="form_heading" id="alertmsg" style={{color: '#00FF00',display:'none'}} ></div>
            <div className="form">
                <div className="slideup">
                <p className="text-center">Please Fill This Form For Online Professional Training Courses and Certification</p><br/>
				<Form />
                </div>
            </div>
        </div>
    </div>
</div>

</>
        )
    }
}
export default Footer; 