import { useState, useEffect } from "react";
import axios from "axios";

export default function SidebarRecentPlaceStudent() {

  const [placements, setPlacement] = useState([]);

  useEffect(() => {
    getAllPlacement();
  }, []);

  async function getAllPlacement() {
    try {
      const res = await axios.get(
        "https://www.mecciengineer.com/mecci/public/api/allplacement"
      );
      //console.log(res.data.placements);
      setPlacement(res.data.placements);
    } catch (error) {
      console.log("something wrong");
      console.log(error.message);
    }
  }
  let url ="https://www.mecciengineer.com/mecci/public/imgpath1/";
  let count =1;
        return (
            <>
<div className="colleft">
	<h3>Recent Placed Students </h3>
	<div className="placedStudent text-center">
		<div className="placedStudentImg">
			<div id="myCarousel" className="carousel slide" data-ride="carousel" data-interval="4500">
				<div className="carousel-inner" role="listbox">
       
				{placements.map((couritm1) => (
				
					<div className={"item " + ((couritm1.id ==28) ? "active": "")} key={couritm1.id}>
					
					<img src={url+couritm1.image} alt="best1" />
				    </div>


					))}
					
				</div>
			</div>
		</div>
	</div>
</div>  
            </>
        )
    }



