import React, { Component } from "react";
import SidebarUpcommingBatch from "./SidebarUpcommingBatch";
import SidebarRecentPlaceStudent from "./SidebarRecentPlaceStudent";
import SidebarGallery from "./SidebarGallery";
import SidebarMecciServicesHome from "./SidebarMecciServicesHome";
import SidebarEpcTrainingHome from "./SidebarEpcTrainingHome";
import SidebarCorporateServicesHome from "./SidebarCorporateServicesHome";
import SidebarItTraningHome from "./SidebarItTraningHome";
import SidebarNonItTrainingHome from "./SidebarNonItTrainingHome";
//import SidebarGallery from './SidebarGallery';
export class HomeSideBar extends Component {
  render() {
    return (
      <>
        
        <SidebarUpcommingBatch />
        <SidebarRecentPlaceStudent />
        <SidebarGallery />
        <SidebarMecciServicesHome />
        <SidebarEpcTrainingHome />
        <SidebarCorporateServicesHome />
        <SidebarItTraningHome />
        <SidebarNonItTrainingHome />

      </>
    );
  }
}

export default HomeSideBar;
