import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Testimonials from "./components/Testimonials";
import Gallary from "./pages/Gallary";
import MecciServices from "./pages/MecciServices";
import Epctraining from "./pages/Epctraining";
import WhyMecci from "./pages/WhyMecci";
import SubstationDesignCourse from "./pages/SubstationDesignCourse";
import SolarStructureDesignCourse from "./pages/SolarStructureDesignCourse";
import SolarPowerPlantDesignEngineering from "./pages/SolarPowerPlantDesignEngineering";
import ProjectManagementControl from "./pages/ProjectManagementControl";
import ProcessDesignWorkshop from "./pages/ProcessDesignWorkshop";
import ProcessDesignEngineering from "./pages/ProcessDesignEngineering";
import PlanningPackage from "./pages/PlanningPackage";
import PlanningEngineeringTraining from "./pages/PlanningEngineeringTraining";
import PipingDesignEngineering from "./pages/PipingDesignEngineering";
import OnlineClassroomTraining from "./pages/OnlineClassroomTraining";
import ManpowerServices from "./pages/ManpowerServices";
import InstrumentationDesignEngineering from "./pages/InstrumentationDesignEngineering";
import IlluminationSystemsDesigningCourse from "./pages/IlluminationSystemsDesigningCourse";
import HybridElectricVehicleDesignCourse from "./pages/HybridElectricVehicleDesignCourse";
import HVACDesignEngineering from "./pages/HVACDesignEngineering";
import EngineeringServices from "./pages/EngineeringServices";
import ElectricalDesignEngineering from "./pages/ElectricalDesignEngineering";
import ElectricalDesigEngineering from "./pages/ElectricalDesigEngineering";
import DesignDetailedEngineeringTraining from "./pages/DesignDetailedEngineeringTraining";
import DaysIndustrialTraining from "./pages/DaysIndustrialTraining";
import CorporateServices from "./pages/CorporateServices";
import CivilAndStructuralDesignEngineering from "./pages/CivilAndStructuralDesignEngineering";
import BillingEngineeringTraining from "./pages/BillingEngineeringTraining";
import ContactUs from "./pages/ContactUs";
import Partener from "./pages/Partener";
import ApplyJobs from "./pages/ApplyJobs";
import Placement from "./pages/Placement";
import SAPHRHCMTraining from "./pages/SAPHRHCMTraining";
import SAPSimpleFinanceTraining from "./pages/SAPSimpleFinanceTraining";
import SAPFICOTraining from "./pages/SAPFICOTraining";
import ERPSAPHANATraining from "./pages/ERPSAPHANATraining";
import ERPSAPQMTraining from "./pages/ERPSAPQMTraining";
import ERPSAPPSTraining from "./pages/ERPSAPPSTraining";
import ERPSAPPPTraining from "./pages/ERPSAPPPTraining";
import ERPSAPPMTraining from "./pages/ERPSAPPMTraining";
import ERPSAPMMTraining from "./pages/ERPSAPMMTraining";
import ERPSAPHRTraining from "./pages/ERPSAPHRTraining";
import ERPTraining from "./pages/ERPTraining";
import HRGeneralistCourse from "./pages/HRGeneralistCourse";
import AdvancedTallyTraining from "./pages/AdvancedTallyTraining";
import AdvancedExcelForAccountingTraining from "./pages/AdvancedExcelForAccountingTraining";
import PayRollComplianceTraining from "./pages/PayRollComplianceTraining";
import AccountsGSTAndTaxationTraining from "./pages/AccountsGSTAndTaxationTraining";
import NONITTraining from "./pages/NONITTraining";
import PerformanceTesting from "./pages/PerformanceTesting";
import SoftwareAutomationTesting from "./pages/SoftwareAutomationTesting";
import SoftwareManualTesting from "./pages/SoftwareManualTesting";
import DotNETTraining from "./pages/DotNETTraining";
import AngularJSTraining from "./pages/AngularJSTraining";
import CoreJavaTraining from "./pages/CoreJavaTraining";
import WebDesigningTraining from "./pages/WebDesigningTraining";
import Seotraining from "./pages/Seotraining";
import Ittraning from "./pages/Ittraning";
import Blogs from "./pages/Blogs";
import Error404 from './components/Error404';
function App() {
  return (
    <>
    <Router>
      <Header />
       <Routes>
          <Route path="/mecci-services" element={<MecciServices />} />
          <Route path="/gallary" element={<Gallary />} />
          <Route path="/epctraining" element={<Epctraining />} />
          <Route path="/why-mecci" element={<WhyMecci />} />
          <Route path="/engineering-services" element={<EngineeringServices />} />
          <Route path="/online-classroom-training" element={<OnlineClassroomTraining />} />
          <Route path="/manpower-services" element={<ManpowerServices />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/corporate-services" element={<CorporateServices />} />
          <Route path="/project-management-control" element={<ProjectManagementControl />} />
          <Route path="/solar-power-plant-design-engineering" element={<SolarPowerPlantDesignEngineering />} />
          <Route path="/planning-package" element={<PlanningPackage />} />
          <Route path="/process-design-workshop" element={<ProcessDesignWorkshop />} />
          <Route path="/45-days-industrial-training" element={<Epctraining />} />
          <Route path="/design-and-detailed-engineering-training" element={<DesignDetailedEngineeringTraining />} />
          <Route path="/electrical-design-engineering" element={<ElectricalDesigEngineering />} />
          <Route path="/piping-design-engineering" element={<PipingDesignEngineering />} />
          <Route path="/process-design-engineering" element={<ProcessDesignEngineering />} />
          <Route path="/instrumentation-design-engineering" element={<InstrumentationDesignEngineering />} />
          <Route path="/hvac-design-engineering" element={<HVACDesignEngineering />} />
          <Route path="/planning-engineering-training" element={<PlanningEngineeringTraining />} />
          <Route path="/substation-design-course" element={<SubstationDesignCourse />} />

          <Route path="/solar-structure-design-course" element={<SolarStructureDesignCourse />} />
          <Route path="/hybrid-electric-vehicle-design-course" element={<HybridElectricVehicleDesignCourse />} />
          <Route path="/planning-engineering-training" element={<PlanningEngineeringTraining />} />
          <Route path="/substation-design-course" element={<SubstationDesignCourse />} />
          <Route path="/sap-simple-finance-training" element={<SAPSimpleFinanceTraining />} />
          <Route path="/sap-hr-hcm-training" element={<SAPHRHCMTraining />} />

          
          <Route path="/illumination-systems-designing-course" element={<IlluminationSystemsDesigningCourse />} />
          <Route path="/civil-and-structural-design-engineering" element={<CivilAndStructuralDesignEngineering />} />
          <Route path="/civil-structural-design-engineering" element={<CivilAndStructuralDesignEngineering />} />
          <Route path="/billing-engineering-training" element={<BillingEngineeringTraining />} />
          
          <Route path="/it-training" element={<Ittraning />} />
          <Route path="/seo-traning" element={<Seotraining />} />
          <Route path="/web-designing-training" element={<WebDesigningTraining />} />
          <Route path="/it-training" element={<Ittraning />} />
          <Route path="/core-java-training" element={<CoreJavaTraining />} />
          <Route path="/angular-js-training" element={<AngularJSTraining />} />
          <Route path="/dot-net-training" element={<DotNETTraining />} />
          <Route path="/software-manual-testing" element={<SoftwareManualTesting />} />
          <Route path="/software-automation-testing" element={<SoftwareAutomationTesting />} />
          <Route path="/performance-testing" element={<PerformanceTesting />} />
          <Route path="/non-it-training" element={<NONITTraining />} />
          <Route path="/accounts-gst-and-taxation-training" element={<AccountsGSTAndTaxationTraining />} />
          <Route path="/pay-roll-compliance-training" element={<PayRollComplianceTraining />} />
          <Route path="/advanced-excel-for-accounting-training" element={<AdvancedExcelForAccountingTraining />} />
          <Route path="/advanced-tally-training" element={<AdvancedTallyTraining />} />
          <Route path="/hr-generalist-course" element={<HRGeneralistCourse />} />
          <Route path="/advanced-tally-training" element={<AdvancedTallyTraining />} />
          <Route path="/hr-generalist-course" element={<HRGeneralistCourse />} />
          <Route path="/erp-training" element={<ERPTraining />} />
          <Route path="/erp-sap-hr-training" element={<ERPSAPHRTraining />} />
          <Route path="/erp-sap-mm-training" element={<ERPSAPMMTraining />} />
          <Route path="/erp-sap-pm-training" element={<ERPSAPPMTraining />} />
          <Route path="/erp-sap-pp-training" element={<ERPSAPPPTraining />} />
          <Route path="/erp-sap-ps-training" element={<ERPSAPPSTraining />} />
          <Route path="/erp-sap-qm-training" element={<ERPSAPQMTraining />} />
          <Route path="/erp-sap-hana-training" element={<ERPSAPHANATraining />} />
          <Route path="/sap-fico-training" element={<SAPFICOTraining />} />
          <Route path="/apply-jobs" element={<ApplyJobs />} />
          <Route path="/partener" element={<Partener />} />
          <Route path="/placement" element={<Placement />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      <Footer />
      </Router>
    
    </>
  );
}

export default App;
