import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import { Helmet } from "react-helmet";
export class ProjectManagementControl extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Mecciengineers-Top most project management Courses Online by Experts
          </title>
          <meta
            name="description"
            content="Top-rated Project management Training online.  We offer Project management course in India, Sudan, Nigeria, Egypt, South Sudan, South Africa, Oman, Qatar, Dubai, Kuwait, UAE."
          />
          <meta
            name="keywords"
            content="Project management Engineering Training Institute, Project management Certificate Course training, Project management Course, Project management Engineering Course"
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/project-management-control"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Project Management Program"
            imgsrc="assets/images/corporate-services.jpg"
            alt="Project Management Program"
            title="Project Management Program"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  <b>MEPL</b> does the whole project engineering design and
                  documentation from kick off to commissioning for all types of
                  industrial facilities. Our expert professionals are capable to
                  produce engineering documents from PFD, P&ID and Technical
                  Datasheets up to operating manual. Also our engineers will be
                  available for commissioning and training and we give 100%
                  quality to our customers. We do the documentation in process,
                  Civil, mechanical, electrical and instrumentation.
                </p>
                <p>
                  Our Program & Project Management services include setting up
                  of various systems & procedures for effective management of
                  project from conception to handover. Our services include
                  development of detailed Project Management Plan with an
                  optimized Organization Structure for effective implementation
                  of Governance role, Stakeholder engagement & Benefit
                  management. Our company will provide all applicable project
                  procedures, detailed documentation for project execution plan
                  (Master Schedule), detailed Cost Estimate, Risk Register &
                  applicable templates etc. to suit the client need.
                </p>
                <h3>We are experts in the below process packages:</h3>
                <ul>
                  <li>Natural Gas Sweetening Packages</li>
                  <li>GDU Packages</li>
                  <li>IGF Packages</li>
                  <li>2 Phase and 3 Phase Separator Units</li>
                  <li>Gas Metering Systems</li>
                  <li>Chemical Injection Systems</li>
                  <li> Well Head Control Panels</li>
                  <li>Test Units</li>
                  <li>BOP Panels</li>
                  <li>ESD Panels</li>
                  <li>Pigging Systems</li>
                  <li>Test Separator Packages</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProjectManagementControl;
