import React, { Component } from "react";
//import { Link } from "react-router-dom";
import HomeSliderData from "../pages/HomeSliderData";
function Slider() {
  return (
    <>
      <div className="container-fluid">
        <div className="banner">
          <div id="myCarousel" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner" role="listbox">
              <div className="item active">
                <img
                  src={HomeSliderData[0].imgsrc}
                  alt={HomeSliderData[0].alt}
                  className="img-responsive"
                />
              </div>
              <div className="item">
                <img
                  src={HomeSliderData[1].imgsrc}
                  alt={HomeSliderData[1].alt}
                  className="img-responsive"
                />
              </div>
              <div className="item">
                <img
                  src={HomeSliderData[2].imgsrc}
                  alt={HomeSliderData[2].alt}
                  className="img-responsive"
                />
              </div>

			  <div className="item">
                <img
                  src={HomeSliderData[3].imgsrc}
                  alt={HomeSliderData[3].alt}
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
}

export default Slider;
