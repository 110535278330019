import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Registration from "../components/Registration";
import { Helmet } from "react-helmet";

export class ApplyJobs extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Apply Job</title>
          <meta name="description" content="jobs" />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/apply-jobs"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Placement"
            imgsrc="assets/images/course/course-title-img/jobapply.jpg"
            alt="Placement"
            title="Placement"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <Registration />
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ApplyJobs;
