const HomeSliderData = [
    {   
       id     :1,
        imgsrc:"assets/images/banner2.jpg",
       	alt   :"slider1",
        link  :"#",
    },
    {   
       id     :2,
        imgsrc:"assets/images/bannar1.jpg",
       	alt   :"slider2",
        link  :"#",
    },
    {   
       id     :3,
        imgsrc:"assets/images/bannar3.jpg",
		alt   :"slider3",
        link  :"#",
    },
    {   
        id     :4,
         imgsrc:"assets/images/bannar4.png",
         alt   :"slider4",
         link  :"#",
     },
	
	];
    export default HomeSliderData;