import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";
export class PlanningEngineeringTraining extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
          Planning Design Engineering Training, Course Online in India
          </title>
          <meta
            name="description"
            content=" Top-rated Planning design Engineering Training.  We offer Planning Design course in India, Sudan, Nigeria, Egypt, South Sudan, South Africa, Oman, Qatar, Dubai, Kuwait, UAE."
          />
          <meta
            name="keywords"
            content=" MECCI Engineers provides the best online & offline Planning Design Engineering Training Courses in India, Sudan, Nigeria,   Oman, Qatar, UAE & more countries"
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/planning-engineering-training"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Best Project Planning Engineering Training"
            imgsrc="assets/images/course/course-title-img/planning-engineering-training.jpg"
            alt="Planning Engineering Training"
            title="Best Project Planning Engineering Training"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  A project planning engineer works with a site manager to
                  develop suitable construction methods and sequences for a
                  project. This training course manage projects from start to
                  finish through a range of topics including scope, schedule and
                  resource planning, project risk, execution and control plus
                  more.
                </p>
                <p>
                  Project Planning Design and Analysis is a Project Based
                  training program Opportunity that includes the science of
                  ensuring stability, strength, and rigidity of structures so
                  that resist all applied loads without failure.
                </p>
                <hr />
                <Table
                  caption="Offered Project Planning Engineering Course"
                  disctitle="Project Planning Engineering Course"
                  disc1="Project Planning Engineering Course (Full Time)"
                  pdf1="assets/images/course/courses-pdf/project-planning-engineering-training.pdf"
                  duration1="45 Days"
                  disc2="Project Planning Engineering Course (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/project-planning-engineering-training.pdf"
                  duration2="3 Months"
                  disc3="Project Planning Engineering Course (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/project-planning-engineering-training.pdf"
                  duration3="8 Days"
                  disc4="Project Planning Engineering Course (Correspondence)	"
                  pdf4="assets/images/course/courses-pdf/project-planning-engineering-training.pdf"
                  duration4="1 Year"
                />
                <hr />

                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul>
                  <li>Introduction to planning of construction works.</li>
                  <li> WBS – Work Breakdown Structure</li>
                  <li>Scheduling & CPM, Network Analysis.</li>
                  <li> Resource allocation and resource levelling</li>
                  <li> Monitoring & Control</li>
                  <li> Cash flow</li>
                  <li> Planning & Knowledge Management</li>
                  <li>Planned vs Actual</li>
                  <li>Other Miscellaneous Topics.</li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>
                    We Offer the best SEO Programming training and dedicated
                    placement assistance in Noida with properly planned training
                    modules and course content.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>Smart Labs with Real Latest Equipment’s.</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PlanningEngineeringTraining;
