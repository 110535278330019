import React from "react";
function BrandLogoCard(params) {
  return (
    <>
        <div className="col-md-3">
          <div className="item">
            <div className="pad15" style={{ marginTop: "13px" }}>
              <div className="smallCol card zoom">
                <h3 className="card-title">{params.title}</h3>
                <img
                  src={params.imgsrc}
                  className="img-responsive"
                  alt={params.alt}
                />
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default BrandLogoCard;
