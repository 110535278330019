import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";
export class AccountsGSTAndTaxationTraining extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>E-Accounts, E-Taxation & E-GST Training Course</title>
          <meta
            name="description"
            content="E-Accounts, E-Taxation & E-GST Training Course"
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/accounts-gst-and-taxation-training"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="E-Accounts, E-Taxation & E-GST Training Course"
            imgsrc="assets/images/course/course-title-img/e-accounts-e-taxation-e-gst-training-course.jpg"
            alt="E-Accounts, E-Taxation & E-GST Training Course"
            title="E-Accounts, E-Taxation & E-GST Training Course"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  The training program is designed by our experienced faculty
                  which includes Corporate Professionals Accountants after
                  thoroughly examining the current requirements of the market
                  and evaluating the latest development in GST and Income Tax
                  field. The course is targeted towards fresher students who
                  want to forward their accounting and taxation knowledge to
                  become a professional tax consultant who can fluently and
                  easily perform tax related operations without any difficulty.
                  e-Accounting course and classes involves performing regular
                  accounting functions, accounting research and the-Accounting
                  training and education through various computer based
                  accounting financial spreadsheet tools.
                </p>
                <p>
                  Our courses are designed keeping in mind the current Tax laws
                  and the technology required for facilitating preparation,
                  computation and E-Fillings of tax returns (Including Income
                  Tax, TDS, VAT and Service tax) with or without Digital
                  Signatures. There has been remarkable growth in this era of
                  information and communication technology in business to
                  support the exchange of data and information within and
                  between organizations. This concept is opted at international
                  level. There is large number of companies who started
                  e-Accounting as a training programme.
                </p>

                <hr />
                <Table
                  caption="Offered Courses in E-Accounts, E-Taxation & E-GST Training"
                  disctitle="Course Related to E-Accounts, E-Taxation & E-GST"
                  disc1="E-Accounts, E-Taxation & E-GST course (Full Time)"
                  pdf1="assets/images/course/courses-pdf/assets/images/course/courses-pdf/eaccounts-etaxation-egst-training.pdf"
                  duration1="45 Days"
                  disc2="E-Accounts, E-Taxation & E-GST course (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/assets/images/course/courses-pdf/eaccounts-etaxation-egst-training.pdf"
                  duration2="3 Months"
                  disc3="E-Accounts, E-Taxation & E-GST course (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/assets/images/course/courses-pdf/eaccounts-etaxation-egst-training.pdf"
                  duration3="8 Days"
                  disc4="E-Accounts, E-Taxation & E-GST course (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/assets/images/course/courses-pdf/eaccounts-etaxation-egst-training.pdf"
                  duration4="6 Months"
                />
                <hr />

                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul>
                  <li>Overview of GST Law</li>
                  <li>
                    GST Registration under Model GST Law with GST Rules &
                    Formats
                  </li>
                  <li>Job Work & Composition Levy in GST</li>
                  <li>Accounting for GST in Tally</li>
                  <li>GST Returns Module</li>
                  <li>Introduction to Income Tax</li>
                  <li>Income which do not form part of the total income</li>
                  <li>
                    Aggregation of Income, Set-Off and Carry Forward of Losses
                  </li>
                  <li>Computation of Total Income & Tax Payable</li>
                  <li>
                    ESI & EPF Calculations and Submission of Returns as per
                    Factory Act & Rules for Employees
                  </li>
                  <li>Main Commodities and Service Tariff with rates</li>
                  <li>Deduction From Income Deduction u/s 80c to 80u</li>
                  <li>
                    Analytically-Income under head salary computation
                    theoretically & by utility
                  </li>
                  <li>Practical Aspects of Accounting Finalization</li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>
                    Course will focus on conceptual knowledge as well as give
                    practical knowledge based on current Tax Laws.
                  </li>
                  <li>
                    Students will get to learn on the actual accounting software
                    Tally and tax software Taxbase, VAT Xpress, Service Tax
                    Xpress and E Audit.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AccountsGSTAndTaxationTraining;
