import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import { Helmet } from "react-helmet";
export class PlanningPackage extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Mecciengineers-Top most planning package Courses Online by Experts
          </title>
          <meta
            name="description"
            content=" Top-rated planning package Engineering Training.  We offer planning  package course in India, Sudan, Nigeria, Egypt, South Sudan, South Africa, Oman, Qatar, Dubai, Kuwait, UAE."
          />
          <meta
            name="keywords"
            content="planning package training Institute, planning package Certificate Course training, planning  package Course, planning package Engineering Course"
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/planning-package"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Project Planning Package"
            imgsrc="assets/images/course/course-title-img/project-planning-package.jpg"
            alt="Project Planning Package"
            title="Project Planning Package"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  <b>MEPL</b> does the whole project engineering design and
                  documentation from kick off to commissioning for all types of
                  industrial facilities. A project has a single objective that
                  must be accomplished through the completion of tasks that are
                  unique and interrelated in process, Civil, mechanical,
                  electrical and instrumentation.
                </p>
                <p>
                  Project Engineering bridges the boundaries between engineering
                  and project management, leading the technical workers who
                  contribute to the building of structures or products. In some
                  cases, the project engineer is the same as a project manager
                  but in most cases these two professionals have joint
                  responsibility for leading a project. Project management holds
                  responsibility of every field of engineers.
                </p>
                <p>
                  The role of the project engineer can often be described as
                  that of a liaison between the project manager and the
                  technical disciplines involved in a project. The project
                  engineer is also often the primary technical point of contact
                  for the consumer. A project engineer's responsibilities
                  include schedule preparation, pre-planning and resource
                  forecasting for engineering and other technical activities
                  relating to the project. They may also be in charge of
                  performance management of vendors. They assure the accuracy of
                  financial forecasts, which tie-in to project schedules. They
                  ensure projects are completed according to project plans.
                  Project engineers manage project team resources and training
                  and develop extensive project management experience and
                  expertise. When project teams are structured so that multiple
                  specialty disciplines report to the project engineer, then two
                  important responsibilities of the project engineer are
                  inter-discipline coordination and overall quality control of
                  the work.
                </p>
                <p>
                  <b>We are experts in the below process packages:</b>
                </p>
                <ul>
                  <li>Natural Gas Sweetening Packages</li>
                  <li>New Build & Construction</li>
                  <li>Civil Engineering</li>
                  <li>Mechanical & Electrical</li>
                  <li>Nuclear & Power</li>
                  <li>2 Phase and 3 Phase Separator Units</li>
                  <li>Gas Metering Systems</li>
                  <li>Chemical Injection Systems</li>
                  <li>Well Head Control Panels</li>
                  <li>Test Units</li>
                  <li>BOP Panels</li>
                  <li>ESD Panels</li>
                  <li>Pigging Systems</li>
                  <li>Test Separator Packages</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PlanningPackage;
