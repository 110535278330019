import React, { Component } from "react";
import { Link } from "react-router-dom";
import mecciService, { corporateServices } from "../pages/CourseDataLink";
export class SidebarCorporateServicesHome extends Component {
  render() {
    return (
      <>
        <div
          className="colleft accordion"
          data-toggle="collapse"
          data-target="#carporar"
        >
          <h3>CORPORATE SERVICES</h3>
          <div id="carporar" className="collapse in">
            <ul className="listunstyled">
              <li>
                <Link to={corporateServices[0].link} target="_blank">
                  {corporateServices[0].title}
                </Link>
              </li>
              <li>
                <Link to={corporateServices[1].link} target="_blank">
                  {corporateServices[1].title}{" "}
                </Link>
              </li>
              <li>
                <Link to={corporateServices[2].link} target="_blank">
                  {corporateServices[2].title}
                </Link>
              </li>
              <li>
                <Link to={corporateServices[3].link} target="_blank">
                  {corporateServices[3].title}{" "}
                </Link>
              </li>
              <li>
                <Link to={corporateServices[4].link} target="_blank">
                  {corporateServices[4].title}{" "}
                </Link>
              </li>
              <li>
                <Link to={corporateServices[5].link} target="_blank">
                  {corporateServices[5].title}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default SidebarCorporateServicesHome;
