import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";
export class CivilAndStructuralDesignEngineering extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Mecciengineers-Civil & Structural Design Training, Course Online in India
            Experts
          </title>
          <meta
            name="description"
            content=" MECCI Engineers provides the best online & offline Civil & Structural Design Training Courses in India, Sudan, Nigeria,   Oman, Qatar, UAE & more countries."
          />
          <meta
            name="keywords"
            content=" Civil Design Engineering Training Institute, Civil Design Certificate Course training, Civil Design Course, Civil Design Engineering Course"
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/civil-and-structural-design-engineering"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Civil Design Engineering Course"
            imgsrc="assets/images/course/course-title-img/civil-design-engineering-course.jpg"
            alt="civil-design-engineering-course"
            title="Civil Design Engineering Course"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  MECCI Engineer offering Civil & Structural design engineering
                  Course. Civil & Structural engineering deals with the study,
                  analysis and design of different structures involves with
                  requirement in industrial facilities. These structures are
                  generally required for construction related to power projects,
                  refineries, Chemical plants and etc. But this is not only
                  applicable in the field of civil & structural engineering; in
                  fact this field of engineering has a wide range of
                  applications in machinery, vehicle, medical equipment, parking
                  lots etc. One of the major aspects of this field is analysis
                  based on safety requirements. With the wide development of
                  infrastructure in India and overseas this field has become one
                  of the hot favourites for future and present engineers,
                  consultants and contractor companies.
                </p>
                <hr />
                <Table
                  caption="Offered Courses in Civil Design Engineering"
                  disctitle="Course Related to Civil Detail Design"
                  disc1="Civil Design Engineering (Full Time)"
                  pdf1="assets/images/course/courses-pdf/civil-design-engineering-course.pdf"
                  duration1="45 Days"
                  disc2="Civil Design Engineering (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/civil-design-engineering-course.pdf"
                  duration2="3 Months"
                  disc3="Civil Design Engineering (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/civil-design-engineering-course.pdf"
                  duration3="8 Days"
                  disc4="Civil Design Engineering (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/civil-design-engineering-course.pdf"
                  duration4="1 Year"
                />
                <hr />

                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul>
                  <li>Basics of Structural Engineering</li>
                  <li>Analysis & Design of Structures</li>
                  <li>Steel Frame Connection</li>
                  <li>2D & 3D Modeling Structures</li>
                  <li>3D Structural Design</li>
                  <li>Structural Analysis & Design.</li>
                  <li>Stress & Failure Analysis.</li>
                  <li>Foundation Design.</li>
                  <li>Concrete Design & Analysis.</li>
                  <li>Structural Evaluation.</li>
                  <li>Steel Structure Design.</li>
                  <li>Pre-Engineered Buildings.</li>
                  <li>Construction Detailing & Drawings.</li>
                  <li>Infrastructure Design.</li>
                  <li>Storm Water & Sewage Design.</li>
                  <li>Bridges, Roads, & Power Projects Design.</li>
                  <li>Investigation & Failure Analysis.</li>
                  <li>Proof Checking & Advisory Services.</li>
                  <li>Application Design.</li>
                  <li>Restoration & Rehabilitation of Structures.</li>
                  <li>Analysis & Evaluation of Soundness of Structures.</li>
                  <li>Structures, Tanks, Bins, Hopers.</li>
                  <li>Steel Analysis & Steel Design.</li>
                  <li>Rain Water Harvest System.</li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>EPC Project Based Training.</li>
                  <li>
                    Providing all Training Material and technical Drawing,
                    documents and case study materials.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CivilAndStructuralDesignEngineering;
