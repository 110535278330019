import React, { Component } from 'react';
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import { Helmet } from 'react-helmet';

export class ERPTraining extends Component {
    render() {
      return (
        <>
         <Helmet>
          <title>Web Designing Training</title>
          <meta name="description" content="Web Designing Training" />
        </Helmet>
          <div className="container-fluid">
            <PageTitle
              page_header_title="Web Designing Training"
              imgsrc="assets/images/corporate-services.jpg"
              alt="web-designing-training"
              title="Web Designing Training"
            />
            <div className="row">
              <div className="col-md-8 col-md-8a">
                <div className="colright">
                  <p></p>
                </div>
              </div>
              <div className="col-md-4 col-md-4a">
                <Sidebar />
              </div>
            </div>
          </div>
        </>
      );
    }
}

export default ERPTraining;
