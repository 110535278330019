import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import { Helmet } from "react-helmet";

export class ManpowerServices extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Top most manpower consultant in India.</title>
          <meta
            name="classification"
            content="MECCI Engineers, ISO 9001:2015 Certified man power consultant presents various customized courses for working persons and fresher engineers. We offer Civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course in India, Sudan, Nigeria, Egypt, Bangladesh, South Sudan &amp; South Africa, Oman, Qatar, Dubai, Kuwait, UAE."
          />
          <meta
            name="keywords"
            content="Man power consultant in India, Civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course "
          />
        </Helmet>
        <Helmet>
          <link rel="canonical" href="https://www.mecciengineer.com/manpower-services" />
        </Helmet>

        <div className="container-fluid">
          <PageTitle
            page_header_title="Manpower Services"
            imgsrc="assets/images/course/course-title-img/manpower-services.jpg"
            alt="manpower-services"
            title="Manpower Services"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <h2>ENGINEERING DESIGN & DETAIL DRAWINGS</h2>
                <h3>CIVIL & STRUCTURAL</h3>
                <p>
                  Detail Fabrication Drawings For Column, Ladder, Staircase,
                  Floor Plan, Bracings, Purling, Conveyors Standard Welding /
                  Bolting Details As Per Requirement.
                </p>
                <h3>PIPING ENGINEERING</h3>
                <p>Piping GA, Plan, Section/ Equipment Layout Drawings.</p>
                <p>Piping and Instrumentation Drawings.</p>
                <p>Piping Isometrics / Bulk / Final M T O Drawings.</p>
                <p>Nozzle Orientation & Pipe Rack Developments Drawings.</p>
                <h3>MECHANICAL ENGINEERING</h3>
                <p>
                  Drawings for Equipments, Tank Fabrication, Ducting and
                  Refractory & Sheet Developments drawings.
                </p>
                <h3>ELECTRICAL ENGINEERING</h3>
                <p>
                  Single Line drawings, Schematics, Wiring & cable schedules,
                  Equipment layouts, Conduits & cable layouts, & Construction /
                  Testing/Commissioning services.
                </p>
                <h3>INSTRUMENTATION ENGINEERING</h3>
                <p>
                  All Type of design & construction works including :
                  Preparation of ILD, Logic drawings, Instrument Hookup details,
                  Control narratives, JB/Marshalling drawings, Cable schedule,
                  Termination schedule, DCS/PLC System design requirements &
                  Installation, testing & commissioning services of all
                  Instrumentation systems by Technically qualified and
                  experienced staff within a specified time schedule.
                </p>
                <h2>DOMAIN EXPERTISE</h2>
                <h3>
                  Providing Manpower On deputation contract basis domestic /
                  overseas :
                </h3>
                <ul>
                  <li>
                    Draughtsman (CAD / PDS / PDMS ) Engineer, Designer : Civil /
                    Mechanical / Piping / Electrical / Instrumentation
                  </li>
                  <li>
                    Manager Construction Qa/Qc/ Production / Planning & Project
                    Controls Erection Engineers.
                  </li>
                  <li>
                    Administrative Staff : Front Office / Back Office Support
                  </li>
                  <li>
                    Commissioning & Startuptechnically Qualified & Experienced
                    Field Personnel Specialists In Oil & Gas,Petrochemical,
                    Cement, Power Projects Etc.
                  </li>
                  <li>
                    <b>Cad Conversion Work</b> : Conversion Of Drawings On Auto
                    Cad As Per Scale Factor & Hard Copy To Soft Copy.
                  </li>
                  <li>
                    <b>As Built Drawing </b>: Existing / New Industrial Project
                    For Civil / Mechanical / Piping / Structural And All Type Of
                    As Built Work. Site Survey & Drawings Corrected As Per Site
                    Work.
                  </li>
                </ul>
                <h2>
                  CONFIDENTIALITY POLICY & THE SALIENT FEATURES OF OUR POLICY
                </h2>
                <ul>
                  <li>
                    MECCI agrees that neither it nor its staff working on the
                    project will disclose any information about the project to
                    any third party without the client’s prior consent.
                  </li>
                  <li>
                    All data and information provided by the client will be
                    confidential and strictly the property of theclient.
                  </li>
                  <li>
                    MECCI further agrees that neither it nor its staff will
                    reproduce in any way or divulge any tangible or intangible
                    property whatsoever which could reasonably be construed as
                    constituting confidential information of the client.
                  </li>
                  <li>
                    All raw data, printouts, processed data and final product
                    will solely be the client’s property.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ManpowerServices;
