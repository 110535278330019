import { useState, useEffect } from "react";
import axios from "axios";
function News() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    getAllNews();
  }, []);

  async function getAllNews() {
    try {
      const res = await axios.get("https://www.mecciengineer.com/mecci/public/api/news");

      setNews(res.data.news);
    } catch (error) {
      //console.log("something wrong");
      console.log(error.message);
    }
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex justify-content-between align-items-center breaking-news bg-white">
              <div className="d-flex flex-row flex-grow-1 flex-fill text-white px-1 news">
                <span className="d-flex align-items-center">
                  &nbsp;&nbsp;&nbsp;&nbsp;News
                </span>
              </div>
              <marquee
                className="news-scroll"
                behavior="scroll"
                direction="left"
              >
                {news.map((newsitm) => (
                  <>
                  <span key={newsitm.id}>
                    <img src="assets/images/newicon.gif" alt="img" />
                    <span className="dot"></span>

                    {newsitm.news}
					&nbsp;&nbsp;&nbsp;
          </span>
                  </>
                ))}
              </marquee>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default News;
