//import "./styles.css";
import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";

function Registration() {
  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [city, setCity] = useState("");
  const [position, setPosition] = useState("");
  const [qualification, setQualification] = useState("");
  const [experience, setExperience] = useState("");
  const [resume, setResume] = useState("");

  async function appltJob() {
    // console.warn(resume,experience,qualification,position,city,gender,mobile,email,fname);
    const formData = new FormData();
    formData.append("name", fname);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("gender", gender);
    formData.append("city", city);
    formData.append("position", position);
    formData.append("qualification", qualification);
    formData.append("experience", experience);
    formData.append("resume", resume);
    let result = await fetch("https://www.mecciengineer.com/mecci/public/api/applyjob", {
      method: "POST",
      body: formData,
    });
    alert("data hase been saved");
  }

  return (
    <div className="form-horizontal" role="form">
      <h2>Apply Jobs</h2>
      <div className="form-group">
        <label htmlFor="firstName" className="col-sm-3 control-label">
          Full Name
        </label>
        <div className="col-sm-6">
          <input
            type="text"
            onChange={(e) => setFname(e.target.value)}
            id="name"
            name="fname"
            placeholder="Full Name"
            className="form-control"
            autoFocus
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="email" className="col-sm-3 control-label">
          Email
        </label>
        <div className="col-sm-6">
          <input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            id="email"
            placeholder="Email"
            className="form-control"
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="mobile" className="col-sm-3 control-label">
          Mobile
        </label>
        <div className="col-sm-6">
          <input
            type="text"
            onChange={(e) => setMobile(e.target.value)}
            name="mobile"
            id="mobile"
            placeholder="mobile"
            className="form-control"
          />
        </div>
      </div>

      <div className="form-group">
        <label className="control-label col-sm-3">Gender</label>
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6">
              <label className="radio-inline">
                <input
                  type="radio"
                  onChange={(e) => setGender(e.target.value)}
                  name="gender"
                  id="femaleRadio"
                  value="Female"
                />
                Female
              </label>
            </div>
            <div className="col-sm-6">
              <label className="radio-inline">
                <input
                  type="radio"
                  onChange={(e) => setGender(e.target.value)}
                  name="gender"
                  id="maleRadio"
                  value="Male"
                />
                Male
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="city" className="col-sm-3 control-label">
          City
        </label>
        <div className="col-sm-6">
          <input
            type="text"
            onChange={(e) => setCity(e.target.value)}
            name="city"
            id="city"
            className="form-control"
          />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="position" className="col-sm-3 control-label">
          Apply for Position
        </label>
        <div className="col-sm-6">
          <input
            type="text"
            onChange={(e) => setPosition(e.target.value)}
            name="position"
            id="position"
            className="form-control"
          />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="country" className="col-sm-3 control-label">
          Higher Qualification
        </label>
        <div className="col-sm-6">
          <select
            name="qualification"
            onChange={(e) => setQualification(e.target.value)}
            id="qualification"
            className="form-control"
          >
            <option value="0"></option>
            <option value="mca">MCA</option>
            <option value="bca">BCA</option>
            <option value="ma">M.A.</option>
            <option value="m.com">M.Com</option>
            <option value="b.tech">B.Tech</option>
            <option value="b.sc">B.Sc.</option>
            <option value="b.com">B.Com</option>
            <option value="b.ed">B.Ed.</option>
            <option value="certificate">Certificate</option>
            <option value="polytechnics">Polytechnics</option>
            <option value="iti">ITI</option>
            <option value="other">Other</option>
          </select>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="exp" className="col-sm-3 control-label">
          Work Status
        </label>
        <div className="col-sm-6">
          <select
            name="experience"
            onChange={(e) => setExperience(e.target.value)}
            id="experience"
            className="form-control"
          >
            <option value="0"></option>
            <option value="fresher">Fresher</option>
            <option value="experienced">Experienced</option>
          </select>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="position" className="col-sm-3 control-label">
          Upload Resume
        </label>
        <div className="col-sm-6">
          <input
            type="file"
            name="image"
            onChange={(e) => setResume(e.target.files[0])}
            className="form-control"
            accept=".doc, .docx,.pdf"
          />
        </div>
      </div>
      <div className="form-group">
        <div className="col-sm-6 col-sm-offset-3">
          <button
            type="submit"
            className="btn btn-primary btn-block"
            onClick={appltJob}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}

export default Registration;
