import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";
export class AdvancedExcelForAccountingTraining extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best Advanced Excel for Accounting Training</title>
          <meta
            name="description"
            content="Best Advanced Excel for Accounting Training" 
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/advanced-excel-for-accounting-training"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Best Advanced Excel for Accounting Training"
            imgsrc="assets/images/course/course-title-img/advanced-excel-accounting-training.jpg"
            alt="Best Advanced Excel for Accounting Training"
            title="Best Advanced Excel for Accounting Training"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  In This Training Program in Advance Excel is a 45 Days
                  training program covering the Basic and Advanced modules of
                  MS-Excel. The aim of this project is to make students
                  understand the usefulness & applications of excel using a
                  real-life case study.
                </p>
                <p>
                  With organizations growing at a large scale, Advanced Excel
                  training is required to analyze large amounts of data. It
                  helps to discover trends and patterns that can influence
                  business decisions. MS Excel training classes not only teach
                  how to use formulas, charts, tables but also help develop
                  advanced Excel skills and techniques to build a successful
                  career.
                </p>

                <hr />

                <Table
                  caption="Offered Courses in Advanced Excel for Accounting Training"
                  disctitle="Course Related to Advanced Excel for Accounting Training"
                  disc1="Advanced Excel for Accounting Training (Full Time)"
                  pdf1="assets/images/course/courses-pdf/advanced-excel-accounting-training.pdf"
                  duration1="45 Days"
                  disc2="Advanced Excel for Accounting Training (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/advanced-excel-accounting-training.pdf"
                  duration2="3 Months"
                  disc3="Advanced Excel for Accounting Training (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/advanced-excel-accounting-training.pdf"
                  duration3="8 Days"
                  disc4="Advanced Excel for Accounting Training (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/advanced-excel-accounting-training.pdf"
                  duration4="6 Months"
                />

                <hr />

                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul>
                  <li>
                    Introduction to Human resource technology and Analytics
                  </li>
                  <li>Employee data, data sources and metrics</li>
                  <li>
                    The Employees Provident Funds and Miscellaneous Provision
                    Act - 1952 (EPF)
                  </li>
                  <li>
                    Correctly complete year end requirements and establish the
                    year-beginning requirements
                  </li>
                  <li>
                    Follow the proper policies, procedures, and documentation
                    requirements for garnishments and levies
                  </li>
                  <li>
                    Handle stock options, expense reimbursements, relocation,
                    and other expenses
                  </li>
                  <li>
                    The Contract Labour (Regulation & Abolition) Act - 1970
                  </li>
                  <li>The Child Labour (Prohibition & Regulation Act), 1986</li>
                  <li>The Minimum Wages Act-1948</li>
                  <li>
                    Properly identify, pay, and withhold taxes for employees
                  </li>
                  <li>
                    The Employment Exchange (Compulsory Notification of
                    Vacancies) ACT-1959
                  </li>
                  <li>Statutory benefits administration</li>
                  <li>
                    The Interstate Migrant Workmen (Regulation of Employment and
                    Conditions of Services) ACT, 1979
                  </li>
                  <li>The Industrial Employment (Standing Orders) ACT 1946</li>
                  <li>The Industrial Disputes ACT 1947</li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>
                    Help employees clearly understand what training programs can
                    speed up their career progress in their desired direction.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AdvancedExcelForAccountingTraining;
