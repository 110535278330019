import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";

export class HVACDesignEngineering extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
          HVAC Design Engineering Training, Course Online in India
          </title>
          <meta
            name="description"
            content="MECCI Engineers provides the best online & offline HVAC Design Engineering Training Courses in India, Sudan, Nigeria,   Oman, Qatar, UAE & more countries."
          />
          <meta
            name="keywords"
            content=" HVAC Design Engineering Training Institute, HVAC Design Certificate Course training, HVAC Design Course, HVAC Design Engineering Course"
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/hvac-design-engineering"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="HVAC Design Engineering Course"
            imgsrc="assets/images/course/course-title-img/hvac-design-engineering-course.jpg"
            alt="HVAC-design-engineering-course"
            title="HVAC Design Engineering Course"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  HVAC Design Engineering is an application based on Mechanical
                  Engineering principles, which deals with Design, Analysis,
                  Installation, Testing, Commissioning and Maintenance of HVAC
                  System. Industrially, all HVAC activities are performed with
                  the compliance and guidelines of International and Industrial
                  Codes & Standards as well as the laws and regulations of
                  respective local authority. Detail engineering in HVAC
                  projects consists of the engineering, design, detail and
                  layout of whole HVAC system.
                </p>
                <p>
                  MECCI Engineers offering HVAC design engineering Course. For
                  engineering purposes the definition should also be extended to
                  include the lowest life-cycle cost of conditioning the air by
                  right-sizing of equipment to meet the particular application
                  with the lowest operating and maintenance.
                </p>
                <hr />

                <Table
                  caption="Offered Courses in HVAC Design Engineering"
                  disctitle="Course Related to HVAC Detail Design"
                  disc1="HVAC Design Engineering (Full Time)"
                  pdf1="assets/images/course/courses-pdf/hvac-design-module.pdf"
                  duration1="45 Days"
                  disc2="HVAC Design Engineering (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/hvac-design-module.pdf"
                  duration2="3 Months"
                  disc3="HVAC Design Engineering (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/hvac-design-module.pdf"
                  duration3="8 Days"
                  disc4="HVAC Design Engineering (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/hvac-design-module.pdf"
                  duration4="1 Year"
                />

                <hr />

                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul>
                  <li>Introduction to HVAC</li>
                  <li>Refrigerants</li>
                  <li>Project Procurement works</li>
                  <li>Cooling Load estimation</li>
                  <li>Preparation of Drawings</li>
                  <li>Heating Loss estimation</li>
                  <li>Clean Rooms/ Cold Stores</li>
                  <li> Air Distribution System</li>
                  <li>Ventilation system</li>
                  <li>Chilled Water system</li>
                  <li>Equipment Selection</li>
                  <li>Erection of Equipments</li>
                  <li>Maintenance</li>
                  <li>Estimation of Projects</li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>EPC Project Based Training.</li>
                  <li>
                    Providing all Training Material and technical Drawing,
                    documents and case study materials.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HVACDesignEngineering;
