import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import MyForm from "../components/Contact";
import { Helmet } from "react-helmet";

export class ContactUs extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Contact Us</title>
          <meta name="description" content="Contact Us" />
          <link rel="canonical" href="https://www.mecciengineer.com/contact-us" />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Contact Us"
            imgsrc="assets/images/contact-us.webp"
            alt="contact-us"
            title="Contact Us"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <div className="row">
                  <br />
                  <br />
                  <div className="col-sm-6 col-md-6 zoom">
                    <iframe
                      width="100%"
                      height="320px;"
                      frameBorder="0"
                      style={{ border: "0" }}
                      src="https://maps.google.co.in/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=MECCI+Engineers+Pvt.+Ltd.,+Block+E,+Sector+3,+Noida,+Uttar+Pradesh&amp;aq=0&amp;oq=mecci+engineer&amp;sll=27.141237,80.883382&amp;sspn=7.582791,15.534668&amp;ie=UTF8&amp;hq=MECCI+Engineers+Pvt.+Ltd.,&amp;hnear=Block+E,+Sector+3,+Noida,+Gautam+Buddh+Nagar,+Uttar+Pradesh&amp;t=m&amp;cid=8140484857133048213&amp;ll=28.591983,77.319717&amp;spn=0.026377,0.036478&amp;z=14&amp;iwloc=A&amp;output=embed"
                      allowFullScreen
                    ></iframe>
                  </div>

                  <div className="col-sm-6 col-md-6">
                    <MyForm />
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-xs-12 col-sm-6 col-lg-4">
                    <div className="box">
                      <div className="icon">
                        <div className="image">
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                        </div>
                        <div className="info">
                          <h3 className="title">MAIL & WEBSITE</h3>
                          <p>
                            <i
                              className="fa fa-envelope"
                              aria-hidden="true"
                            ></i>
                            &#160;
                            <a href="mailto:info@mecciengineer.com">
                              info@mecciengineer.com
                            </a>
                            <br />
                            <br />
                            <i className="fa fa-globe" aria-hidden="true"></i>
                            &#160; www.mecciengineer.com
                          </p>
                        </div>
                      </div>
                      <div className="space"></div>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-6 col-lg-4">
                    <div className="box">
                      <div className="icon">
                        <div className="image">
                          <i className="fa fa-mobile" aria-hidden="true"></i>
                        </div>
                        <div className="info">
                          <h3 className="title">CONTACT</h3>
                          <p>
                            <b>NOIDA (Corporate Office)</b>
                          </p>
                          <p>
                            <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                            &#160; (+91)-120-4157540
                            <br />
                            <br />
                            <i
                              className="fa fa-mobile"
                              aria-hidden="true"
                            ></i>{" "}
                            &#160; (+91)-
                            <a href="tel:+919910907623">9910907623</a>
                            <br />
                            <br />
                            <i
                              className="fa fa-mobile"
                              aria-hidden="true"
                            ></i>{" "}
                            &#160; (+91)-
                            <a href="tel:+919910988623">9910988623</a>
                            <br />
                            <br />
                            <i
                              className="fa fa-mobile"
                              aria-hidden="true"
                            ></i>{" "}
                            &#160; (+91)-
                            <a href="tel:+919958153868">9958153868</a>
                            <br />
                            <br />
                          </p>
                        </div>
                      </div>
                      <div className="space"></div>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-6 col-lg-4">
                    <div className="box">
                      <div className="icon">
                        <div className="image">
                          <i
                            className="fa fa-map-marker"
                            aria-hidden="true"
                          ></i>
                        </div>

                        <div className="info">
                          <h3 className="title">ADDRESS</h3>
                          <p>
                            <b>NOIDA (Corporate Office)</b>
                          </p>
                          <p>
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                            ></i>{" "}
                            E-49, 1st Floor, Sector-3 Noida - 201301 (UP) India
                            Nearest Metro Station Sector-16 Noida, Behind HCL
                            Comnet
                          </p>
                        </div>
                      </div>
                      <div className="space"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ContactUs;
