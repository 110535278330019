import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";
export class ProcessDesignEngineering extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
          Process Design Engineering Training, Course Online in India
          </title>
          <meta
            name="description"
            content="MECCI Engineers provides the best online & offline Process Design Engineering Training Courses in India, Sudan, Nigeria,   Oman, Qatar, UAE & more countries."
          />technical
          <meta
            name="keywords"
            content=" Process Design Engineering Course, Process Design Training Institute, Process Design Course in Noida, Process Design Training India, Process Design Engineering Training"
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/process-design-engineering"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Process Design Engineering Course"
            imgsrc="assets/images/corporate-services.jpg"
            alt="process-design-engineering-course"
            title="Process Design Engineering Course"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  In chemical engineering, Process Design is the design of
                  processes for desired physical and/or chemical transformation
                  of materials. Process design is central to chemical
                  engineering, and it can be considered to be the summit of that
                  field, bringing together all of the field's components.
                  Process design is distinct from equipment design, which is
                  closer in spirit to the design of unit operations.
                </p>
                <p>
                  Process design can be the design of new facilities or it can
                  be the modification or expansion of existing facilities. The
                  design starts at a conceptual level and ultimately ends in the
                  form of fabrication and construction plants. Process design
                  documents serve to define the design and they ensure that the
                  design components fit together.
                </p>

                <hr />
                <Table
                  caption="Offered Courses in Process Design Engineering"
                  disctitle="Course Related to Process Detail Design"
                  disc1="Process Design Engineering (Full Time)"
                  pdf1="assets/images/course/courses-pdf/process-design-and-detailed-engineering.pdf"
                  duration1="45 Days"
                  disc2="Process Design Engineering (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/process-design-and-detailed-engineering.pdf"
                  duration2="3 Months"
                  disc3="Process Design Engineering (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/process-design-and-detailed-engineering.pdf"
                  duration3="8 Days"
                  disc4="Process Design Engineering (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/process-design-and-detailed-engineering.pdf"
                  duration4="1 Year"
                />
                <hr />
                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul style={{ listStyleType: "square" }}>
                  <li>
                    Overview of Industry and role of a Process Engineer in
                    various fields.
                  </li>
                  <li>
                    Basic design requirements based on the types of plants /
                    projects.
                  </li>
                  <li>Overview of Basic Engineering Package.</li>
                  <li>Introduction to PFD, P&amp;ID and PDS of equipments.</li>
                  <li>Relevant Codes and Standards used in industry.</li>
                  <li>Development of PFD: Process &amp; Utility.</li>
                  <li>
                    Mass and Energy balance - Design Basis, Sample Calculations
                    and Utility Sizing. Interlocks, Control .
                  </li>
                  <li>
                    Philosophy, Valves Selection and sizing, Safety valves
                    sizing.
                  </li>
                  <li>
                    Development of P&amp;ID: Process and Utility, Line Sizing
                    &amp; Pressure Drop Calculations.
                  </li>
                  <li>Pump Selection and sizing.</li>
                  <li>Heat Exchanger Design (Thermal Design).</li>
                  <li>Distillation Column Design.</li>
                  <li>
                    Available Software used in industry and their applications.{" "}
                  </li>
                  <li>
                    Personality Development &amp; Mock Interviews preparation.
                  </li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>EPC Project Based Training.</li>
                  <li>
                    Providing all Training Material and technical Drawing,
                    documents and case study materials.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProcessDesignEngineering;
