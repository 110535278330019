import React from 'react'

const Error404 = () => {
    return (
        <div>
          <h1>Oops! Page not found</h1>  
        </div>
    )
}

export default Error404
