import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";
export class BillingEngineeringTraining extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Mecciengineers- Billing Design Engineering Training, Course Online in India
            Experts
          </title>
          <meta
            name="description"
            content=" Top-rated billing Engineering Training.  We offer billing Design course in India, Sudan, Nigeria, Egypt, South Sudan, South Africa, Oman, Qatar, Dubai, Kuwait, UAE."
          />
          <meta
            name="keywords"
            content="MECCI Engineers provides the best online & offline Billing Design Engineering Training Courses in India, Sudan, Nigeria,   Oman, Qatar, UAE & more countries."
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/billing-engineering-training"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Billing Engineering Course"
            imgsrc="assets/images/course/course-title-img/billing-engineering-training.jpg"
            alt="billing-engineering-course"
            title="Billing Engineering Course"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  All organization should maintain certain procedural systems,
                  methods and norms for preparing bills and forwarding them
                  through the right channels for payments to the client and
                  contractor.
                </p>
                <p>
                  This training is a huge opportunity for civil engineers who
                  are beginners and working professionals to get a job in the
                  construction companies as a Billing Engineer by doing
                  professional training in the Billing Engineering Course.
                </p>
                <hr />
                <Table
                  caption="Offered Billing Engineering Course"
                  disctitle="Billing Engineering Course"
                  disc1="Billing Engineering Course (Full Time)"
                  pdf1="assets/images/course/courses-pdf/billing-engineering-course.pdf"
                  duration1="45 Days"
                  disc2="Billing Engineering Course (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/billing-engineering-course.pdf"
                  duration2="3 Months"
                  disc3="Billing Engineering Course (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/billing-engineering-course.pdf"
                  duration3="8 Days"
                  disc4="Billing Engineering Course (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/billing-engineering-course.pdf"
                  duration4="1 Year"
                />
                <hr />

                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul>
                  <li>Units & conversions</li>
                  <li>
                    Terminologies in Building works & Reading of Drawings –
                    Plan, Elevation, Sections, Blow-up Details, Services,
                    Structure -RCC & Steel, Drains, Sewage Disposal, Water Tank
                    etc etc
                  </li>
                  <li>Steel Reinforcement – Grades, BBS</li>
                  <li>
                    Estimation of – earthwork, foundation elements, basement
                    elements, retaining walls, beams, columns, formwork, shoring
                    and scaffolding work, Masonry, flooring, plastering, POP &
                    Punning, services – water supply & sewage disposal, internal
                    electrification, glass work, steel work, wood work, PVC
                    work, Glass work, SS work, Aluminium Work, Painting &
                    Polishing, External Elevation work.
                  </li>
                  <li>Costing – SORs, DSR, Index etc</li>
                  <li>Rate Analysis.</li>
                  <li>Cement consumption statement.</li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>
                    We Offer the best SEO Programming training and dedicated
                    placement assistance in Noida with properly planned training
                    modules and course content.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>Smart Labs with Real Latest Equipment’s.</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BillingEngineeringTraining;
