import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

function PartnerRegistration() {
  const [formData, setFormData] = useState({
    fname: "",
    email: "",
    phone: "",
    altphone: "",
    city: "",
    carpetarea: "",
    comment: "",
  });

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setFormData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fData = {
      method: "POST",
      url: "https://www.mecciengineer.com/mecci/public/api/savedata",
      Headers: {},
      data: {
        name: formData.fname,
        email: formData.email,
        phone: formData.phone,
        altphone: formData.altphone,
        city: formData.city,
        carpetarea: formData.carpetarea,
        course: "NA",
        type: "partner-reg",
        training_mode: "NA",
        description: formData.comment,
      },
    };

    let response = await axios(fData)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        e.target.reset();
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setValidationError(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-offset-2 col-sm-8">
          <form
            onSubmit={handleSubmit}
            className="contact-form partner"
            method="post"
          >
            <div className="form-group">
              <label htmlFor="weight" className="control-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                name="fname"
                value={formData.fname}
                onChange={inputEvent}
                placeholder="Name"
                required="required"
                autoFocus=""
                autoComplete="off"
              />
            </div>

            <div className="form-group form_left">
              <label htmlFor="weight" className="control-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formData.email}
                onChange={inputEvent}
                placeholder="Email"
                required="required"
                autoComplete="off"
              />
            </div>

            <div className="form-group">
              <label htmlFor="weight" className="control-label">
                Mobile No.
              </label>
              <input
                type="text"
                className="form-control"
                name="phone"
                value={formData.phone}
                onChange={inputEvent}
                maxLength="10"
                placeholder="Mobile No."
                required="required"
                autoComplete="off"
              />
            </div>

            <div className="form-group">
              <label htmlFor="weight" className="control-label">
                Alternative Mobile No.
              </label>
              <input
                type="text"
                className="form-control"
                name="altphone"
                value={formData.altphone}
                onChange={inputEvent}
                maxLength="10"
                placeholder="Alternate Mobile"
                required=""
                autoComplete="off"
              />
            </div>

            <div className="form-group">
              <label htmlFor="weight" className="control-label">
                City
              </label>
              <input
                type="text"
                className="form-control"
                name="city"
                value={formData.city}
                onChange={inputEvent}
                placeholder="City"
                required="required"
                autoComplete="off"
              />
            </div>

            <div className="form-group">
              <label htmlFor="weight" className="control-label">
                Carpet Area
              </label>
              <input
                type="text"
                className="form-control"
                name="carpetarea"
                value={formData.carpetarea}
                onChange={inputEvent}
                placeholder="Carpet Area"
                required="required"
                autoComplete="off"
              />
            </div>

            <div className="form-group">
              <label htmlFor="weight" className="control-label">
                Comment
              </label>
              <textarea
                className="form-control textarea-contact"
                rows="5"
                name="comment"
                value={formData.comment}
                onChange={inputEvent}
                placeholder="Why are you looking for this partnership?"
                required=""
              ></textarea>
              <br />
              <button className="btn btn-lg btn-default btn-send">
                <i className="fa fa-paper-plane" aria-hidden="true"></i>&#160;
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default PartnerRegistration;
