import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import { Helmet } from "react-helmet";
export class WhyMecci extends Component {
  render() {
    return (
      <>
      <Helmet>
          <link rel="canonical" href="https://www.mecciengineer.com/why-mecci" />
        </Helmet>
        <Helmet>
          <title>Top most engineering training institute in India.</title>
          <meta
            name="classification"
            content="MECCI Engineers, ISO 9001:2015 Certified EPC Training Institute presents various customized courses for working persons and fresher engineers. We offer Civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course in India, Sudan, Nigeria, Egypt, Bangladesh, South Sudan &amp; South Africa, Oman, Qatar, Dubai, Kuwait, UAE."
          />
          <meta
            name="keywords"
            content="Civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Why MECCI Engineers Private Limited"
            imgsrc="assets/images/course/course-title-img/why-mecci-engineer.jpg"
            alt="why-mecci"
            title="Why MECCI Engineers Private Limited"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  MEPL is a New Generation Expertise based ISO 9001:2015
                  Certified Company providing
                  <b> Consulting Services and Professional Trainings </b> for
                  Oil & Gas, Chemical, Energy and Infrastructure Sectors in
                  India and abroad.
                </p>

                <h2>PEOPLE @ MECCI Engineers</h2>
                <p>
                  MEPL team comprises of dynamic professional engineers and
                  designers having over 40 yrs of collective experience in the
                  design and construction of Industrial Plants including Oil,
                  Gas, Chemical, Petrochemical/Process Plants, & Electrical
                  Systems. People behind MEPL have trained thousands and they
                  are our most important assets. Their talent is in dealing with
                  our clients, teaching the courses, coordinating the complex
                  corporate training assignments that we are given, ensuring
                  that everything runs smoothly and striving for constant
                  improvement are essential ingredients in the continuing aim
                  for perfection.
                </p>
                <h3>MEPL We deliver every word we said.</h3>
                <p>
                  MEPL develops the training programs based on the practical
                  requirement of the market with current market scenario that
                  has been built up over the years in various specialist areas.
                  We set out to teach top-quality engineering skills training
                  courses and we have achieved this-we constantly strive to make
                  them as good as it's possible to but in the recent years we
                  have also refined our methods, adding several enhancements to
                  the construction stages of course description, design of the
                  courses and assessment. We believe that these are important to
                  our training participants; it's easy to see what the courses
                  consist of, what value they will gain from attending them and
                  how they can apply their new knowledge and skills in their
                  workplace in a structured and much needed way.
                </p>

                <p>
                  Thousands of Engineers, Designers & Draftsmen, Technicians and
                  Reserarchers have attended MEPL Training Programs. On a
                  national basis, MEPL is now unquestionably one of the best
                  professional training Company for Electrical/Piping/Plant
                  Engineering course provider in the field of technology
                  training. Many from outside India have also used MEPL as their
                  training provider.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WhyMecci;
