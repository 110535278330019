const BlogsData = [
    {   id    :7,
        title :"Electrical Design Training",
        imgsrc:"assets/images/course/electrical-engineering-course.jpg",
        desc  :"Our modern world is built on electricity. Learn to harness its power to create the next generation of electronics, sensors, and information networks.",
		alt   :"elecricaldesign",
        link  :"electrical-design-engineering",
    },
	{   id    :8,
        title :"Piping Design Training",
        imgsrc:"assets/images/course/piping-design-course.jpg",
        desc  :"MECCI offers the best Piping Design Training with 100% live project-based training. One of the truly multidisciplinary subject is a piping Design.",
		alt   :"piping-design",
        link  :"piping-design-engineering",
    },
	{   id    :9,
        title :"Process Design Training",
        imgsrc:"assets/images/course/process-engineering-course.jpg",
        desc  :"We provide extensive training where we provide them knowledge on Industrial process design and development of the final process and design documents.",
		alt   :"process",
        link  :"process-design-engineering",
    },
	{   id    :10,
        title :"Instrumentation Design Training",
        imgsrc:"assets/images/course/instrumentationesignengineering.jpg",
        desc  :"MECCI offer a variety of job oriented courses for Instrumentation Engineers. We have six months courses, three months courses and short term courses.",
		alt   :"instrumentation",
        link  :"instrumentation-design-engineering",
    },
	{   id    :11,
        title :"HVAC Design Training",
        imgsrc:"assets/images/course/hvac-engineering-course.jpg",
        desc  :"MECCI Engineers  has embarked on an ambitious program to certify HVAC Design and other professionals associated with our field for their capabilities.",
		alt   :"hvac-design",
        link  :"hvac-design-engineering",
    },
	{   id    :12,
        title :"Civil & Structural Design Training",
        imgsrc:"assets/images/course/civil-engineering-course.jpg",
        desc  :"This course is a methodical investigation to get economical specification of a structure or a structural element to carry the predicted load safely.",
		alt   :"civil",
        link  :"civil-and-structural-design-engineering",
    },
	{   id    :13,
        title :"Billing Engineering Training",
        imgsrc:"assets/images/course/billing-engineering-course.jpg",
        desc  :"We provides the broad practical training in Billing Engineering Course. All organization should maintain certain methods & norms for preparing bills.",
		alt   :"billing-engineering",
        link  :"billing-engineering-training",
    },
	{   id    :14,
        title :"Planning Engineering Training",
        imgsrc:"assets/images/course/planining-engineering-course.jpg",
        desc  :"A Project Planning Engineering Training programs workshops with a site manager to develop suitable construction methods and sequences for a project.",
		alt   :"planning-engineer",
        link  :"planning-engineering-training",
    },
	{   id    :15,
        title :"Solar Power Plant Design Training",
        imgsrc:"assets/images/course/solar-powerplant-engineering.jpg",
        desc  :"Solar Power Plant Design training will make you competent to design both OFF-Grid & ON-Grid plant, perform site survey & prepare a technical report.",
		alt   :"solar",
        link  :"solar-power-plant-design-engineering",
    },
	{   id    :16,
        title :"Substation Design Training",
        imgsrc:"assets/images/course/substation-design-course.jpg",
        desc  :"We Provides industry specific knowledge about power substations that you would not have known unless you worked within the utility industry related.",
		alt   :"substationdesigncourse",
        link  :"substation-design-course"
    },
	{   id    :1,
        title :"Solar Structure Design Training",
        imgsrc:"assets/images/course/solar-structure-design-course.jpg",
        desc  :"We provides all concepts related to Technology, Design & Planning training of Solar Power Plants along with the balance of Plants shall be explained.",
		alt   :"solar-structure-design",
        link  :"solar-structure-design-course",
    },{ id    :17,  
        title :"Hybrid & Electric Vehicle Design Training",
        imgsrc:"assets/images/course/hybrid-electric-vehicle-design-course.jpg",
        desc  :"This training covers hybrid & electric vehicle engineering concepts, theory, applications relevant to HEV, PHEV, EREV, BEV for passenger car industry.",
		alt   :"hybrid-electric-vehicle",
        link  :"hybrid-electric-vehicle-design-course",
    },
	{   id    :18,
        title :"Illumination Systems Designing Training",
        imgsrc:"assets/images/course/illumination-systems-designing-course.jpg",
        desc  :"Illumination systems training design of an illumination system requires balancing uniformity, maximizing the collection efficiency from the source.",
		alt   :"illumination-designing",
        link  :"illumination-systems-designing-course",
    },
	/*Corporate &nbsp;Services*/
	{   id    :19,
        title :"Project Management & Control Training",
        imgsrc:"assets/images/course/project-management.jpg",
        desc  :"MEP is parametric design, modeling, analysis software for mechanical, electrical, plumbing and introduces you to the user interfaces of the software.",
		alt   :"project-management-control",
        link  :"project-management-control",
    },
	{   id    :20,
        title :"Solar Power Plant Design Training",
        imgsrc:"assets/images/course/solar-structure-design-course.jpg",
        desc  :"MECCI Engineers provides Solar Power Plant Design Training course has been developed to meet the requirements of the National Occupational Standards.",
		alt   :"solar-power-plant-design-engineering",
        link  :"solar-power-plant-design-engineering",
    },
	{   id    :21,
        title :"Planning Package Workshop",
        imgsrc:"assets/images/course/planining-engineering-course.jpg",
        desc  :"MECCI Engineers does the whole the project engineering design and documentation from kick off to commissioning for all types of industrial facilities.",
		alt   :"planning-package",
        link  :"planning-package",
    },
	{   id    :22,
        title :"Instrumentation Design Engineering",
        imgsrc:"assets/images/course/instrumentation-design-course.jpg",
        desc  :"Instrumentation system is a part of instrumentation & design deals with specifications of equipment, layouts, wiring schematics, instrument index etc.",
		alt   :"instrumentation-design-engineering",
        link  :"instrumentation-design-engineering",
    },
	{   id    :23,
        title :"Process Design Workshop",
        imgsrc:"assets/images/course/process-design-workshop.jpg",
        desc  :"We provide extensive workshop where we provide them knowledge on Industrial process design and development of the final process and design documents.",
		alt   :"process-design",
        link  :"process-design-workshop",
    },
	{   id    :24,
        title :"45 Days Industrial Training",
        imgsrc:"assets/images/course/45-days-industrial-training.jpg",
        desc  :"As 45 days industrial training and internships create a lot of excitement among students/ Professionals as they set foot into the professional world.",
		alt   :"days-industrial-training",
        link  :"45-days-industrial-training",
    },
	/*IT Training*/
	{   id    :25,
        title :"SEO Training",
        imgsrc:"assets/images/course/seotraningcourse.jpg",
        desc  :"Search Engine and Web Optimization are the keys to developing and maintaining a digital presence. Are you looking for the best SEO training course?",
		alt   :"seo-training",
        link  :"seo-traning",
    },
	{   id    :26,
        title :"Web Designing Training",
        imgsrc:"assets/images/course/php-course.jpg",
        desc  :"Web Design is essential to bringing a website to life and creating the experience that you learn various techniques, tools and programming languages.",
		alt   :"web-designing",
        link  :"web-designing-training",
    },
	{   id    :27,
        title :"Core Java Training",
        imgsrc:"assets/images/course/java-training-course.jpg",
        desc  :"Our Java certification training course lets you master the Java programming language & OOP concepts. We provide best online/ offline training classes.",
		alt   :"corejavatraining",
        link  :"core-java-training",
    },
	{   id    :28,
        title :"Angular JS Training",
        imgsrc:"assets/images/course/angular-js-training.jpg",
        desc  :"Angular effectively is a valuable developer skill is designed for working professionals & fresher interested in pursuing a career in app development.",
		alt   :"angular-training",
        link  :"angular-js-training",
    },
	{   id    :29,
        title :"Dot NET Training",
        imgsrc:"assets/images/course/asp-dot-net.jpg",
        desc  :"Dot NET effectively is a valuable developer skill is designed for working professionals & fresher interested in pursuing a career in app development.",
		alt   : "dot-net-training",
        link  :"dot-net-training",
    },
	{   id    :30,
        title :"Software Manual Testing",
        imgsrc:"assets/images/course/software-testing.jpg",
        desc  :" We cover all manual testing concepts in detail with easy-to-understand examples and provide advanced level users to learn software testing concepts.",
		alt   :"manual-testing",
        link  :"software-manual-testing",
    },
	{   id    :31,
        title :"Software Automation Testing",
        imgsrc:"assets/images/course/automation-testing.jpg",
        desc  :"Software Automation Testing is a software testing technique that performs using special automated testing software tools to execute a test case suite.",
		alt   :"automation-testing",
        link  :"software-automation-testing",
    },
	{   id    :32,
        title :"Performance Testing",
        imgsrc:"assets/images/course/software-testing.jpg",
        desc  :"Performance testing is a testing measure that evaluates the speed, responsiveness and stability of a computer, network, software under a workload.",
		alt   :"performance-testing",
        link  :"performance-testing",
    },
	/*NON-IT Training*/
	{   id    :33,
        title :"Accounts, GST and Taxation Training",
        imgsrc:"assets/images/course/accountsgstandtaxationtraining.jpg",
        desc  :"This accounting Training will also provide you with a strong foundation in Accounting & Taxation with an emphasis on developing your analytical skills.",
		alt   :"account-taxation-gst-training",
        link  :"accounts-gst-and-taxation-training",
    },
	{   id    :34,
        title :"Pay-Roll Compliance Training",
        imgsrc:"assets/images/course/pay-rollcompliancetraining.jpg",
        desc  :"Payroll compliance not just involves complexity but also a share of sensitivity on the topic. The payroll for an employee includes his benefits, works.",
		alt   :"statutory-compliance-services",
        link  :"pay-roll-compliance-training",
    },
	{   id    :35,
        title :"Advanced Excel for Accounting Training",
        imgsrc:"assets/images/course/advancedexcelforaccountingtraining.jpg",
        desc  :"Are you an accounting professional with a good grasp of Excel who wants to take your skills to the next level? This course is designed just for you.",
		alt   :"advanced-excel-accounting-training",
        link  :"advanced-excel-for-accounting-training",
    },
	{   id    :36,
        title :"Advanced Tally Training",
        imgsrc:"assets/images/course/advancedtallytraining.jpg",
        desc  :"We cater to your complex business requirements. By specialized skills, we mean building solutions & deliver the right-fit solution for your business.",
		alt   :"advanced-tally-training",
        link  :"advanced-tally-training",
    },
	{   id    :37,
        title :"HR Generalist Training",
        imgsrc:"assets/images/course/hr-generalist-course.jpg",
        desc  :"HR Generalist are manage daily operations, take care of administration tasks, HR policies/ programs, ensure company compliance within an organization.",
		alt   :"hr-generalist-course",
        link  :"hr-generalist-course",
    },
	{   id    :38,
        title :"ERP SAP HR Training",
        imgsrc:"assets/images/course/erpsaphrtraining.jpg",
        desc  :"Start your journey today and discover our wide range of traditional classroom and virtual events, web seminars and course of ERP SAP HR Training.",
		alt   :"erp-sap-hr-training",
        link  :"erp-sap-hr-training",
    },
	{   id    :39,
        title :"ERP SAP MM Training",
        imgsrc:"assets/images/course/erp-sap-mmtraining.jpg",
        desc  :"ERP SAP MM (Materials Management) is one of the fundamental modules of SAP ERP system that is implemented in most of the installations of SAP.",
		alt   :"erp-sap-mm-training",
        link  :"erp-sap-mm-training",
    },
	{   id    :40,
        title :"ERP SAP PM Training",
        imgsrc:"assets/images/course/erpsappmtraining.jpg",
        desc  :"Start your journey today and discover our wide range of traditional classroom and virtual events, web seminars and course of ERP SAP PM Training.",
		alt   :"erp-sap-pm-training",
        link  :"erp-sap-pm-training",
    },{ id    :41,  
        title :"ERP SAP PP Training",
        imgsrc:"assets/images/course/erpsappptraining.jpg",
        desc  :"Start your journey today and discover our wide range of traditional classroom and virtual events, web seminars and course of ERP SAP PP Training.",
		alt   :"erp-sap-pp-training",
        link  :"erp-sap-pp-training",
    },{ id    :42,  
        title :"ERP SAP PS Training",
        imgsrc:"assets/images/course/erp-sap-ps-training.jpg",
        desc  :"Start your journey today and discover our wide range of traditional classroom and virtual events, web seminars and course of ERP SAP PS Training.",
		alt   :"erp-sap-ps-training",
        link  :"erp-sap-ps-training",
    },{ id    :43,  
        title :"ERP SAP QM Training",
        imgsrc:"assets/images/course/erpsapqmtraining.jpg",
        desc  :"Start your journey today and discover our wide range of traditional classroom and virtual events, web seminars and course of ERP SAP QM Training.",
		alt   :"erp-sap-qm-training",
        link  :"erp-sap-qm-training",
    },{ id    :44,  
        title :"ERP SAP HANA Training",
        imgsrc:"assets/images/course/erpsaphanatraining.jpg",
        desc  :"Start your journey today and discover our wide range of traditional classroom and virtual events, web seminars and course of ERP SAP HANA Training.",
		alt   :"erp-sap-hana-training",
        link  :"erp-sap-hana-training",
    },
	{   id    :45,
        title :"SAP FICO Training",
        imgsrc:"assets/images/course/sapficotraining.jpg",
        desc  :"SAP FICO Finance analyze financial conditions of organization that helps professional perform various Accounting & Financial Management processes.",
		alt   :"erp-fico-training",
        link  :"sap-fico-training",
    },
	{   id    :46,
        title :"SAP Simple Finance Training",
        imgsrc:"assets/images/course/sap-simple-finance-training.jpg",
        desc  :"SAP Simple Finance analyze financial conditions of organization that helps professional perform various Accounting & Financial Management processes.",
		alt   :"sap-simple-finance-training",
        link  :"sap-simple-finance-training",
    },{ id    :47,  
        title :"SAP HR-HCM Training",
        imgsrc:"assets/images/course/sap-hr-hcm training.jpg",
        desc  :"Payroll compliance not just involves complexity but also a share of sensitivity on the topic and adhering to all federal, state and local regulations.",
		alt   :"sap-hr-hcm-training",
        link  :"sap-hr-hcm-training",
    }
	];
    export default BlogsData;