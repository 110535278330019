import React from "react";
import { Link } from "react-router-dom";
import mecciService, {
  itTraining
} from "../pages/CourseDataLink";
export default function SidebarItTraning() {
  return (
    <>
      <div className="colleft accordion"
          data-toggle="collapse"
          data-target="#ittra"
        >
        <h3>IT TRAINING</h3>
        <div id="ittra" className="collapse">
        <ul className="listunstyled">
		    <li><Link to={`/${itTraining[0].link}`} target="_blank">{itTraining[0].title}</Link></li>
			<li><Link to={`/${itTraining[1].link}`} target="_blank">{itTraining[1].title} </Link></li>
			<li><Link to={`/${itTraining[2].link}`} target="_blank">{itTraining[2].title}</Link></li>
			<li><Link to={`/${itTraining[3].link}`} target="_blank">{itTraining[3].title} </Link></li>
			<li><Link to={`/${itTraining[4].link}`} target="_blank">{itTraining[4].title}  </Link></li>
			<li><Link to={`/${itTraining[5].link}`} target="_blank">{itTraining[5].title}</Link></li>
			<li><Link to={`/${itTraining[6].link}`} target="_blank">{itTraining[6].title}</Link></li>
			<li><Link to={`/${itTraining[7].link}`} target="_blank">{itTraining[7].title}</Link></li>
			
		</ul>
          </div>
      </div>
    </>
  )
}

