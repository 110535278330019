import React, { Component } from "react";
import { Link } from "react-router-dom";
import mecciService, { nonItTraining } from "../pages/CourseDataLink";

export class SidebarMecciServicesHome extends Component {
  render() {
    return (
      <>
        <div
          className="colleft accordion"
          data-toggle="collapse"
          data-target="#mecciservices"
        >
          <h3>MECCI SERVICES</h3>
          <div id="mecciservices" className="collapse in" >
            <ul className="listunstyled">
              <li>
                <Link to={mecciService[0].link} target="_blank">
                  {mecciService[0].title}
                </Link>
              </li>
              <li>
                <Link to={mecciService[1].link} target="_blank">
                  {mecciService[1].title}{" "}
                </Link>
              </li>
              <li>
                <Link to={mecciService[2].link} target="_blank">
                  {mecciService[2].title}
                </Link>
              </li>
              <li>
                <Link to={mecciService[3].link} target="_blank">
                  {mecciService[3].title}{" "}
                </Link>
              </li>
              <li>
                <Link to={mecciService[4].link} target="_blank">
                  {mecciService[4].title}{" "}
                </Link>
              </li>
              <li>
                <Link to={mecciService[5].link} target="_blank">
                  {mecciService[5].title}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default SidebarMecciServicesHome;
