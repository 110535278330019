import React from "react";
function PageTitle(params) {
  return (
    <>
      <title>{params.page_header_title}</title>
      <div className="row">
        <div className="col-md-12 col-md-12a">
          <img
            src={params.imgsrc}
            className="img-responsive"
            style={{ margin: "0 auto", height: '390px' }}
            alt={params.alt}
          />
          <div className="page-header">
            <h1 className="page-title">{params.title}</h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageTitle;
