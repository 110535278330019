import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";

export class HybridElectricVehicleDesignCourse extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
          Hybrid Electric Vehicle Design Training, Course Online in India"
          </title>
          <meta
            name="description"
            content=" Top-rated hybrid design Engineering Training.  We offer hybrid Design course in India, Sudan, Nigeria, Egypt, South Sudan, South Africa, Oman, Qatar, Dubai, Kuwait, UAE."
          />
          <meta
            name="keywords"
            content=" MECCI Engineers provides the best online & offline Electric Hybrid Vehicle Design Training in India, Sudan, Nigeria,   Oman, Qatar, UAE & more countries."
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/hybrid-electric-vehicle-design-course"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Hybrid & Electric Vehicle Design Course"
            imgsrc="assets/images/course/course-title-img/hybrid-electric-vehicle-design-course.jpg"
            alt="Hybrid & Electric Vehicle Design Course"
            title="Hybrid & Electric Vehicle Design Course"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  As electric motors become more ubiquitous in our everyday
                  lives, found in just about everything we use from automobiles
                  to kitchen appliances to IOT-connected and smart devices,
                  itâ€™s more important than ever to understand the machine
                  characteristics, modern control techniques, and associated
                  interactions with electronic drives that power these objects.
                  Computer-based tools for estimating machine parameters and
                  performance can remarkably speed up a designerâ€™s
                  understanding of when different control and machine design
                  assumptions are applicable, and how gracefully these
                  assumptions fail as performance limits are approached.
                </p>
                <p>
                  There is great scope of this course as it has a lot of job
                  opportunities for the students who are seeking a good job in
                  technical field. This course is a blend of current technology
                  and the future technology as far as the automobile prime
                  movers are concerned. The objective of the course are knowing
                  the latest Engine technology and looking for future technology
                  to meet the stringent Emission norms and making the students
                  familiar with Hybrid and Electric Technology which will be the
                  future prime movers of the automobile..
                </p>

                <hr />
                <Table
                  caption="Offered Courses in Hybrid & Electric Vehicle Design course"
                  disctitle="Course Related to Hybrid & Electric Vehicle Design"
                  disc1="Hybrid & Electric Vehicle Design course (Full Time)"
                  pdf1="assets/images/course/courses-pdf/hybrid-electrical-vehicle.pdf"
                  duration1="45 Days"
                  disc2="Hybrid & Electric Vehicle Design course (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/hybrid-electrical-vehicle.pdf"
                  duration2="3 Months"
                  disc3="Hybrid & Electric Vehicle Design course (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/hybrid-electrical-vehicle.pdf"
                  duration3="8 Days"
                  disc4="Hybrid & Electric Vehicle Design course (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/hybrid-electrical-vehicle.pdf"
                  duration4="1 Year"
                />
                <hr />

                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul>
                  <li>Introduction: Electric Vehicle</li>
                  <li>Classification of Electric Motors - construction</li>
                  <li>Motor Torque Calculations For Electric Vehicle</li>
                  <li>Electric Vehicle Architecture Design</li>
                  <li>Electric Drive and controller</li>
                  <li>Energy Storage Solutions(ESS)</li>
                  <li>
                    Battery Management System(BMS)/Energy Management System
                  </li>
                  <li>Control Unit</li>
                  <li>Logic functions in electrical controls</li>
                  <li>
                    Servo motor, Stepper motor & Linear motor Plugging & Dynamic
                    braking of motors
                  </li>
                  <li>Logic functions in electrical controls</li>
                  <li>
                    Trouble shooting in motors, control/power circuits &
                    starters
                  </li>
                  <li>INDIAN and GLOBAL Scenario</li>
                  <li>
                    Projects Development & industrial guidance programme session
                  </li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>
                    Help employees clearly understand what training programs can
                    speed up their career progress in their desired direction.
                  </li>
                  <li>
                    Providing all Training Material and technical Drawing,
                    documents and case study materials.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HybridElectricVehicleDesignCourse;
