const CourseData = [
    {   id    :1,
        title :"Why Mecci",
        imgsrc:"assets/images/course/whymecci.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"whymecci",
        link  :"why-mecci",
    },
	{   id    :2,
        title :"Engineering Services",
        imgsrc:"assets/images/course/engineeringservices.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"engineeringservices",
        link  :"engineering-services",
    },
	{   id    :3,
        title :"Online | Classroom Training",
        imgsrc:"assets/images/course/online-class.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"onlinetraining",
        link  :"online-classroom-training",
    },
	{   id    :4,
        title :"Manpower Services",
        imgsrc:"assets/images/course/mep-engineering-course.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"manpower",
        link  :"manpower-services",
    },
	{   id    :5,
        title :"Blogs",
        imgsrc:"assets/images/course/blog.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"blogs",
        link  :"blogs",
    },
	{   id    :6,
        title :"Gallary",
        imgsrc:"assets/images/course/gallery.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"gallary",
        link  :"gallary",
    },
	/*EPC - Training*/
	{   id    :7,
        title :"Electrical Design Engineering",
        imgsrc:"assets/images/course/electrical-engineering-course.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"elecricaldesign",
        link  :"electrical-design-engineering",
    },
	{   id    :8,
        title :"Piping Design Engineering",
        imgsrc:"assets/images/course/piping-design-course.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"piping-design",
        link  :"piping-design-engineering",
    },
	{   id    :9,
        title :"Process Design Engineering",
        imgsrc:"assets/images/course/process-engineering-course.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"process",
        link  :"process-design-engineering",
    },
	{   id    :10,
        title :"Instrumentation Design Engineering",
        imgsrc:"assets/images/course/instrumentationesignengineering.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"instrumentation",
        link  :"instrumentation-design-engineering",
    },
	{   id    :11,
        title :"HVAC Design Engineering",
        imgsrc:"assets/images/course/hvac-engineering-course.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"hvac-design",
        link  :"hvac-design-engineering",
    },
	{   id    :12,
        title :"Civil & Structural Design Engineering",
        imgsrc:"assets/images/course/civil-engineering-course.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"civil",
        link  :"civil-and-structural-design-engineering",
    },
	{   id    :13,
        title :"Billing Engineering Training",
        imgsrc:"assets/images/course/billing-engineering-course.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"billing-engineering",
        link  :"billing-engineering-training",
    },
	{   id    :14,
        title :"Planning Engineering Training",
        imgsrc:"assets/images/course/planining-engineering-course.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"planning-engineer",
        link  :"planning-engineering-training",
    },
	{   id    :15,
        title :"Solar Power Plant Design Engineering",
        imgsrc:"assets/images/course/solar-powerplant-engineering.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"solar",
        link  :"solar-power-plant-design-engineering",
    },
	{   id    :16,
        title :"Substation Design Course",
        imgsrc:"assets/images/course/substation-design-course.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small",
		alt   :"substationdesigncourse",
        link  :"substation-design-course"
    },
	{   id    :1,
        title :"Solar Structure Design Course",
        imgsrc:"assets/images/course/solar-structure-design-course.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"solar-structure-design",
        link  :"solar-structure-design-course",
    },{ id    :17,  
        title :"Hybrid & Electric Vehicle Design Course",
        imgsrc:"assets/images/course/hybrid-electric-vehicle-design-course.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"hybrid-electric-vehicle",
        link  :"hybrid-electric-vehicle-design-course",
    },
	{   id    :18,
        title :"Illumination Systems Designing Course",
        imgsrc:"assets/images/course/illumination-systems-designing-course.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"illumination-designing",
        link  :"illumination-systems-designing-course",
    },
	/*Corporate &nbsp;Services*/
	{   id    :19,
        title :"Project Management and Control",
        imgsrc:"assets/images/course/project-management.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"project-management-control",
        link  :"project-management-control",
    },
	{   id    :20,
        title :"Solar Power Plant Design Engineering",
        imgsrc:"assets/images/course/solar-power-plant-design-engineering.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"solar-power-plant-design-engineering",
        link  :"solar-power-plant-design-engineering",
    },
	{   id    :21,
        title :"Planning Package",
        imgsrc:"assets/images/course/planningpackage.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"planning-package",
        link  :"planning-package",
    },
	{   id    :22,
        title :"Instrumentation Design Engineering",
        imgsrc:"assets/images/course/instrumentation-design-course.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"instrumentation-design-engineering",
        link  :"instrumentation-design-engineering",
    },
	{   id    :23,
        title :"Process Design Workshop",
        imgsrc:"assets/images/course/process-design-workshop.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"process-design",
        link  :"process-design-workshop",
    },
	{   id    :24,
        title :"45 Days Industrial Training",
        imgsrc:"assets/images/course/45-days-industrial-training.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"days-industrial-training",
        link  :"45-days-industrial-training",
    },
	/*IT Training*/
	{   id    :25,
        title :"SEO Training",
        imgsrc:"assets/images/course/seotraningcourse.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"seo-training",
        link  :"seo-traning",
    },
	{   id    :26,
        title :"Web Designing Training",
        imgsrc:"assets/images/course/php-course.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"web-designing",
        link  :"web-designing-training",
    },
	{   id    :27,
        title :"Core Java Training",
        imgsrc:"assets/images/course/java-training-course.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"corejavatraining",
        link  :"core-java-training",
    },
	{   id    :28,
        title :"Angular JS Training",
        imgsrc:"assets/images/course/angular-js-training.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"angular-training",
        link  :"angular-js-training",
    },
	{   id    :29,
        title :"Dot NET Training",
        imgsrc:"assets/images/course/asp-dot-net.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   : "dot-net-training",
        link  :"dot-net-training",
    },
	{   id    :30,
        title :"Software Manual Testing",
        imgsrc:"assets/images/course/software-testing.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"manual-testing",
        link  :"software-manual-testing",
    },
	{   id    :31,
        title :"Software Automation Testing",
        imgsrc:"assets/images/course/automation-testing.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"automation-testing",
        link  :"software-automation-testing",
    },
	{   id    :32,
        title :"Performance Testing",
        imgsrc:"assets/images/course/software-testing.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"performance-testing",
        link  :"performance-testing",
    },
	/*NON-IT Training*/
	{   id    :33,
        title :"Accounts, GST and Taxation Training",
        imgsrc:"assets/images/course/accountsgstandtaxationtraining.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"account-taxation-gst-training",
        link  :"accounts-gst-and-taxation-training",
    },
	{   id    :34,
        title :"Pay-Roll Compliance Training",
        imgsrc:"assets/images/course/pay-rollcompliancetraining.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"statutory-compliance-services",
        link  :"pay-roll-compliance-training",
    },
	{   id    :35,
        title :"Advanced Excel for Accounting Training",
        imgsrc:"assets/images/course/advancedexcelforaccountingtraining.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"advanced-excel-accounting-training",
        link  :"advanced-excel-for-accounting-training",
    },
	{   id    :36,
        title :"Advanced Tally Training",
        imgsrc:"assets/images/course/advancedtallytraining.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"advanced-tally-training",
        link  :"advanced-tally-training",
    },
	{   id    :37,
        title :"HR Generalist Course",
        imgsrc:"assets/images/course/hr-generalist-course.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"hr-generalist-course",
        link  :"hr-generalist-course",
    },
	{   id    :38,
        title :"ERP SAP HR Training",
        imgsrc:"assets/images/course/erpsaphrtraining.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"erp-sap-hr-training",
        link  :"erp-sap-hr-training",
    },
	{   id    :39,
        title :"ERP SAP MM Training",
        imgsrc:"assets/images/course/erp-sap-mmtraining.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"erp-sap-mm-training",
        link  :"erp-sap-mm-training",
    },
	{   id    :40,
        title :"ERP SAP PM Training",
        imgsrc:"assets/images/course/erpsappmtraining.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"erp-sap-pm-training",
        link  :"erp-sap-pm-training",
    },{ id    :41,  
        title :"ERP SAP PP Training",
        imgsrc:"assets/images/course/erpsappptraining.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"erp-sap-pp-training",
        link  :"erp-sap-pp-training",
    },{ id    :42,  
        title :"ERP SAP PS Training",
        imgsrc:"assets/images/course/erp-sap-ps-training.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"erp-sap-ps-training",
        link  :"erp-sap-ps-training",
    },{ id    :43,  
        title :"ERP SAP QM Training",
        imgsrc:"assets/images/course/erpsapqmtraining.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"erp-sap-qm-training",
        link  :"erp-sap-qm-training",
    },{ id    :44,  
        title :"ERP SAP HANA Training",
        imgsrc:"assets/images/course/erpsaphanatraining.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"erp-sap-hana-training",
        link  :"erp-sap-hana-training",
    },
	{   id    :45,
        title :"SAP FICO Training",
        imgsrc:"assets/images/course/sapficotraining.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"erp-fico-training",
        link  :"sap-fico-training",
    },
	{   id    :46,
        title :"SAP Simple Finance Training",
        imgsrc:"assets/images/course/sap-simple-finance-training.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"sap-simple-finance-training",
        link  :"sap-simple-finance-training",
    },{ id    :47,  
        title :"SAP HR-HCM Training",
        imgsrc:"assets/images/course/sap-hr-hcm training.jpg",
        desc  :"MECCI NOIDA offers a number of customized Corporate training programs for small,",
		alt   :"sap-hr-hcm-training",
        link  :"sap-hr-hcm-training",
    }
	];
    export default CourseData;