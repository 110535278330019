import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";

export class ERPSAPMMTraining extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best ERP SAP MM Training Course</title>
          <meta name="description" content="Best ERP SAP MM Training Course" />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/erp-sap-mm-training"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Best ERP SAP MM Training Course"
            imgsrc="assets/images/course/course-title-img/erp-sap-mm-training.jpg"
            alt="Best ERP SAP MM Training Course"
            title="Best ERP SAP MM Training Course"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div class="colright">
                <p>
                  ERP SAP MM (Materials Management) is one of the fundamental
                  modules of SAP ERP system that is implemented in most of the
                  installations of SAP. This module is related to the logistics
                  part of SAP solution.
                </p>
                <p>
                  ERP SAP MM Course will prepare the students to learn and
                  understand all the end-to-end implementation steps to
                  configure SAP MM modules for any organization.
                </p>

                <hr />
                <Table
                  caption="Offered Courses in ERP SAP MM Training"
                  disctitle="Course Related to ERP SAP MM Training"
                  disc1="ERP SAP MM Training (Full Time)"
                  pdf1="assets/images/course/courses-pdf/erp-sap-mm-training.pdf"
                  duration1="45 Days"
                  disc2="ERP SAP MM Training (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/erp-sap-mm-training.pdf"
                  duration2="3 Months"
                  disc3="ERP SAP MM Training (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/erp-sap-mm-training.pdf"
                  duration3="8 Days"
                  disc4="ERP SAP MM Training (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/erp-sap-mm-training.pdf"
                  duration4="6 Months"
                />

                <hr />

                <h3>Note: All above coures avaliable in ONLINE</h3>

                <h3>What You Will Learn</h3>
                <ul>
                  <li>
                    Introduction to Human resource technology and Analytics
                  </li>
                  <li>Employee data, data sources and metrics</li>
                  <li>
                    The Employees Provident Funds and Miscellaneous Provision
                    Act - 1952 (EPF)
                  </li>
                  <li>
                    Correctly complete year end requirements and establish the
                    year-beginning requirements
                  </li>
                  <li>
                    Follow the proper policies, procedures, and documentation
                    requirements for garnishments and levies
                  </li>
                  <li>
                    Handle stock options, expense reimbursements, relocation,
                    and other expenses
                  </li>
                  <li>
                    The Contract Labour (Regulation & Abolition) Act - 1970
                  </li>
                  <li>The Child Labour (Prohibition & Regulation Act), 1986</li>
                  <li>The Minimum Wages Act-1948</li>
                  <li>
                    Properly identify, pay, and withhold taxes for employees
                  </li>
                  <li>
                    The Employment Exchange (Compulsory Notification of
                    Vacancies) ACT-1959
                  </li>
                  <li>Statutory benefits administration</li>
                  <li>
                    The Interstate Migrant Workmen (Regulation of Employment and
                    Conditions of Services) ACT, 1979
                  </li>
                  <li>The Industrial Employment (Standing Orders) ACT 1946</li>
                  <li>The Industrial Disputes ACT 1947</li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>
                    Help employees clearly understand what training programs can
                    speed up their career progress in their desired direction.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ERPSAPMMTraining;
