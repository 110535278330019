import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import BrandLogoCard from "../components/BrandLogoCard";
import BrandLogoData from "../pages/BrandLogoData";
import PartnerRegistration from "../components/PartnerRegistration";
import { Helmet } from "react-helmet";

export class Partener extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>RAINING FRANCHISE OPPORTUNITIES</title>
          <meta name="description" content="RAINING FRANCHISE OPPORTUNITIES" />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/partener"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Partener"
            imgsrc="assets/images/skill_partner.jpg"
            alt="Partener"
            title="TRAINING FRANCHISE OPPORTUNITIES"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  Start a business teaching others with our list of Training
                  franchise opportunities. A franchise in education not only
                  allows you to run a business, but it also allows you to help
                  improve the training of professional in need.
                </p>
                <h3>OUR VALUED PARTNERS</h3>
                <p>
                  Do you have the next big idea to promote high quality
                  education for millions children in our country? Are you
                  willing to use all your entrepreneurial energy to make your
                  dream a reality? Are you looking for funders and mentors to
                  support your dream venture? If yes, we are looking for you!
                </p>
                <h3>BECOME A MECCI PARTNERS TODAY</h3>
                <h2>Registration</h2>
                <PartnerRegistration />

                <h3>OUR PLACEMENT CLIENTS</h3>
                <div className="row">
                  <BrandLogoCard imgsrc={BrandLogoData[0].imgsrc} />
                  <BrandLogoCard imgsrc={BrandLogoData[1].imgsrc} />
                  <BrandLogoCard imgsrc={BrandLogoData[2].imgsrc} />
                  <BrandLogoCard imgsrc={BrandLogoData[3].imgsrc} />
                  <BrandLogoCard imgsrc={BrandLogoData[4].imgsrc} />
                  <BrandLogoCard imgsrc={BrandLogoData[5].imgsrc} />
                  <BrandLogoCard imgsrc={BrandLogoData[6].imgsrc} />
                  {/* <BrandLogoCard 
                 imgsrc={BrandLogoData[7].imgsrc}
               /> */}
                  <BrandLogoCard imgsrc={BrandLogoData[8].imgsrc} />
                  {/* <BrandLogoCard 
                 imgsrc={BrandLogoData[9].imgsrc}
               /> */}
                  <BrandLogoCard imgsrc={BrandLogoData[10].imgsrc} />
                  <BrandLogoCard imgsrc={BrandLogoData[11].imgsrc} />
                  <BrandLogoCard imgsrc={BrandLogoData[12].imgsrc} />
                  <BrandLogoCard imgsrc={BrandLogoData[13].imgsrc} />
                </div>
              </div>
            </div>

            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Partener;
