import React from "react";
//import CourseData from "../pages/CourseData";
import { Link } from "react-router-dom";
function BlogCard(params) {
  
  return (
    <>
      <div className="col-md-4">
        <div
          className="my-2 mx-auto p-relative bg-white shadow-1 blue-hover blogscard blog"
          style={{ overflow: "hidden", borderRadius: "1px" }}
        >
          <img
            src={params.imgsrc}
            alt={params.alt}
            className="d-block w-full"
          />

          <div className="px-2 py-2">
            <h3
              className="ff-serif font-weight-normal text-black card-heading mt-0 mb-1"
              style={{ lineHeight: "1.5" }}
            >
              {params.title}
            </h3>

            <p className="mb-1">{params.desc}</p>
          </div>

          <Link
            to={params.link}
            className="text-uppercase d-inline-block font-weight-medium lts-2px ml-2 mb-2 text-center styled-link"
          >
            Read More
          </Link>
        </div>
        
      </div>
    </>
  );
}

export default BlogCard;
