import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";

export class SubstationDesignCourse extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
          Sub Station Design Engineering Training, Course Online in India
          </title>
          <meta
            name="description"
            content=" MECCI Engineers provides the best online & offline Sub Station Design Engineering Training Courses in India, Sudan, Nigeria,   Oman, Qatar, UAE & more countries."
          />

          <meta
            name="keywords"
            content=" Substation Design Engineering Course, Substation Design Training Institute, Substation Design Course in Noida, Substation Design Training India, Substation Design Engineering Training"
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/substation-design-course"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Substation Design Course"
            imgsrc="assets/images/course/course-title-img/substation-design-course.jpg"
            alt="Substation Design Course"
            title="Substation Design Course"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  High voltage substation design is a complex engineering
                  activity that embraces a wide variety of disciplines. This
                  comprehensive overview brings these disciplines together to
                  give you an interactive guide to high voltage, air or gas
                  insulated substation design.
                </p>
                <p>
                  Depending on the functions performed by a substation, the
                  configuration and complexity can be quite varied. The skill of
                  the designer is to anticipate the present and future needs
                  that the substation will cater to, select appropriate design
                  configuration and calculate the ratings of main equipment such
                  as busbars, transformers and switchgear so as to ensure
                  trouble free service over a number of decades. Sufficient
                  thought should be given to the need for maintaining critical
                  substation equipment and appropriate redundancies must be
                  planned as well. This is essential as otherwise the consumers
                  fed by the substation can suffer frequent supply outages,
                  which is undesirable from service as well as financial point
                  of view.
                </p>
                <p>
                  These complex issues will be dealt in this program using a
                  simple step-by-step approach through real life examples. At
                  each step, the basic design approach and calculations will be
                  performed by the students to clearly understand the concepts
                  that are being taught.
                </p>
                <hr />
                <Table
                  caption="Offered Courses in Substation Design Course"
                  disctitle="Course Related to Substation Design Course"
                  disc1="Substation Design Course (Full Time)"
                  pdf1="assets/images/course/courses-pdf/sub-station-design-engineering.pdf"
                  duration1="45 Days"
                  disc2="Substation Design Course (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/sub-station-design-engineering.pdf"
                  duration2="3 Months"
                  disc3="Substation Design Course (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/sub-station-design-engineering.pdf"
                  duration3="8 Days"
                  disc4="Substation Design Course (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/sub-station-design-engineering.pdf"
                  duration4="6 Months"
                />

                <hr />

                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul>
                  <li>
                    Calculate substation capacity based on load requirements and
                    decide upon a suitable location
                  </li>
                  <li>
                    Examples/case studies of system studies required and system
                    study specifications
                  </li>
                  <li>
                    Select a suitable configuration of the substation and size
                    the main equipment
                  </li>
                  <li>Gas Insulated Equipment</li>
                  <li>
                    Plan for appropriate system studies and draw up a
                    specification for system studies
                  </li>
                  <li>
                    Decide on the layout of an outdoor HV switchyard showing
                    main and auxiliary equipment
                  </li>
                  <li>
                    Choose equipment for fault limiting, VAR compensation and
                    harmonic control and adjust the switchyard layout to include
                    these systems
                  </li>
                  <li>Substation equipment for harmonic control</li>
                  <li>
                    Plan indoor substations with medium voltage switchgear and
                    select the required equipment ratings
                  </li>
                  <li>
                    Work out a suitable layout for the MV switchgear and
                    associated equipment
                  </li>
                  <li>MV substation design and specifications</li>
                  <li>
                    Examples/case studies of mv substation equipment design
                  </li>
                  <li>
                    Examples/case studies of mv substation equipment layout
                  </li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>EPC Project Based Training.</li>
                  <li>
                    Providing all Training Material and technical Drawing,
                    documents and case study materials.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SubstationDesignCourse;
