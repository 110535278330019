import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
function DownloadTablePdfCard(params) {
  const [pdfData, setPdfData] = useState({
    course  : "",
    duration: "",
    link    : "",
    status:false
  });

  const [isDownload, setDownload] = useState(true);

  const [formData, setFormData] = useState({
    fname   : "",
    email   : "",
    phone   : "",
 
  });

function downloadData(course,link,duration)
{
  setPdfData({'course':course,'link':link,'duration':duration});
  setDownload(!isDownload);
}
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setFormData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fData = {
      method: "POST",
      url: "https://www.mecciengineer.com/mecci/api/downloadcourse",
      Headers: {},
      data: {
        name:     formData.fname,
        email:    formData.email,
        phone:    formData.phone,
        course:   pdfData.course,
        link:     pdfData.link,
        duration: pdfData.duration
      },
    };
    
    let response = await axios(fData)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        //window.location.href = pdfData.link;
      })
      .catch(({ response }) => {
        if (response.status === 422) {
         // setValidationError(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        //  setResMessage("Error! data not saved");
        }
      });
      setFormData({fname:'',email:'',phone:''});
  };
  
  return (
    <>
      <table className="table table-responsive table-bordered">
        <caption>
          <h3>{params.caption}</h3>
        </caption>
        <thead>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">{params.disctitle}</th>
            <th scope="col">Downloads</th>
            <th scope="col">Duration</th>
          </tr>
        </thead>
        <tbody>
          <tr className="info">
            <th scope="row">1</th>
            <td>{params.disc1}</td>
            <td>
            { isDownload ?
            <img
                src="assets/images/PDFIMG.jpg"
                alt="download"
                data-course={params.disc1}
                data-duration={params.duration1}
                data-link={params.pdf1}
                onClick={()=>downloadData(params.disc1,params.pdf1,params.duration1)}
                data-toggle="modal"
                data-target="#GSCCModal"
              />
              :
               <a href={params.pdf1} download={params.caption} className="dwnpdf">
                <img src="assets/images/PDFIMG.jpg" alt="download" />
              </a> 
              }
            </td>
            <td>{params.duration1}</td>
          </tr>
          <tr className="warning">
            <th scope="row">2</th>
            <td>{params.disc2}</td>
            <td>
            { isDownload ?
            <img
                src="assets/images/PDFIMG.jpg"
                alt="download"
                data-course={params.disc2}
                data-duration={params.duration2}
                data-link={params.pdf2}
                onClick={()=>downloadData(params.disc2,params.pdf2,params.duration2)}
                data-toggle="modal"
                data-target="#GSCCModal"
              />
              :
                 <a href={params.pdf2} download={params.caption}>
                <img src="assets/images/PDFIMG.jpg" alt="download" />
              </a> }
            </td>
            <td>{params.duration2}</td>
          </tr>
          <tr className="info">
            <th scope="row">3</th>
            <td>{params.disc3}</td>
            <td>
            { isDownload ?
            <img
                src="assets/images/PDFIMG.jpg"
                alt="download"
                data-course={params.disc3}
                data-duration={params.duration3}
                data-link={params.pdf3}
                onClick={()=>downloadData(params.disc3,params.pdf3,params.duration3)}
                data-toggle="modal"
                data-target="#GSCCModal"
              />
              :
              <a href={params.pdf3} download={params.caption}>
                <img src="assets/images/PDFIMG.jpg" alt="download" />
              </a> 
              }
            </td>
            <td>{params.duration3}</td>
          </tr>
          <tr className="warning">
            <th scope="row">4</th>
            <td>{params.disc4}</td>
            <td>
            { isDownload ?
              <img
                src="assets/images/PDFIMG.jpg"
                alt="download"
                data-course={params.disc4}
                data-duration={params.duration4}
                data-link={params.pdf4}
                onClick={()=>downloadData(params.disc4,params.pdf4,params.duration4)}
                data-toggle="modal"
                data-target="#GSCCModal"
              />
              :
              <a href={params.pdf4} download={params.caption}>
                <img src="assets/images/PDFIMG.jpg" alt="download" />
              </a>
               }
            </td>
            <td>{params.duration4}</td>
          </tr>
        </tbody>
      </table>
      <div
        id="GSCCModal"
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
              <h4 className="modal-title" id="myModalLabel">
              {params.caption}
              </h4>
            </div>
            <form
                onSubmit={handleSubmit}
                className="contact-form"
                method="post"
              >
            <div className="modal-body">
              
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="fname"
                    value={formData.fname}
                    onChange={inputEvent}
                    placeholder="Name"
                    required="required"
                    autoComplete="off"
                    autoFocus=""
                  />
                </div>

                <div className="form-group form_left">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={formData.email}
                    onChange={inputEvent}
                    placeholder="Email"
                    required="required"
                    autoComplete="off"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    value={formData.phone}
                    onChange={inputEvent}
                    maxLength="10"
                    placeholder="Mobile No."
                    required="required"
                    autoComplete="off"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="hidden"
                    className="form-control"
                    name="course"
                    value={pdfData.course}
                    onChange={inputEvent}
                    placeholder="Course"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="hidden"
                    className="form-control"
                    name="link"
                    value={pdfData.link}
                    onChange={inputEvent}
                    placeholder="link"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="hidden"
                    className="form-control"
                    name="duration"
                    value={pdfData.duration}
                    onChange={inputEvent}
                    placeholder="Course"
                  />
                </div> 
              
            </div>
                <div className="modal-footer">
                <button className="btn btn-default btn-send">
                <i className="fa fa-paper-plane" aria-hidden="true"></i>&#160;
                Save
                </button>
              </div>
              </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default DownloadTablePdfCard;
