import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import { Helmet } from 'react-helmet';
export class OnlineClassroomTraining extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>MECCI Engineering Design & Power Training Institute</title>
          <meta name="description" content="MECCI Engineering Design & Power Training Institute" />
        </Helmet>
        <Helmet>
         <link rel="canonical" href="https://www.mecciengineer.com/online-classroom-training" />
        </Helmet>

        <div className="container-fluid">
          <PageTitle
            page_header_title="MECCI Engineering Design & Power Training Institute"
            imgsrc="assets/images/course/course-title-img/online-classroom-training.jpg"
            alt="OnlineClassroomTraining"
            title="MECCI Engineering Design & Power Training Institute"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div class="colright">
                <h3>
                  <b>We are offers short-term online/ classroom training to help
                  professionals to upskill and upgrade.</b>
                </h3>
                <p>
                  Mecci Engineering is offering Online professional engineering
                  training utilizing its extensive Technology Expertise and
                  Training Distribution Methodology for the benefit of the
                  student community in transforming their dreams from a
                  wandering generality to concrete authenticity. Our sole maxim
                  in introducing Online professional engineering training
                  sessions to cater the training requisites of the Software
                  Aspirants who cannot attend Classroom Training conducted at
                  our Office Locations in Noida (Delhi NCR) due to sundry
                  reasons.
                </p>
                <p>
                  The Online learning industry is expeditiously evolving in the
                  direction of even more puissant implements to put more
                  preponderant flexibility into the hands of instructors and
                  trainers. Utilize these implements to your advantage, and
                  while you may not get a masterpiece on your first endeavor,
                  you'll be well peregrinating.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OnlineClassroomTraining;
