import { useState, useEffect } from "react";
import axios from "axios";

export default function SidebarUpcommingBatch() {
  const [upCommingBatch, setUpcommingBatch] = useState([]);

  useEffect(() => {
    getAllBatch();
  }, []);

  async function getAllBatch() {
    try {
      const res = await axios.get(
        "https://www.mecciengineer.com/mecci/public/api/upcomming-batch"
      );
      //console.log(res.data.batchs);
      setUpcommingBatch(res.data.batchs);
    } catch (error) {
      console.log("something wrong");
      //console.log(error.message);
    }
  }

  return (
    <>
      <div className="collen">
        <div align="center">
          <div id="contact_form_div" style={{ fontSmarginRightize: "6px" }}>
            <p id="contact_label">
              <i className="fa fa-calendar" aria-hidden="true"></i> Upcoming
              Batches
            </p>
            <div className="panel panel-danger">
              <div className="panel-body">
                <marquee
                  truespeed="truespeed"
                  scrollamount="2"
                  direction="up"
                  loop={true}
                  height="300px"
                >
                  <ul className="media-list">
                    {upCommingBatch.map((couritm) => (
                      <li className="media" key={couritm.id}>
                        <div className="media-left">
                          <div className="panel panel-danger text-center date">
                            <div className="panel-heading month">
                              <span className="panel-title strong">
                                {couritm.month}
                              </span>
                            </div>
                            <div className="panel-body day text-danger">
                              {couritm.ddate}
                            </div>
                          </div>
                        </div>
                        <div className="media-body">
                          <h4 className="media-heading">Batch Starting</h4>
                          <p>{couritm.course_title}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </marquee>
                {/* <a href="#" className="btn btn-default btn-block">More Events »</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
