import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";

export class IlluminationSystemsDesigningCourse extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
          Illumination Designing Training, Course Online in India"
          </title>
          <meta
            name="description"
            content=" Top-rated illumination design Engineering Training.  We offer illumination Design course in India, Sudan, Nigeria, Egypt, South Sudan, South Africa, Oman, Qatar, Dubai, Kuwait, UAE."
          />
          <meta
            name="keywords"
            content=" MECCI Engineers provides the best online & offline Illumentation Design Engineering Training Courses in India, Sudan, Nigeria,   Oman, Qatar, UAE & more countries."
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/illumination-systems-designing-course"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Illumination Systems Designing Course"
            imgsrc="assets/images/course/course-title-img/illumination-systems-designing-course.jpg"
            alt="illumination-systems-designing-course"
            title="Illumination Systems Designing Course"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  The design of illumination systems is as much art as it is
                  science. Illumination engineering, or design, is a
                  challenging, and very rewarding field that transforms the
                  output of a simple bulb (or LED) into a pattern of
                  illumination that allows us to work, drive or play safely. As
                  engineering evolves into design, the designer combines
                  fundamental physics with architectural aesthetics and human
                  physiology to develop lighting solutions to satisfy all of our
                  ever changing needs.
                </p>
                <p>
                  Illumination design is a combination of art and science,
                  encompassing engineering, design, the laws of physics as well
                  as human physiology and psychology. Good lighting not only
                  needs to perform the lighting task adequately, but it also
                  must fit completely into the design environment and be
                  â€œaesthetically pleasingâ€, whether in use or not.
                </p>

                <hr />
                <Table
                  caption="Offered Courses in Illumination Systems Designing course"
                  disctitle="Course Related to Illumination Systems Designing course"
                  disc1="Illumination Systems Designing course (Full Time)"
                  pdf1="assets/images/course/courses-pdf/illumination-systems-designing.pdf"
                  duration1="45 Days"
                  disc2="Illumination Systems Designing course (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/illumination-systems-designing.pdf"
                  duration2="3 Months"
                  disc3="Illumination Systems Designing course (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/illumination-systems-designing.pdf"
                  duration3="8 Days"
                  disc4="Illumination Systems Designing course (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/illumination-systems-designing.pdf"
                  duration4="1 Year"
                />
                <hr />
                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul>
                  <li>
                    <i className="fa-duotone fa-period"></i> Radiant energy and
                    visible spectrum, energy conversion to light, colour, eye
                    and vision
                  </li>
                  <li>Different entities of illuminating systems</li>
                  <li>
                    Light sources: daylight, incandescent, electric discharge,
                    fluorescent, arc lamps and lasers
                  </li>
                  <li>Luminaries, wiring, switching & control circuits</li>
                  <li>
                    Laws of illumination; illumination from point, line and
                    surface sources
                  </li>
                  <li>Photometry and spectrophotometry; photocells</li>
                  <li>General illumination design</li>
                  <li>
                    Laws of illumination; illumination from point, line and
                    surface sources
                  </li>
                  <li>Photometry and spectrophotometry</li>
                  <li>
                    General illumination design, Illumination levels, loss
                    factors, lamp selection and maintenance
                  </li>
                  <li>
                    Interior lighting â€“ industrial, residential, office
                    departmental stores, indoor stadium, theater and hospitals
                  </li>
                  <li>
                    Selection of cable/wire sizes; potential sources of fire
                    hazards and precautions
                  </li>
                  <li>
                    Different type of loads and their individual protections
                  </li>
                  <li>A specific design problem on this aspect</li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>
                    Help employees clearly understand what training programs can
                    speed up their career progress in their desired direction.
                  </li>
                  <li>
                    Providing all Training Material and technical Drawing,
                    documents and case study materials.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default IlluminationSystemsDesigningCourse;
