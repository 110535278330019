import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";
export class PipingDesignEngineering extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
          Piping Design Engineering Training, Course Online in India
          </title>
          <meta
            name="description"
            content=" MECCI Engineers provides the best online & offline Piping Design Engineering Training Courses in India, Sudan, Nigeria,   Oman, Qatar, UAE & more countries."/>
          <meta
            name="keywords"
            content="Piping Design Engineering Course, Piping Design Training Institute, Piping Design Course in Noida, Piping Design Training India, Piping Design Engineering Training"
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/piping-design-engineering"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Piping Design Engineering Course"
            imgsrc="assets/images/course/course-title-img/piping-design-engineering-course.jpg"
            alt="Piping-Design-Engineering-Course"
            title="Piping Design Engineering Course"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  Piping Engineering is an application based on Mechanical
                  Engineering principles, which deals with Design, Analysis,
                  Installation, Testing, Commissioning and Maintenance of Piping
                  System. Industrially, all piping activities are performed with
                  the compliance and guidelines of International and Industrial
                  Codes & Standards as well as the laws and regulations of
                  respective local authority. Detail engineering in piping
                  projects consists of the engineering, design, detail and
                  layout of process and utility equipment, piping and
                  instrumentation.
                </p>
                <p>
                  Piping System is a network of Pipes by using Pipe Fittings and
                  other special components to perform the required mode of
                  transferring fluids (Liquids/ Gas/ Slurry) from one location
                  to another location. It is the effective method for
                  transferring fluids without considerable or about zero losses
                  in properties and quality of fluid.
                </p>
                <p>
                  Pipes and its associate equipments are account for 25% of
                  total investments in the Oil& Gas, Chemical, , Power Plants,
                  LPG/CNG Plant, Water Treatment Plants, Pharmaceutical,
                  Distribution System, and Petrochemical plants.
                </p>

                <hr />
                <Table
                  caption="Offered Courses in Piping Design Engineering"
                  disctitle="Course Related to Piping Detail Design"
                  disc1="Piping Design Engineering (Full Time)"
                  pdf1="assets/images/course/courses-pdf/piping-design-and-detailed-engineering.pdf"
                  duration1="45 Days"
                  disc2="Piping Design Engineering (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/piping-design-and-detailed-engineering.pdf"
                  duration2="3 Months"
                  disc3="Piping Design Engineering (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/piping-design-and-detailed-engineering.pdf"
                  duration3="8 Days"
                  disc4="Piping Design Engineering (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/piping-design-and-detailed-engineering.pdf"
                  duration4="1 Year"
                />

                <hr />
                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul>
                  <li>Introduction to Industry.</li>
                  <li>
                    Role of a Piping Engineer in various fields of industry.
                  </li>
                  <li>Overview of International codes and standards.</li>
                  <li>
                    Fundamentals of Pipe, Pipe Fittings and Piping System
                    Components.
                  </li>
                  <li>Overview of Pipe Hangers and Supports.</li>
                  <li>Pipe Hydraulics & Line Sizing.</li>
                  <li>Overview of Equipments Used in Process Plants.</li>
                  <li>
                    Overview of Codes & Standards, Piping Materials &
                    Specifications.
                  </li>
                  <li>Basics of PFD, P&ID and Plot plan development.</li>
                  <li>Development of Equipments & Piping layouts.</li>
                  <li>Guidelines to preparation of as built drawings.</li>
                  <li>
                    Preparation of Isometric drawings and Bill of Materials.
                  </li>
                  <li>
                    Piping System Studies: Drum Piping, Pump Piping, Compressor
                    Piping, Heat Exchanger Piping etc.
                  </li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>EPC Project Based Training.</li>
                  <li>
                    Providing all Training Material and technical Drawing,
                    documents and case study materials.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PipingDesignEngineering;
