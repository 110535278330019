const GallaryData = [
    {   id    :1,
        imgsrc:"assets/images/gallary/gallary.jpeg",
    },
    {   id    :2,
        imgsrc:"assets/images/gallary/gallary1.jpeg",
    },
    {   id    :3,
        imgsrc:"assets/images/gallary/gallary2.jpeg",
    },
    {   id    :4,
        imgsrc:"assets/images/gallary/gallary3.jpeg",
    },
   
    {   id    :7,
        imgsrc:"assets/images/gallary/gallary6.jpeg",
    },
    {   id    :8,
        imgsrc:"assets/images/gallary/gallary7.jpeg",
    },
    {   id    :9,
        imgsrc:"assets/images/gallary/gallary8.jpeg",
    },
    {   id    :10,
        imgsrc:"assets/images/gallary/gallary9.jpeg",
    },
    {   id    :11,
        imgsrc:"assets/images/gallary/gallary10.jpeg",
    },
    {   id    :12,
        imgsrc:"assets/images/gallary/gallary11.jpeg",
    },
    {   id    :13,
        imgsrc:"assets/images/gallary/gallary12.jpeg",
    },
    {   id    :14,
        imgsrc:"assets/images/gallary/gallary13.jpeg",
    },
    {   id    :15,
        imgsrc:"assets/images/gallary/gallary14.jpeg",
    },
    {   id    :16,
        imgsrc:"assets/images/gallary/gallary15.jpeg",
    },
    {   id    :17,
        imgsrc:"assets/images/gallary/gallary16.jpeg",
    },
    {   id    :18,
        imgsrc:"assets/images/gallary/gallary17.jpeg",
    },
    {   id    :19,
        imgsrc:"assets/images/gallary/gallary18.jpeg",
    },
    {   id    :20,
        imgsrc:"assets/images/gallary/gallary19.jpeg",
    },
    {   id    :5,
        imgsrc:"assets/images/gallary/gallary4.jpeg",
    },
    {   id    :6,
        imgsrc:"assets/images/gallary/gallary5.jpeg",
    },
];
export default GallaryData;