const mecciService = [
    {   
        id    :2,
        title :"Why Mecci",
        link  :"why-mecci",
    },
	{   
        id    :2,
        title :"Engineering Services",
        link  :"engineering-services",
    },
	{   
        id    :3,
        title :"Online | Classroom Training",
        link  :"online-classroom-training",
    },
	{   
        id    :4,
        title :"Manpower Services",
        link  :"manpower-services",
    },
	{   
        id    :5,
        title :"Blogs",
        link  :"blogs",
    },
	{   
        id    :6,
        title :"Gallary",
        link  :"gallary",
    },
];
	/*EPC - Training*/
    const epcTraining = [
	{   
        id    :1,
        title :"Electrical Design Engineering",
        link  :"electrical-design-engineering",
    },
	{   
        id    :2,
        title :"Piping Design Engineering",
        link  :"piping-design-engineering",
    },
	{   
        id    :3,
        title :"Process Design Engineering",
        link  :"process-design-engineering",
    },
	{   
        id    :4,
        title :"Instrumentation Design Engineering",
        link  :"instrumentation-design-engineering",
    },
	{   
        id    :5,
        title :"HVAC Design Engineering",
        link  :"hvac-design-engineering",
    },
	{   
        id    :6,
        title :"Civil & Structural Design Engineering",
        link  :"civil-and-structural-design-engineering",
    },
	{   
        id    :7,
        title :"Billing Engineering Training",
        link  :"billing-engineering-training",
    },
	{   
        id    :8,
        title :"Planning Engineering Training",
        link  :"planning-engineering-training",
    },
	{   
        id    :9,
        title :"Solar Power Plant Design Engineering",
        link  :"solar-power-plant-design-engineering",
    },
	{   
        id    :10,
        title :"Substation Design Course",
        link  :"substation-design-course"
    },
	{   
        id    :11,
        title :"Solar Structure Design Course",
        link  :"solar-structure-design-course",
    },{ 
        id    :12,  
        title :"Hybrid & Electric Vehicle Design Course",
        link  :"hybrid-electric-vehicle-design-course",
    },
	{   
        id    :13,
        title :"Illumination Systems Designing Course",
        link  :"illumination-systems-designing-course",
    },
];
	/*Corporate &nbsp;Services*/
    const corporateServices = [
	{   
        id    :1,
        title :"Project Management and Control",
        link  :"project-management-control",
    },
	{   
        id    :2,
        title :"Solar Power Plant Design Engineering",
        link  :"solar-power-plant-design-engineering",
    },
	{   
        id    :3,
        title :"Planning Package",
        link  :"planning-package",
    },
	{   
        id    :4,
        title :"Instrumentation Design Engineering",
        link  :"instrumentation-design-engineering",
    },
	{   
        id    :5,
        title :"Process Design Workshop",
        link  :"process-design-workshop",
    },
	{   
        id    :6,
        title :"45 Days Industrial Training",
        link  :"45-days-industrial-training",
    },
];
	/*IT Training*/
    const itTraining = [
	{   
        id    :1,
        title :"SEO Training",
        link  :"seo-traning",
    },
	{   
        id    :2,
        title :"Web Designing Training",
        link  :"web-designing-training",
    },
	{   
        id    :3,
        title :"Core Java Training",
        link  :"core-java-training",
    },
	{   
        id    :4,
        title :"Angular JS Training",
        link  :"angular-js-training",
    },
	{   
        id    :5,
        title :"Dot NET Training",
        link  :"dot-net-training",
    },
	{   
        id    :6,
        title :"Software Manual Testing",
        link  :"software-manual-testing",
    },
	{   
        id    :7,
        title :"Software Automation Testing",
        link  :"software-automation-testing",
    },
	{   
        id    :8,
        title :"Performance Testing",
        link  :"performance-testing",
    },
];
	/*NON-IT Training*/
    const nonItTraining = [
	{   
        id    :1,
        title :"Accounts, GST and Taxation Training",
        link  :"accounts-gst-and-taxation-training",
    },
	{   
        id    :2,
        title :"Pay-Roll Compliance Training",
        link  :"pay-roll-compliance-training",
    },
	{   
        id    :3,
        title :"Advanced Excel for Accounting Training",
        link  :"advanced-excel-for-accounting-training",
    },
	{   
        id    :4,
        title :"Advanced Tally Training",
        link  :"advanced-tally-training",
    },
	{   
        id    :5,
        title :"HR Generalist Course",
        link  :"hr-generalist-course",
    },
	{   
        id    :6,
        title :"ERP SAP HR Training",
        link  :"erp-sap-hr-training",
    },
	{   
        id    :7,
        title :"ERP SAP MM Training",
        link  :"erp-sap-mm-training",
    },
	{   
        id    :8,
        title :"ERP SAP PM Training",
        link  :"erp-sap-pm-training",
    },{ 
        id    :9,  
        title :"ERP SAP PP Training",
        link  :"erp-sap-pp-training",
    },{ 
        id    :10,  
        title :"ERP SAP PS Training",
        link  :"erp-sap-ps-training",
    },{ 
        id    :11,  
        title :"ERP SAP QM Training",
        link  :"erp-sap-qm-training",
    },{ 
        id    :12,  
        title :"ERP SAP HANA Training",
        link  :"erp-sap-hana-training",
    },
	{   
        id    :13,
        title :"SAP FICO Training",
        link  :"sap-fico-training",
    },
	{   
        id    :14,
        title :"SAP Simple Finance Training",
        link  :"sap-simple-finance-training",
    },{ 
        id    :15,  
        title :"SAP HR-HCM Training",
        link  :"sap-hr-hcm-training",
    }
	];
    export default mecciService;
    export  {epcTraining, corporateServices,itTraining,nonItTraining} ;
    //{mecciService, epcTraining, corporateServices,itTraining,nonItTraining}