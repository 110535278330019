import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import { Helmet } from "react-helmet";

export class DesignDetailedEngineeringTraining extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Mecciengineers-Top most design and detailed Engineering Courses
            Online by Experts
          </title>
          <meta
            name="description"
            content="Top-rated design and detailed Engineering Training.  We offer design and detailed design course in India, Sudan, Nigeria, Egypt, South Sudan, South Africa, Oman, Qatar, Dubai, Kuwait, UAE."
          />
          <meta
            name="keywords"
            content="design and detailed Engineering Training Institute, design and detailed design Certificate Course training, design and detailed engineering Course, design and detailed Engineering Course"
          />
        </Helmet>
        <Helmet>
  <link rel="canonical" href="https://www.mecciengineer.com/design-and-detailed-engineering-training" />
</Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="About Workshop"
            imgsrc="assets/images/corporate-services.jpg"
            alt="About Workshop"
            title="About Workshop"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  MEPL with support of MEPL Engineering has been providing a
                  World Class Leading Technical Training and workshop, at your
                  doorstep in different engineering disciplines to meet the
                  requirements of skilled manpower in the field of EPC, EPCM
                  "OIL & GAS", Petrochemical & various process/manufacturing
                  plants, with adequate industrial exposure. The training
                  programs are developed by industrial experts keeping in view
                  with the industrial challenges and needs. The employees /
                  students are tailor made machinery in industries to carry out
                  fixed pattern of job which add discontentment to their
                  existing workload. Our career oriented industrial training and
                  workshop program imparts them industrial practices being
                  followed in industrial arena which motivate our students /
                  employees to explore their capability and creativity to add
                  innovation to their work in turn it benefits the industrial
                  society. Also with added skills they have better opportunity
                  in organization and further they get an edge to switchover to
                  better firm with competence and added skills.
                </p>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12 zoom1">
                  <div className="thumbnail">
                    <img
                      src="assets/images/clip_image004.jpg"
                      alt="About Workshop"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DesignDetailedEngineeringTraining;
