import React, { Component } from 'react'
import GallaryData from "../pages/GallaryData";
export class SidebarGallery extends Component {
    render() {
        return (
            <>
<div className="colleft">
<h3>MECCI Gallery</h3>
	<div className="placedStudent text-center">
		<div className="placedStudentImg traingCenter">
			<div id="myCarousel" className="carousel slide" data-ride="carousel" data-interval="3050">
				<div className="carousel-inner" role="listbox">
				{GallaryData.map((gallary) => (
					
					<div className={"item " + ((gallary.id ==1) ? "active": "")} key={gallary.id}>
					  <img src={gallary.imgsrc} alt="best Best Training Institute for IT training in noida" />
					</div>
				))}
				
				</div>
			</div>
		</div>
	</div>
</div>  
            </>
        )
    }
}

export default SidebarGallery
