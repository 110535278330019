import React, { Component } from "react";
import { Link } from "react-router-dom";
import BlogsData from "../pages/BlogsData";
console.warn(BlogsData);
class Coursecrousel extends React.Component {
  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div
              className="MultiCarousel"
              data-items="1,3,3,4"
              data-slide="2"
              id="MultiCarousel"
              data-interval="10"
            >
              <div className="MultiCarousel-inner">
                
                {BlogsData.map((course)=>(
                  <div className="item " key={course.id}>
        <div className="pad15" key={course.id}>
          <div className="smallCol card zoom one" key={course.id}>
            <h3>{course.title}</h3>
            <img
              src={course.imgsrc}
              className="img-responsive"
              alt={course.alt}
            />
            <p>
              {course.desc}
              <Link to={course.link} className="text-uppercase">
                <strong> More</strong>
              </Link>
            </p>
          </div>
        </div>
      </div>

                ))}
                

                
              </div>
              <button className="btn btn-primary leftLst">&#60;</button>
              <button className="btn btn-primary rightLst">&#62;</button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Coursecrousel;
