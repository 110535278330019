import { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import PlacementCard from "../components/PlacementCard";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
function Placement() {
  const [placements, setPlacement] = useState([]);

  useEffect(() => {
    getAllPlacement();
  }, []);

  async function getAllPlacement() {
    try {
      const res = await axios.get(
        "https://www.mecciengineer.com/mecci/public/api/allplacement"
      );
      //console.log(res.data.placements);
      setPlacement(res.data.placements);
    } catch (error) {
      console.log("something wrong");
      console.log(error.message);
    }
  }
  let url = "https://www.mecciengineer.com/mecci/public/imgpath1/";
  return (
    <>
      <Helmet>
        <title>Placement</title>
        <meta name="description" content="Placement" />
      </Helmet>
      <Helmet>
        <link rel="canonical" href="https://www.mecciengineer.com/placement" />
      </Helmet>
      <div className="container-fluid">
        <PageTitle
          imgsrc="assets/images/placeholder640x360.png"
          alt="placement"
          title="Placement"
        />
        <div className="row">
          <div className="col-md-8 col-md-8a">
            <div className="row">
              {placements.map((couritm1) => (
                <PlacementCard
                  title={couritm1.name}
                  imgsrc={url + couritm1.image}
                  alt={couritm1.name}
                  company={couritm1.company}
                />
              ))}
            </div>
          </div>
          <div className="col-md-4 col-md-4a">
            <Sidebar />
          </div>
        </div>
      </div>
    </>
  );
}

export default Placement;
