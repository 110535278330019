import { useState, useEffect } from "react";
import axios from "axios";
function Testimonials () {
  const [review, setReview] = useState([]);

  useEffect(() => {
    getAllReviews();
  }, []);

  async function getAllReviews() {
    try {
      const res = await axios.get("https://www.mecciengineer.com/mecci/public/api/review");

      setReview(res.data.reviews);
    } catch (error) {
      console.log(error.message);
    }
  }
    return (
      <>
        <div className="rwstimonial">
          <h2 className="textblue">
            Students <span className="text-gray">Reviews</span>
          </h2>
          <div
            id="myCarousel "
            className="carousel slide"
            data-ride="carousel"
            data-interval="5000"
          >
            <div className="carousel-inner">

            {review.map((couritm1) => (
              <>
              <div className={"item carousel-item " + ((couritm1.id ==1) ? "active": "")} key={couritm1.id}>
                <div className="img-box">
                  <img
                    src="assets/images/placeholder_logo.jpeg"
                    alt="testimonial"
                  />
                </div>
                <p className="overview">
                  <b>{couritm1.name}</b>
                </p>
                <div className="star-rating">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <i className="fa fa-star"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-star"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-star"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-star"></i>
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-star-o"></i>
                    </li>
                  </ul>
                </div>
                <p className="testimonial">
                {couritm1.review}
                </p>
              </div>
              </>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }

export default Testimonials;
