import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";

export class DotNETTraining extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Top most Software training institute in India.</title>
          <meta
            name="classification"
            content="Dot net Training Institute presents various customized courses for working persons and fresher engineers. We offer Civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course in India, Sudan, Nigeria, Egypt, Bangladesh, South Sudan &amp; South Africa, Oman, qatar, Dubai, kuwait, UAE."
          />
          <meta
            name="keywords"
            content="Dot net training, Civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course"
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/dot-net-training"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Best Dot NET Training and Certification Course"
            imgsrc="assets/images/course/course-title-img/dot-net-training.jpg"
            alt="Best Dot NET Training and Certification Course"
            title="Best Dot NET Training and Certification Course"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  Learn Dot net Online Training Course from Industry Experts
                  with Certification. It provides perfect platform to develop
                  windows and web application with flexibility that these
                  programs can be executed with any operating system. The Java
                  programming language is a modern, evolutionary computing
                  language that combines an elegant language design with
                  powerful features that were previously available primarily in
                  specialty languages. We have classNameified our Java Training
                  Course into three levels of programming, as a basic concept:
                  specialized Java Programming, Core Java Programming and
                  Advanced form of Java Programming.
                </p>
                <p>
                  The trainers maintain an innovative and creative approach, and
                  even the complicated topics are easy to grasp by the students.
                  We deliver in multithreaded environment preferably with
                  Development Skills in Core Java. Customerâ€™s satisfaction is
                  our core competencies. We know that with the right individual
                  in board anything you can so we think in creating the right
                  person.
                </p>
                <hr />
                <Table
                  caption="Offered Courses in Dot NET Training"
                  disctitle="Course Related to Dot NET Training"
                  disc1="Dot NET Training (Full Time)"
                  pdf1="assets/images/course/courses-pdf/dot-net-training.pdf"
                  duration1="45 Days"
                  disc2="Dot NET Training (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/dot-net-training.pdf"
                  duration2="3 Months"
                  disc3="Dot NET Training (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/dot-net-training.pdf"
                  duration3="8 Days"
                  disc4="Dot NET Training (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/dot-net-training.pdf"
                  duration4="6 Months"
                />
                <hr />

                <h3>Note: All above coures avaliable in ONLINE</h3>

                <h3>What You Will Learn</h3>
                <ul>
                  <li>Introduction of Dot NET</li>
                  <li>Data types and Variables</li>
                  <li>Operators and Expressions</li>
                  <li>Control Flow Statements</li>
                  <li>Methods</li>
                  <li>Object-Oriented Programming</li>
                  <li>Objects and Classes</li>
                  <li>Inheritance in Java</li>
                  <li>Packages & Exception Handling</li>
                  <li>Input/OutputStreams</li>
                  <li>Collection Framework & Generics Classes</li>
                  <li>Inner Classes</li>
                  <li>Introduction to Threads</li>
                  <li>Interfaces and Abstract Classes</li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>
                    Help employees clearly understand what training programs can
                    speed up their career progress in their desired direction.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DotNETTraining;
