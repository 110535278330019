import React from "react";
function PlacementCard(params) {
  return (
    <>
      <div className="col-md-4 clo-xs-12">
        <div className="item">
          <div className="pad15" style={{marginTop:'13px'}}>
            <div className="smallCol card zoom">
              <h3 className="card-title">{params.title}</h3>
              <img
                src={params.imgsrc}
                className="img-responsive"
                alt={params.alt}
              />
              <p>
                <strong>{params.company}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlacementCard;
