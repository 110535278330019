import React, { Component } from "react";
import { Link } from "react-router-dom";
import mecciService, {
  epcTraining
} from "../pages/CourseDataLink";
export class SidebarEpcTrainingHome extends Component {
  render() {
    return (
      <>
        <div
          className="colleft accordion"
          data-toggle="collapse"
          data-target="#demo"
        >
          <h3>EPC TRAINING </h3>

          <div id="demo" className="collapse in">
            <ul className="listunstyled">
              <li>
                <Link to={epcTraining[0].link} target="_blank">
                  {epcTraining[0].title}
                </Link>
              </li>
              <li>
                <Link to={epcTraining[1].link} target="_blank">
                  {epcTraining[1].title}{" "}
                </Link>
              </li>
              <li>
                <Link to={epcTraining[2].link} target="_blank">
                  {epcTraining[2].title}
                </Link>
              </li>
              <li>
                <Link to={epcTraining[3].link} target="_blank">
                  {epcTraining[3].title}{" "}
                </Link>
              </li>
              <li>
                <Link to={epcTraining[4].link} target="_blank">
                  {epcTraining[4].title}{" "}
                </Link>
              </li>
              <li>
                <Link to={epcTraining[5].link} target="_blank">
                  {epcTraining[5].title}
                </Link>
              </li>
              <li>
                <Link to={epcTraining[6].link} target="_blank">
                  {epcTraining[6].title}
                </Link>
              </li>
              <li>
                <Link to={epcTraining[7].link} target="_blank">
                  {epcTraining[7].title}{" "}
                </Link>
              </li>
              <li>
                <Link to={epcTraining[8].link} target="_blank">
                  {epcTraining[8].title}
                </Link>
              </li>
              <li>
                <Link to={epcTraining[9].link} target="_blank">
                  {epcTraining[9].title}{" "}
                </Link>
              </li>
              <li>
                <Link to={epcTraining[10].link} target="_blank">
                  {epcTraining[10].title}{" "}
                </Link>
              </li>
              <li>
                <Link to={epcTraining[11].link} target="_blank">
                  {epcTraining[11].title}
                </Link>
              </li>
              <li>
                <Link to={epcTraining[12].link} target="_blank">
                  {epcTraining[12].title}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default SidebarEpcTrainingHome;
