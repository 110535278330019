import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";

export class ElectricalDesigEngineering extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
          Electrican Design Engineering Training, Course Online in India
          </title>
          <meta
            name="description"
            content="MECCI Engineers provides the best online & offline Electrical Design Engineering Training Courses in India, Sudan, Nigeria,   Oman, Qatar, UAE & more countries."
          />
          <meta
            name="keywords"
            content="Electrical Design Engineering Training Institute, Electrical Design Certificate Course training, Electrical Design Course, Electrical Design Engineering Course"
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/electrical-design-engineering"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Electrical Design Engineering Course"
            imgsrc="assets/images/corporate-services.jpg"
            alt="electrical-desig-engineering"
            title="Electrical Design Engineering Course"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  Electrical Design entails Planning, Creating, Testing or
                  Supervising the development and installation of electrical
                  equipment, including lighting design, power systems, power
                  distribution, fire and life safety systems, electronic
                  components and voice and data communications infrastructure.
                  Many Electrical Design projects start with an idea that
                  emanates from the marketing and sales department or from a
                  research and development division within a company. Electrical
                  Design products might represent a new marketable product or
                  might seek to improve an existing product or system.
                </p>
                <p>
                  There is huge demand of Electrical Design Engineers in many
                  segments like Design, Manufacture and installation of power
                  and distribution systems.
                </p>
                <p>
                  Sub-stations Design, Major Electrical Equipment Selection
                  sizing calculation and installations, Cable installation
                  design and Hazardous area, SLD and procurement data are some
                  of the skills required by every EPC company now a days.
                </p>

                <h3>Offered Courses in Electrical Design Engineering</h3>
                <hr />
                <Table
                  caption="Offered Courses in Electrical Design Engineering"
                  disctitle="Course Related to Electrical Detail Design"
                  disc1="Electrical Design Engineering ( Full Time)"
                  pdf1="assets/images/course/courses-pdf/electrical-design-and-detailed-engineering.pdf"
                  duration1="45 Days"
                  disc2="Electrical Design Engineering (Only Sunday) "
                  pdf2="assets/images/course/courses-pdf/electrical-design-and-detailed-engineering.pdf"
                  duration2="3 Months"
                  disc3="Electrical Design Engineering (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/electrical-design-and-detailed-engineering.pdf"
                  duration3="8 Days"
                  disc4="Electrical Design Engineering (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/electrical-design-and-detailed-engineering.pdf"
                  duration4="1 Year"
                />
                <hr />

                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul>
                  <li>
                    Overview of EPC industry and requirement of electrical
                    systems Design.
                  </li>
                  <li>Role of Electrical Design Engineer in Industry.</li>
                  <li>
                    Knowing Client requirements & collection of specific data
                    for projects.
                  </li>
                  <li>Understanding various phases of projects.</li>
                  <li>
                    Use of International Standards, Design Basis, std.
                    practices/procedure and specifications
                  </li>
                  <li>Estimation of Plant Electrical Load.</li>
                  <li>
                    Development of Single Line Diagrams (SLD) and Control
                    Schematics.
                  </li>
                  <li>
                    Selection,sizing, protection, specification,data-sheets of
                    Electrical Equipments.
                  </li>
                  <li>Cable Selection and Sizing and Routing.</li>
                  <li>Illumination Design.</li>
                  <li>Earthing & Lightening Protection Design.</li>
                  <li>Hazardous Area Classification.</li>
                  <li>Substation/switchyard design.</li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>EPC Project Based Training.</li>
                  <li>
                    Providing all Training Material and technical Drawing,
                    documents and case study materials
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ElectricalDesigEngineering;
