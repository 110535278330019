import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";
export class SoftwareManualTesting extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Top most Software manual testing institute in India.</title>
          <meta
            name="classification"
            content="Software Training manual Institute presents various customized courses for working persons and fresher engineers. We offer Civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course in India, Sudan, Nigeria, Egypt, Bangladesh, South Sudan and South Africa, Oman, Qatar, Dubai, Kuwait, UAE."
          />
          <meta
            name="keywords"
            content="Software manual training institute in India, civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course"
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/software-manual-testing"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Manual Testing Training and Certification"
            imgsrc="assets/images/course/course-title-img/manual-testing-training.jpg"
            alt="Manual Testing Training and Certification"
            title="Manual Testing Training and Certification"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  Manual testing helps discover and record any software bugs or
                  discrepencies related to the functionality of the product.
                  Testing is a critical phase of Software Development Life
                  Cycle. Manual testing is the process in which the defects are
                  identified, isolated, subjected for justification and ensure
                  that the product is defect-free, in-order to produce quality
                  product.
                </p>
                <p>
                  Manual testing is performed by the tester who carries out all
                  of the actions on the tested application manually,
                  step-by-step and indicates whether a particular step was
                  accomplished successfully or whether it failed. Our syllabus
                  matches the real world requirements for both beginner level to
                  advanced level. Our training will be handled in either weekday
                  or weekends programme depends on participants requirement.
                </p>
                <hr />
                <Table
                  caption="Offered Courses in Manual Testing Training and Certification"
                  disctitle="Course Related to Manual Testing Training course	"
                  disc1="Manual Testing Training course (Full Time)"
                  pdf1="assets/images/PDFIMG.jpg"
                  duration1="45 Days"
                  disc2="Manual Testing Training course (Only Sunday)"
                  pdf2="assets/images/PDFIMG.jpg"
                  duration2="3 Months"
                  disc3="Manual Testing Training course (Fastrack Batch)"
                  pdf3="assets/images/PDFIMG.jpg"
                  duration3="8 Days"
                  disc4="Manual Testing Training course (Correspondence)"
                  pdf4="assets/images/PDFIMG.jpg"
                  duration4="6 Months"
                />

                <hr />

                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul>
                  <li>Introduction to Software Testing</li>
                  <li>Testing Introduction and Terminology</li>
                  <li>Software Development Life Cycle</li>
                  <li>Software Testing Life Cycle</li>
                  <li>Bug Life Cycle</li>
                  <li>Waterfall Model in SDLC</li>
                  <li>Spiral Model in SDLC</li>
                  <li>V Model in SDLC</li>
                  <li>Types of Software Testing</li>
                  <li>Smoke And Sanity Testing</li>
                  <li>Black box & White boxtesting</li>
                  <li>Integration Testing</li>
                  <li>
                    Difference between Functional and Non-functional testing
                  </li>
                  <li>
                    System Integration testing vs. User Acceptance Testing
                  </li>
                  <li>Test Environment and Test data preparation</li>
                  <li>Requirement traceability matrix</li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>
                    Help employees clearly understand what training programs can
                    speed up their career progress in their desired direction.
                  </li>
                  <li>Software Testing Live Project.</li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SoftwareManualTesting;
