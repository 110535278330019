import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import { Helmet } from "react-helmet";

export class Epctraining extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Mecciengineers- Top most industrial training courses online by
            experts
          </title>
          <meta
            name="description"
            content="Top-rated industrial training course.  We offer industrial training course in India, Sudan, Nigeria, Egypt, South Sudan, South Africa, Oman, Qatar, Dubai, Kuwait, UAE."
          />
          <meta
            name="keywords"
            content="industrial Training Institute, industrial Design Certificate Course training, industrial design Course, industrial design Engineering Course"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Engineering Design & Power Training Institute"
            imgsrc="assets/images/corporate-services.jpg"
            alt="Engineering Design & Power Training Institute"
            title="Engineering Design & Power Training Institute"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <div className="row">
                  <div className="col-sm-12">
                    <h3> EPC Training Courses</h3>
                    <p>
                      {" "}
                      With our EPC training courses Noida, you don’t have to
                      settle on only one course but you can choose the one that
                      you wanted to master. You can pick a training course
                      depending on your interest and also to your preference.
                      Under our EPC training courses Noida, we have a variety of
                      courses that you can enroll to. The courses we have will
                      teach you the specific training you would want to know
                      more which can be your ticket to bright future or
                      successful property dealings.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-md-6 zoom">
                    <div className="thumbnail">
                      <img
                        src="assets/images/placeholder300x200.png"
                        alt="Sed convallis dignissim magna et dignissim. Praesent tincidunt sapien eu gravida dignissim. "
                      />
                      <div className="caption">
                        <h3>Electrical Design Engineering Course</h3>
                        <p>
                          Electrical Design entails Planning, Creating, Testing
                          or Supervising the development and installation of
                          electrical equipment, including lighting design, power
                          systems, power distribution, fire and life safety
                          systems, electronic components and voice and data
                          communications infrastructure. Many Electrical Design
                          projects start with an idea that emanates from the
                          marketing
                        </p>
                        <p>
                          <a href="elecricaldesign.php" className="redmore">
                            Read More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6 zoom">
                    <div className="thumbnail">
                      <img
                        src="assets/images/placeholder300x200.png"
                        alt="Sed convallis dignissim magna et dignissim. Praesent tincidunt sapien eu gravida dignissim. "
                      />
                      <div className="caption">
                        <h3>Piping Design Engineering Course</h3>
                        <p>
                          Piping Engineering is an application based on
                          Mechanical Engineering principles, which deals with
                          Design, Analysis, Installation, Testing, Commissioning
                          and Maintenance of Piping System. Industrially, all
                          piping activities are performed with the compliance
                          and guidelines of International and Industrial Codes
                        </p>
                        <p>
                          <a href="piping-design.php" className="redmore">
                            Read More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-md-6 zoom">
                    <div className="thumbnail">
                      <img
                        src="assets/images/corporate-services.jpg"
                        alt="Sed convallis dignissim magna et dignissim. Praesent tincidunt sapien eu gravida dignissim. "
                      />
                      <div className="caption">
                        <h3>Process Design Engineering Course</h3>
                        <p>
                          In chemical engineering, Process Design is the design
                          of processes for desired physical and/or chemical
                          transformation of materials. Process design is central
                          to chemical engineering, and it can be considered to
                          be the summit of that field, bringing together all of
                          the field's components. Process design is distinct
                          from equipment design, which is closer in spirit to
                          the design of unit operations.
                        </p>
                        <p>
                          <a href="process.php" className="redmore">
                            Read More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6 zoom">
                    <div className="thumbnail">
                      <img
                        src="assets/images/placeholder300x200.png"
                        alt="Sed convallis dignissim magna et dignissim. Praesent tincidunt sapien eu gravida dignissim. "
                      />
                      <div className="caption">
                        <h3>Instrumentation Design Engineering Course</h3>
                        <p>
                          Instrumentation term is commonly used in engineering,
                          which means measurement and control for industrial
                          process systems. Various processes in petrochemical,
                          Oil & Gas, Power industries need to be maintained at
                          controlled levels to get the desired product. It is
                          commonly done by controlling such process variables as
                          pressure,
                        </p>
                        <p>
                          <a href="instrumentation.php" className="redmore">
                            Read More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-md-6 zoom">
                    <div className="thumbnail">
                      <img
                        src="assets/images/placeholder300x200.png"
                        alt="Sed convallis dignissim magna et dignissim. Praesent tincidunt sapien eu gravida dignissim. "
                      />
                      <div className="caption">
                        <h3>HVAC Design Engineering Course</h3>
                        <p>
                          MECCI Engineers offering HVAC design engineering
                          Course. For engineering purposes the definition should
                          also be extended to include the lowest life-cycle cost
                          of conditioning the air by right-sizing of equipment
                          to meet the particular application with the lowest
                          operating and maintenance.
                        </p>
                        <p>
                          <a href="hvac-design.php" className="redmore">
                            Read More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6 zoom">
                    <div className="thumbnail">
                      <img
                        src="assets/images/placeholder300x200.png"
                        alt="Sed convallis dignissim magna et dignissim. Praesent tincidunt sapien eu gravida dignissim. "
                      />
                      <div className="caption">
                        <h3>Civil Design Engineering Course</h3>
                        <p>
                          MECCI Engineer offering Civil & Structural design
                          engineering Course. Civil & Structural engineering
                          deals with the study, analysis and design of different
                          structures involves with requirement in industrial
                          facilities. These structures are generally required
                          for construction related to power projects,
                          refineries,
                        </p>
                        <p>
                          <a href="civil.php" className="redmore">
                            Read More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-md-6 zoom">
                    <div className="thumbnail">
                      <img
                        src="assets/images/placeholder300x200.png"
                        alt="Sed convallis dignissim magna et dignissim. Praesent tincidunt sapien eu gravida dignissim. "
                      />
                      <div className="caption">
                        <h3>Billing Engineering Course</h3>
                        <p>
                          This training is a huge opportunity for civil
                          engineers who are beginners and working professionals
                          to get a job in the construction companies as a
                          Billing Engineer by doing professional training in the
                          Billing Engineering Course.
                        </p>
                        <p>
                          <a href="billing-engineering.php" className="redmore">
                            Read More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6 zoom">
                    <div className="thumbnail">
                      <img
                        src="assets/images/placeholder300x200.png"
                        alt="Sed convallis dignissim magna et dignissim. Praesent tincidunt sapien eu gravida dignissim. "
                      />
                      <div className="caption">
                        <h3>Best Project Planning Engineering Training</h3>
                        <p>
                          A project planning engineer works with a site manager
                          to develop suitable construction methods and sequences
                          for a project. This training course manage projects
                          from start to finish through a range of topics
                          including scope, schedule and resource planning,
                          project risk, execution and control plus more.
                        </p>
                        <p>
                          <a href="planning-engineer.php" className="redmore">
                            Read More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-md-6 zoom">
                    <div className="thumbnail">
                      <img
                        src="assets/images/placeholder300x200.png"
                        alt="Sed convallis dignissim magna et dignissim. Praesent tincidunt sapien eu gravida dignissim. "
                      />
                      <div className="caption">
                        <h3>Solar Power Plant Design Engineering Course</h3>
                        <p>
                          Solar Design entails Planning, dynamics of PV
                          deployment have been particularly remarkable, driven
                          mostly by feed-in tariffs. PV is extremely modular,
                          easy and fast to install and accessible to the general
                          public. With suitably established policies and mature
                          markets and finance, PV projects can have short lead
                          times.
                        </p>
                        <p>
                          <a href="solar.php" className="redmore">
                            Read More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6 zoom">
                    <div className="thumbnail">
                      <img
                        src="assets/images/placeholder300x200.png"
                        alt="Sed convallis dignissim magna et dignissim. Praesent tincidunt sapien eu gravida dignissim. "
                      />
                      <div className="caption">
                        <h3>Substation Design Course</h3>
                        <p>
                          High voltage substation design is a complex
                          engineering activity that embraces a wide variety of
                          disciplines. This comprehensive overview brings these
                          disciplines together to give you an interactive guide
                          to high voltage, air or gas insulated substation
                          design.
                        </p>
                        <p>
                          <a
                            href="substationdesigncourse.php"
                            className="redmore"
                          >
                            Read More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-md-6 zoom">
                    <div className="thumbnail">
                      <img
                        src="assets/images/placeholder300x200.png"
                        alt="Sed convallis dignissim magna et dignissim. Praesent tincidunt sapien eu gravida dignissim. "
                      />
                      <div className="caption">
                        <h3>Solar Structure Design Course</h3>
                        <p>
                          Solar structure design is your gateway to a career in
                          the solar industry. It all starts with the
                          fundamentals, and a solid understanding of various
                          components, system architectures, and applications for
                          PV systems. Other topics include site analysis, system
                          sizing, array configuration, and performance
                          estimation;
                        </p>
                        <p>
                          <a
                            href="solar-structure-design.php"
                            className="redmore"
                          >
                            Read More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6 zoom">
                    <div className="thumbnail">
                      <img
                        src="assets/images/placeholder300x200.png"
                        alt="Sed convallis dignissim magna et dignissim. Praesent tincidunt sapien eu gravida dignissim. "
                      />
                      <div className="caption">
                        <h3>Hybrid & Electric Vehicle Design Course</h3>
                        <p>
                          As electric motors become more ubiquitous in our
                          everyday lives, found in just about everything we use
                          from automobiles to kitchen appliances to
                          IOT-connected and smart devices, itâ€™s more important
                          than ever to understand the machine characteristics,
                          modern control techniques,
                        </p>
                        <p>
                          <a
                            href="hybrid-electric-vehicle.php"
                            className="redmore"
                          >
                            Read More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-6 zoom">
                    <div className="thumbnail">
                      <img
                        src="assets/images/placeholder300x200.png"
                        alt="Sed convallis dignissim magna et dignissim. Praesent tincidunt sapien eu gravida dignissim. "
                      />
                      <div className="caption">
                        <h3>Illumination Systems Designing Course</h3>
                        <p>
                          Illumination design is a combination of art and
                          science, encompassing engineering, design, the laws of
                          physics as well as human physiology and psychology.
                          Good lighting not only needs to perform the lighting
                          task adequately, but it also must fit completely into
                          the design environment and be â€œaesthetically
                          pleasingâ€, whether in use or not.
                        </p>
                        <p>
                          <a
                            href="illumination-designing.php"
                            className="redmore"
                          >
                            Read More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Epctraining;
