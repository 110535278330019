import React, { Component } from "react";
import SidebarUpcommingBatch from "./SidebarUpcommingBatch";
import SidebarRecentPlaceStudent from "./SidebarRecentPlaceStudent";
import SidebarGallery from "./SidebarGallery";
import SidebarMecciServices from "./SidebarMecciServices";
import SidebarEpcTraining from "./SidebarEpcTraining";
import SidebarCorporateServices from "./SidebarCorporateServices";
import SidebarItTraning from "./SidebarItTraning";
import SidebarNonItTraining from "./SidebarNonItTraining";
//import SidebarGallery from './SidebarGallery';
export class Sidebar extends Component {
  render() {
    return (
      <>
        <SidebarUpcommingBatch />
        <SidebarMecciServices />
        <SidebarEpcTraining />
        <SidebarCorporateServices />
        <SidebarItTraning />
        <SidebarNonItTraining />
      </>
    );
  }
}

export default Sidebar;
