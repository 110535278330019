import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";

export class AdvancedTallyTraining extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best Advanced Tally Training Course</title>
          <meta
            name="description"
            content="Best Advanced Tally Training Course"
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/advanced-tally-training"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Best Advanced Tally Training Course"
            imgsrc="assets/images/course/course-title-img/advanced-tally-training.jpg"
            alt="Best Advanced Tally Training Course"
            title="Best Advanced Tally Training Course"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  Payroll compliance not just involves complexity but also a
                  share of sensitivity on the topic. The payroll for an employee
                  includes his benefits, deductions and other allowances. It is
                  hence possible that employees raise their concerns and queries
                  over their payroll details. It becomes difficult for the
                  finance department to manage these queries and needs the
                  interference of human resources team who are skilled to handle
                  employees and their concerns. We offer compliance services
                  that perfectly meet your organisation's requirements. We
                  believe in building a collaborative global learning community.
                </p>
                <p>
                  Payroll compliance is the consolidated set of activities
                  involved in the production of the payroll, capturing the data
                  and controlling it along with the calculation of allowances,
                  deduction, benefits and the net wages. It also includes the
                  process of editing and dispatching the payslips and in
                  conformance with all legal and third party declarations.
                </p>
                <hr />
                <Table
                  caption="Offered Courses in Advanced Tally Training course"
                  disctitle="Course Related to Advanced Tally Training course"
                  disc1="Advanced Tally Training course (Full Time)"
                  pdf1="assets/images/course/courses-pdf/advanced-tally-training.pdf"
                  duration1="45 Days"
                  disc2="Advanced Tally Training course (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/advanced-tally-training.pdf"
                  duration2="3 Months"
                  disc3="Advanced Tally Training course (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/advanced-tally-training.pdf"
                  duration3="8 Days"
                  disc4="Advanced Tally Training course (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/advanced-tally-training.pdf"
                  duration4="6 Months"
                />
                <hr />
                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul>
                  <li>
                    Introduction to Human resource technology and Analytics
                  </li>
                  <li>Employee data, data sources and metrics</li>
                  <li>
                    The Employees Provident Funds and Miscellaneous Provision
                    Act - 1952 (EPF)
                  </li>
                  <li>
                    Correctly complete year end requirements and establish the
                    year-beginning requirements
                  </li>
                  <li>
                    Follow the proper policies, procedures, and documentation
                    requirements for garnishments and levies
                  </li>
                  <li>
                    Handle stock options, expense reimbursements, relocation,
                    and other expenses
                  </li>
                  <li>
                    The Contract Labour (Regulation & Abolition) Act - 1970
                  </li>
                  <li>The Child Labour (Prohibition & Regulation Act), 1986</li>
                  <li>The Minimum Wages Act-1948</li>
                  <li>
                    Properly identify, pay, and withhold taxes for employees
                  </li>
                  <li>
                    The Employment Exchange (Compulsory Notification of
                    Vacancies) ACT-1959
                  </li>
                  <li>Statutory benefits administration</li>
                  <li>
                    The Interstate Migrant Workmen (Regulation of Employment and
                    Conditions of Services) ACT, 1979
                  </li>
                  <li>The Industrial Employment (Standing Orders) ACT 1946</li>
                  <li>The Industrial Disputes ACT 1947</li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>
                    Help employees clearly understand what training programs can
                    speed up their career progress in their desired direction.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AdvancedTallyTraining;
