import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import { Helmet } from "react-helmet";
export class ProcessDesignWorkshop extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Mecciengineers- Top most Process Design Engineering Courses Online
            by Experts
          </title>
          <meta
            name="description"
            content="Top-rated Process Engineering Training.  We offer Process Design course in India, Sudan, Nigeria, Egypt, South Sudan, South Africa, Oman, Qatar, Dubai, Kuwait, UAE."
          />
          <meta
            name="keywords"
            content="Process Design Engineering Training Institute, Process Design Certificate Course training, Process Design Course, Process Design Engineering Course"
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/process-design-workshop"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Process Design Engineering Workshop"
            imgsrc="assets/images/course/course-title-img/process-design-engineering.jpg"
            alt="Process Design Engineering Workshop"
            title="Process Design Engineering Workshop"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  Process engineering design is the application of chemical,
                  mechanical, petroleum, gas and other engineering talents to
                  the process-related development, planning, designs, and
                  decisions required for economical and effective completion of
                  a process project. The Process Design Engineering is
                  considered as the brains of any Plant. Project Engineers are
                  playing a vital role in different industries in Design,
                  Fabrication, Commissioning, Production and Safety. This
                  training program enables process design engineers to design
                  process equipments, used widely in various industrial segments
                  like Oil & Gas Industry, Power Plant Industry, Pharmaceutical,
                  Chemical, Fertilizer, Food Processing Industries (FMCG), etc.
                </p>
                <p>
                  Although process design engineers are organizationally located
                  in research, technical service, economic evaluation, as well
                  as other specific departments, the usual arrangement is to
                  have them available to the engineering groups concerned with
                  developing the engineering details as well as to evaluate bids
                  for the various equipment items. Process design is usually a
                  much more specific group responsibility in engineering
                  contractor organizations than in a chemical or petrochemical
                  production company, and the degree of distinction varies with
                  the size of the organization.
                </p>
                <h3>Training Outline</h3>
                <p>
                  The average process engineer has the following
                  responsibilities: 1. prepares studies of process cycles and
                  systems for various product production or improvements or
                  changes in existing production units; prepares material and
                  heat balances; 2. prepares economic studies associated with
                  process performance; 3. designs and/or specifies equipment
                  items required to define the process flowsheet or flow system;
                  specifies corrosion-resistant materials of construction;
                </p>

                <hr />
                <table className="table table-responsive table-bordered">
                  <caption></caption>
                  <thead>
                    <tr>
                      <th scope="col">Course:</th>
                      <th scope="col">Process Design & Simulation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="info">
                      <th scope="row">Eligibility </th>
                      <td>Chemical & Mechanical Engineers</td>
                    </tr>
                    <tr className="warning">
                      <th scope="row">Duration:</th>
                      <td>35-40 hour</td>
                    </tr>
                    <tr className="info">
                      <th scope="row">Course Fee:</th>
                      <td>25,000 INR</td>
                    </tr>
                    <tr className="warning">
                      <th scope="row">Job opportunity </th>
                      <td>
                        In EPC and manufacturing industries, Engineering
                        consultancy
                      </td>
                    </tr>
                    <tr className="warning">
                      <th scope="row">Certification: </th>
                      <td>
                        Training Certificate to all & Merit Certificate based on
                        performance in Case study & test
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />
                <p>
                  4. evaluates competitive bids for equipment; 5. evaluates
                  operating data for existing or test equipment; 6. guides
                  flowsheet draftsmen in detailed flowsheet preparation.
                </p>
                <h3>Course Syllabus</h3>
                <p>
                  The process engineer also develops tests and interprets data
                  and information from the research pilot plant. He/she aids in
                  scaling up the research type flow cycle to one of commercial
                  feasibility. The process engineer must understand the
                  interrelationship between the various research, engineering,
                  purchasing, expediting, construction, and operational
                  functions of a project. He/she must appreciate that each
                  function may and often does affect or influence the process
                  design decisions. For example, it is futile to waste time
                  designing or calculating in detail when the basic components
                  of the design cannot be economically fabricated, or if capable
                  of being fabricated, cannot possibly be delivered by the
                  construction schedule for the project. Some specific phases of
                  a project that require process understanding include plant
                  layout, materials of construction for corrosion as well as
                  strength, start-up operations, troubleshooting, maintenance,
                  performance testing, and so forth.
                </p>
                <h3>Course Syllabus for Mechanical Design</h3>
                <ul>
                  <li>
                    Overview of Industry and role of Process Engineer in various
                    fields.
                  </li>
                  <li>Introduction to BFD, PFD, P&ID and PDS of equipments.</li>
                  <li>Relevant Codes and Standards used in industry.</li>
                  <li>
                    Mass and Energy balance - Design Basis, Sample Calculations
                    and Utility Sizing.
                  </li>
                  <li>
                    Interlocks, Control Philosophy, Valve Selection and sizing,
                    Safety valve sizing.
                  </li>
                  <li>Developing of PFD, PDS, P&I: Process and Utility.</li>
                  <li>Development of Basic Engineering Package (BEP).</li>
                  <li>Line Sizing & Pressure Drop Calculations.</li>
                  <li>Pump Selection and sizing, Reactor sizing.</li>
                  <li>
                    Heat Exchanger Design (Thermal Design), Distillation Column
                    Design.
                  </li>
                  <li>
                    Available software used in industry and their applications.
                  </li>
                  <li>Use of vendor data & Checking of Vendor data.</li>
                  <li>
                    Making Commissioning manual, HAZOP review & risk management.
                  </li>
                  <li>Interview Preparation</li>
                </ul>
                <p>
                  After successful completion of the Course, students can find
                  career growth in Design Consultancy, Equipment manufacturing
                  Companies, Production Units, and Inspection & Testing.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProcessDesignWorkshop;
