import React, { Component } from "react";
import BrandLogoData from "../pages/BrandLogoData";
export class Brandlogo extends Component {
  render() {
    return (
      <>
        <div className="rwstimonial">
          <h2>Our Partners</h2>
          <section className="customer-logos slider">
            <div className="slide">
              <img src={BrandLogoData[0].imgsrc} alt="company logo" width="100"/>
            </div>
            <div className="slide">
             <img src={BrandLogoData[1].imgsrc} alt="company logo" width="100"/>
            </div>
            <div className="slide">
             <img src={BrandLogoData[2].imgsrc} alt="company logo" width="100"/>
            </div>
            <div className="slide">
             <img src={BrandLogoData[3].imgsrc} alt="company logo" width="100"/>
            </div>
            <div className="slide">
             <img src={BrandLogoData[4].imgsrc} alt="company logo" width="100"/>
            </div>
            <div className="slide">
             <img src={BrandLogoData[5].imgsrc} alt="company logo" width="100"/>
            </div>
            <div className="slide">
             <img src={BrandLogoData[6].imgsrc} alt="company logo" width="100"/>
            </div>
            {/* <div className="slide">
             <img src={BrandLogoData[7].imgsrc} alt="company logo" width="100"/>
            </div> */}
            <div className="slide">
             <img src={BrandLogoData[8].imgsrc} alt="company logo" width="100"/>
            </div>
            {/* <div className="slide">
             <img src={BrandLogoData[9].imgsrc} alt="company logo" width="100"/>
            </div> */}
          
            <div className="slide">
             <img src={BrandLogoData[11].imgsrc} alt="company logo" width="100"/>
            </div>
            <div className="slide">
             <img src={BrandLogoData[12].imgsrc} alt="company logo" width="100"/>
            </div>
            <div className="slide">
             <img src={BrandLogoData[13].imgsrc} alt="company logo" width="100"/>
            </div>

          </section>
        </div>
      </>
    );
  }
}

export default Brandlogo;
