import { useState,useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Spinner from "../components/Loader";
import CourseList from "../components/CourseList";

function MyForm() {
  const [formData, setFormData] = useState({
    fname: "",
    email: "",
    phone: "",
    course:"",
    tmode:"",
    comment: "",
  });
const [resmessage, setResMessage] = useState("");
const [isLoading, setLoading] = useState(true);

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setFormData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(!isLoading);
    const fData = {
      method: "POST",
      url: "https://www.mecciengineer.com/mecci/public/api/savedata",
      Headers: {},
      data: {
        name: formData.fname,
        email: formData.email,
        phone: formData.phone,
        course: formData.course,
        type: "contact-us",
        city: "NA",
        altphone: "NA",
        carpetarea: "NA",
        training_mode: formData.tmode,
        description: formData.comment,
      },
    };
    let response = await axios(fData)
      .then(({ data }) => {
        
          setResMessage("data saved successfully");
          setLoading(isLoading);
          
         
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setValidationError(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
          setResMessage("Error! data not saved");
          
        }
      });
      setLoading(isLoading);
     
      
      setFormData({fname:'',email:'',phone:'',course:'',tmode:'',comment:''});
  };
 
  const [courses2,setCourses] =useState([]);

  useEffect(()=>{
    getAllCourse();
  },[])

  async function getAllCourse(){
    try{
      const res =await axios.get("https://www.mecciengineer.com/mecci/public/api/allcourse")

      setCourses(res.data.courses);
    }catch(error){
    //console.log('something wrong')
    console.log(error.message);
    }
  }

  return (
    <>
    
      <form onSubmit={handleSubmit} className="contact-form" method="post">
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="fname"
            value={formData.fname}
            onChange={inputEvent}
            placeholder="Name"
            required="required"
            autoComplete="off"
            autoFocus=""
          />
        </div>

        <div className="form-group form_left">
          <input
            type="email"
            className="form-control"
            name="email"
            value={formData.email}
            onChange={inputEvent}
            placeholder="Email"
            required="required"
            autoComplete="off"
          />
        </div>

        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="phone"
            value={formData.phone}
            onChange={inputEvent}
            maxLength="10"
            placeholder="Mobile No."
            required="required"
            autoComplete="off"
          />
        </div>


        <div className="form-group">
         <select name="course" className="form-control" onChange={inputEvent}>
         <option value=''>Select Course </option>
          {
          courses2.map((couritm)=>  <option value = {couritm.id} key={couritm.id}>{couritm.course_title}</option>)
          }
         </select>
        </div>

        <div className="form-group">
         <select name="tmode" className="form-control" onChange={inputEvent}>
         <option value=''>Select Training Mode </option>
				   <option value="campus-training">Campus Training</option>
				   <option value="online-traning">Online Training</option>
         </select>
        </div>
        
        <div className="form-group">
          <textarea
            className="form-control textarea-contact"
            rows="5"
            name="comment"
            value={formData.comment}
            onChange={inputEvent}
            placeholder="Type Your Message/Feedback here..."
            required=""
          ></textarea>
          <br />
          <button className="btn btn-default btn-send">
            <i className="fa fa-paper-plane" aria-hidden="true"></i>
            Send
          </button>
          
        </div>
        
          {!isLoading ?  <Spinner /> :null } 

        {
          resmessage
        }
      </form>
    </>
  );
}
export default MyForm;
