import React,{Component} from "react";
import { Link } from 'react-router-dom';
function Header(){
        return(
<>
<div style={{ background: "white" }}>
<div className="container-fluid">
             <div className="row">
             <div id="screensize"></div>
              <div className="hidden-xs col-sm-3 col-md-3 col-lg-3">
              <Link className="navbarbrand" to="/">
                    <img
                      src="assets/images/mecci.png"
                      alt="best training institute for Best Training Institute for IT course in Noida"
                      className="logo"
                    />
                  </Link>
              </div>
              
              <div className="hidden-xs col-sm-6 col-md-6 col-lg-6">
                     <img
                      src="assets/images/iaflogo.png"
                      alt="iaflogo"
                      className="otherlogo iaf"
                    />
                     <img
                      src="assets/images/uaflogo.png"
                      alt="isologo"
                      className="otherlogo uaf"
                    />
                      <img
                      src="assets/images/isologo.png"
                      alt="isologo"
                      className="otherlogo iso"
                    />
                     <img
                      src="assets/images/bmg.png"
                      alt="bmg"
                      className="otherlogo bmg"
                    />
                  
              </div>
           

              <div className="col-sm-12 col-md-12 col-lg-3">
              <div id="TopHead">
                  <div className="hidden-xs rightsec" id="Emailno">
                    <i
                      className="fa fa-mobile-phone"
                      style={{ fontSize: "24px" }}
                    ></i>
                    <span className="phone">
                      <Link to="tel:+91-9910988623">+91-9910988623</Link>
                    </span>
                  </div>
                  <div className="hidden-xs rightsec" id="Emailno">
                    <i
                      className="fa fa-whatsapp"
                      aria-hidden="true"
                      style={{ fontSize: "18px" }}
                    ></i>
                    <span className="phone">
                      <Link to="tel:+91-9958153868">+91-9958153868</Link>
                    </span>
                  </div>

                  <div className="rightsec" id="Emailno payu">
                    <a
                      href="https://www.payumoney.com/pay/#/merchant/4B61D5D2C2D32FE7E3B01B06C9605B09?param=5828826"
                      style={{
                        fontSize: "medium",
                        color: "rgb(11, 11, 12)",
                        textDecoration: "none",
                      }}
                      target="_blank"
                    >
                      <img
                        alt="Best Best Training Institute for IT training institute and company in noida"
                        width="60"
                        src="assets/images/payulogo.png" className="paynuimg hidden-xs"
                      />
                      <span className="payu">PayNow</span>
                    </a>
                  </div>
                </div>
              </div> 
                
    
                <div className="col-xs-9 hidden-sm hidden-md hidden-lg">
                  <Link className="navbar-brand" to="/">
                    <img
                      src="assets/images/mecci.png"
                      alt="best training institute for Best Training Institute for IT course in Noida"
                      className="logo"
                    />
                  </Link>
                </div>
              </div>
            
          </div>



        <nav className="navbar navbar-default">
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>

            <div id="navbar" className="navbar-collapse collapse">
              <ul className="nav navbar-nav nav-main">
                <li className="active">
                  <Link to="/">Home</Link>
                </li>
                <li className="certificate_li">
                <a href="#">Mecci Services</a>
                  <ul className="subul">
                    <li>
                      <Link to="/why-mecci">Why Mecci</Link>
                    </li>
                    <li>
                      <Link to="/engineering-services">Engineering Services</Link>
                    </li>
                    <li>
                      <Link to="/online-classroom-training">Online | Classroom Training</Link>
                    </li>
                    <li>
                      <Link to="/manpower-services">Manpower Services</Link>
                    </li>
                    <li>
                      <Link to="/blogs">Blogs</Link>
                    </li>
                    <li>
                    <Link to="/gallary">Gallary</Link> 
                    </li>
                  </ul>
                </li>
                <li className="certificate_li">
                <a href="#">
                    EPC Training 
                  </a>
                  <ul className="subul">
                    <li>
                      <Link to="/electrical-design-engineering">Electrical Design Engineering</Link>
                    </li>
                    <li>
                      <Link to="/piping-design-engineering">Piping Design Engineering</Link>
                    </li>
                    <li>
                      <Link to="/process-design-engineering">Process Design Engineering</Link>
                    </li>
                    <li>
                      <Link to="/instrumentation-design-engineering">Instrumentation Design Engineering</Link>
                    </li>
                    <li>
                      <Link to="/hvac-design-engineering">HVAC Design Engineering</Link>
                    </li>
                    <li>
                      <Link to="/civil-and-structural-design-engineering">Civil &amp; Structural Design Engineering</Link>
                    </li>
                    <li>
                      <Link to="/billing-engineering-training">Billing Engineering Training</Link>
                    </li>
                    <li>
                      <Link to="/planning-engineering-training">Planning Engineering Training</Link>
                    </li>
                    <li>
                      <Link to="/solar-power-plant-design-engineering">Solar Power Plant Design Engineering</Link>
                    </li>
                    <li>
                      <Link to="/substation-design-course">Substation Design Course</Link>
                    </li>
                    <li>
                      <Link to="/solar-structure-design-course">Solar Structure Design Course</Link>
                    </li>
                    <li>
                      <Link to="/hybrid-electric-vehicle-design-course">
                        Hybrid &amp; Electric Vehicle Design Course
                      </Link>
                    </li>
                    <li>
                      <Link to="/illumination-systems-designing-course">Illumination Systems Designing Course</Link>
                    </li>
                  </ul>
                </li>
                <li className="certificate_li">
                <a href="#">Corporate &nbsp;Services</a>
                  <ul className="subul carporate">
                    <li>
                      <Link to="/project-management-control">Project Management &amp; Control</Link>
                    </li>
                    <li>
                      <Link to="/solar-power-plant-design-engineering">Solar Power Plant Design Engineering</Link>
                    </li>
                    <li>
                      <Link to="/planning-package">Planning Package</Link>
                    </li>
                    <li>
                      <Link to="/instrumentation-design-engineering">Instrumentation Design Engineering</Link>
                    </li>
                    <li>
                      <Link to="/process-design-workshop">Process Design Workshop</Link>
                    </li>
                    <li>
                      <Link to="/45-days-industrial-training">45 Days Industrial Training</Link>
                    </li>
                    <li className="certificate_li_ul2">
                      <Link to="/design-and-detailed-engineering-training">
                        Design & Detailed Engineering Training &#160;
                        
                        <i className="fa fa-caret-right" aria-hidden="true"></i>
                      </Link>
                      <ul className="subul2 secondli" >
                        <li>
                          <Link to="/electrical-design-engineering">Electrical Design Engineering</Link>
                        </li>
                        <li>
                          <Link to="/piping-design-engineering">Piping Design Engineering</Link>
                        </li>
                        <li>
                          <Link to="/process-design-engineering">Process Design Engineering</Link>
                        </li>
                        <li>
                          <Link to="/instrumentation-design-engineering">Instrumentation Design Engineering</Link>
                        </li>
                        <li>
                          <Link to="/hvac-design-engineering">HVAC Design Engineering</Link>
                        </li>
                        <li>
                          <Link to="/civil-structural-design-engineering">
                            Civil &amp; Structural Design Engineering
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="certificate_li">
                <a href="#">
                    IT Training
                  </a>
                  <ul className="subul">
                    <li>
                      <Link to="/seo-traning">SEO Training</Link>
                    </li>
                    <li>
                      <Link to="/web-designing-training">Web Designing Training</Link>
                    </li>
                    <li>
                      <Link to="/core-java-training">Core Java Training</Link>
                    </li>
                    <li>
                      <Link to="/angular-js-training">Angular JS Training</Link>
                    </li>
                    <li>
                      <Link to="/dot-net-training">Dot NET Training</Link>
                    </li>
                    <li>
                      <Link to="/software-manual-testing">Software Manual Testing</Link>
                    </li>
                    <li>
                      <Link to="/software-automation-testing">Software Automation Testing</Link>
                    </li>
                    <li>
                      <Link to="/performance-testing">Performance Testing</Link>
                    </li>
                  </ul>
                </li>
                <li className="certificate_li">
                <a href="#">
                    NON-IT Training
                  </a>
                  <ul className="subul noneit">
                    <li>
                      <Link to="/accounts-gst-and-taxation-training">Accounts, GST &amp; Taxation Training</Link>
                    </li>
                    <li>
                      <Link to="/pay-roll-compliance-training">Pay-Roll Compliance Training</Link>
                    </li>
                    <li>
                      <Link to="/advanced-excel-for-accounting-training">Advanced Excel for Accounting Training</Link>
                    </li>
                    <li>
                      <Link to="/advanced-tally-training">Advanced Tally Training</Link>
                    </li>
                    <li>
                      <Link to="/hr-generalist-course">HR Generalist Course</Link>
                    </li>
                    <li className="certificate_li_ul2">
                      <Link to="#">
                        ERP Training &#160;
                        <i className="fa fa-caret-right" aria-hidden="true"></i>
                      </Link>
                      <ul className="subul2">
                        <li>
                          <Link to="/erp-sap-hr-training">ERP SAP HR Training</Link>
                        </li>
                        <li>
                          <Link to="/erp-sap-mm-training">ERP SAP MM Training</Link>
                        </li>
                        <li>
                          <Link to="/erp-sap-pm-training">ERP SAP PM Training</Link>
                        </li>
                        <li>
                          <Link to="/erp-sap-pp-training">ERP SAP PP Training</Link>
                        </li>
                        <li>
                          <Link to="/erp-sap-ps-training">ERP SAP PS Training</Link>
                        </li>
                        <li>
                          <Link to="/erp-sap-qm-training">ERP SAP QM Training</Link>
                        </li>
                        <li>
                          <Link to="/erp-sap-hana-training">ERP SAP HANA Training</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/sap-fico-training">SAP FICO Training</Link>
                    </li>
                    <li>
                      <Link to="/sap-simple-finance-training">SAP Simple Finance Training</Link>
                    </li>
                    <li>
                      <Link to="/sap-hr-hcm-training">SAP HR-HCM Training</Link>
                    </li>
                  </ul>
                </li>
                <li className="certificate_li">
                  <Link to="placement">Placement</Link>
                  <ul className="subul">
                    <li>
                      <Link to="/apply-jobs">Apply Jobs</Link>
                    </li>
                  </ul>
                </li>

                <li className="certificate_li">
                  <Link to="/partener">Partner</Link>
                </li>
                <li className="certificate_li">
                  <Link to="contact-us">Contact Us</Link>
                </li>
              </ul>
              <div className="headerRight">
                {/* <div id="rightTopHead">
                  <div id="Emailno">
                    <i
                      className="fa fa-mobile-phone"
                      style={{ fontSize: "24px" }}
                    ></i>
                    <span className="phone">
                      <Link to="tel:+91-9910988623">+91-9910988623</Link>
                    </span>
                  </div>
                  <div id="Emailno">
                    <i
                      className="fa fa-whatsapp"
                      aria-hidden="true"
                      style={{ fontSize: "18px" }}
                    ></i>
                    <span className="phone">
                      <Link to="tel:+91-9958153868">+91-9958153868</Link>
                    </span>
                  </div>

                  <div id="Emailno payu">
                    <a
                      href="https://www.payumoney.com/pay/#/merchant/4B61D5D2C2D32FE7E3B01B06C9605B09?param=5828826"
                      style={{
                        fontSize: "medium",
                        color: "rgb(11, 11, 12)",
                        textDecoration: "none",
                      }}
                      target="_blank"
                    >
                      <img
                        alt="Best Best Training Institute for IT training institute and company in noida"
                        width="60"
                        src="assets/images/payulogo.png"
                      />
                      <span className="payu">PayNow</span>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </nav>
      </div>
</>
        )
    }

export default Header;