const BrandLogoData = [
    {   
        id    :1,
        imgsrc:"assets/images/company_logo1.jpeg",
		alt   :"erp-sap-pp-training",
    },
    {   
        id    :2,
        imgsrc:"assets/images/company_logo2.jpeg",
		alt   :"erp-sap-pp-training",
    },
    {   
        id    :3,
        imgsrc:"assets/images/company_logo3.jpeg",
		alt   :"erp-sap-pp-training",
    },
    {   
        id    :4,
        imgsrc:"assets/images/company_logo4.jpeg",
		alt   :"erp-sap-pp-training",
    },
    {   
        id    :5,
        imgsrc:"assets/images/company_logo5.jpeg",
		alt   :"erp-sap-pp-training",
    },
    {   
        id    :6,
        imgsrc:"assets/images/company_logo6.jpeg",
		alt   :"erp-sap-pp-training",
    },
    {   
        id    :7,
        imgsrc:"assets/images/company_logo7.jpeg",
		alt   :"erp-sap-pp-training",
    },
    {   
        id    :8,
        imgsrc:"assets/images/company_logo8.jpeg",
		alt   :"erp-sap-pp-training",
    },
    {   
        id    :9,
        imgsrc:"assets/images/company_logo9.jpeg",
		alt   :"erp-sap-pp-training",
    },
    {   
        id    :10,
        imgsrc:"assets/images/company_logo10.jpeg",
		alt   :"erp-sap-pp-training",
    },
    {   
        id    :11,
        imgsrc:"assets/images/company_logo11.jpeg",
		alt   :"erp-sap-pp-training",
    },
    {   
        id    :12,
        imgsrc:"assets/images/company_logo12.jpeg",
		alt   :"erp-sap-pp-training",
    },

    {   
        id    :13,
        imgsrc:"assets/images/company_logo13.jpeg",
		alt   :"erp-sap-pp-training",
    },
    {   
        id    :14,
        imgsrc:"assets/images/company_logo14.jpeg",
		alt   :"erp-sap-pp-training",
    },
    {   
        id    :15,
        imgsrc:"assets/images/company_logo15.jpeg",
		alt   :"erp-sap-pp-training",
    },
    
   
   
	];
    export default BrandLogoData;
   