import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import BlogCard from "../components/BlogCard";
import BlogsData from "../pages/BlogsData";
import { Helmet } from "react-helmet";
export class Blogs extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Top most engineering training institute in India.</title>
          <meta
            name="classification"
            content="MECCI Engineers, ISO 9001:2015 Certified EPC Training Institute presents various customized courses for working persons and fresher engineers. We offer Civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course in India, Sudan, Nigeria, Egypt, Bangladesh, South Sudan &amp; South Africa, Oman, qatar, Dubai, kuwait, UAE."
          />
          <meta
            name="keywords"
            content="Civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course "
          />
        </Helmet>
        <Helmet>
          <link rel="canonical" href="https://www.mecciengineer.com/blogs" />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Blogs"
            imgsrc="assets/images/course/course-title-img/blog.jpg"
            alt="blogs"
            title="Blogs"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <BlogCard
                title={BlogsData[0].title}
                imgsrc={BlogsData[0].imgsrc}
                alt={BlogsData[0].alt}
                desc={BlogsData[0].desc}
                link={BlogsData[0].link}
              />
              <BlogCard
                title={BlogsData[1].title}
                imgsrc={BlogsData[1].imgsrc}
                alt={BlogsData[1].alt}
                desc={BlogsData[1].desc}
                link={BlogsData[1].link}
              />
              <BlogCard
                title={BlogsData[2].title}
                imgsrc={BlogsData[2].imgsrc}
                alt={BlogsData[2].alt}
                desc={BlogsData[2].desc}
                link={BlogsData[2].link}
              />
              <BlogCard
                title={BlogsData[3].title}
                imgsrc={BlogsData[3].imgsrc}
                alt={BlogsData[3].alt}
                desc={BlogsData[3].desc}
                link={BlogsData[3].link}
              />
              <BlogCard
                title={BlogsData[4].title}
                imgsrc={BlogsData[4].imgsrc}
                alt={BlogsData[4].alt}
                desc={BlogsData[4].desc}
                link={BlogsData[4].link}
              />
              <BlogCard
                title={BlogsData[5].title}
                imgsrc={BlogsData[5].imgsrc}
                alt={BlogsData[5].alt}
                desc={BlogsData[5].desc}
                link={BlogsData[5].link}
              />
              <BlogCard
                title={BlogsData[6].title}
                imgsrc={BlogsData[6].imgsrc}
                alt={BlogsData[6].alt}
                desc={BlogsData[6].desc}
                link={BlogsData[6].link}
              />
              <BlogCard
                title={BlogsData[7].title}
                imgsrc={BlogsData[7].imgsrc}
                alt={BlogsData[7].alt}
                desc={BlogsData[7].desc}
                link={BlogsData[7].link}
              />
              <BlogCard
                title={BlogsData[8].title}
                imgsrc={BlogsData[8].imgsrc}
                alt={BlogsData[8].alt}
                desc={BlogsData[8].desc}
                link={BlogsData[8].link}
              />
              <BlogCard
                title={BlogsData[9].title}
                imgsrc={BlogsData[9].imgsrc}
                alt={BlogsData[9].alt}
                desc={BlogsData[9].desc}
                link={BlogsData[9].link}
              />
              <BlogCard
                title={BlogsData[10].title}
                imgsrc={BlogsData[10].imgsrc}
                alt={BlogsData[10].alt}
                desc={BlogsData[10].desc}
                link={BlogsData[10].link}
              />
              <BlogCard
                title={BlogsData[11].title}
                imgsrc={BlogsData[11].imgsrc}
                alt={BlogsData[11].alt}
                desc={BlogsData[11].desc}
                link={BlogsData[11].link}
              />
              <BlogCard
                title={BlogsData[12].title}
                imgsrc={BlogsData[12].imgsrc}
                alt={BlogsData[12].alt}
                desc={BlogsData[12].desc}
                link={BlogsData[12].link}
              />
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Blogs;
