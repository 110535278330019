import { useLocation } from "react-router-dom";
import Slider from "../components/Slider";
import News from "../components/News";
import Coursecrousel from "../components/Coursecrousel";
import Brandlogo from "../components/Brandlogo";
import Sidebar from "../components/Sidebar";
import Testimonials from "../components/Testimonials";
import CourseCard from "../components/CourseCard";
import CourseData from "../pages/CourseData";
import HomeSliderData from "../pages/HomeSliderData";
import HomeSideBar from "../components/HomeSideBar";
import { Helmet } from "react-helmet";
function Home() {
  return (
    <>
      <Helmet>
        <title>
          Top most Engineering Institute- Job Assistance courses online
        </title>
        <meta
          name="description"
          content="MECCI Engineers--Engineering Design and Power Training Institute in Noida. EPC Training Institute presents various advanced training courses for working persons and fresher engineers. We offer Civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course in India, Sudan, Nigeria, Egypt, Bangladesh, South Sudan and South Africa, Oman, Qatar, Dubai, Kuwait, UAE."
        />
        <meta
          name="keywords"
          content="Process Design Engineering Training, Electrical Design Engineering Training Institute, Instrumentation Design Engineering Course Instrumentation Design Training, HVAC Design Engineering Course, Civil Design Engineering Course, Solar Design Engineering Courses in Solar Design Engineering, Illumination Systems Designing Course"
        />
      </Helmet>

      <Slider />
      <News />

      <Coursecrousel />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 col-md-8a">
            <div className="colright">
              <h1>
                <i
                  className="fa fa-home"
                  style={{ fontSize: "32px", color: "#fe990c" }}
                ></i>
                MECCI Noida - Best Training Institute in Noida
              </h1>
              <CourseCard
                title={CourseData[0].title}
                imgsrc={CourseData[0].imgsrc}
                alt={CourseData[0].alt}
                link={CourseData[0].link}
              />
              <CourseCard
                title={CourseData[1].title}
                imgsrc={CourseData[1].imgsrc}
                alt={CourseData[1].alt}
                //desc={CourseData[1].desc}
                link={CourseData[1].link}
              />
              <CourseCard
                title={CourseData[2].title}
                imgsrc={CourseData[2].imgsrc}
                alt={CourseData[2].alt}
                //desc={CourseData[2].desc}
                link={CourseData[2].link}
              />
              <CourseCard
                title={CourseData[3].title}
                imgsrc={CourseData[3].imgsrc}
                alt={CourseData[3].alt}
                //desc={CourseData[3].desc}
                link={CourseData[3].link}
              />
              <CourseCard
                title={CourseData[4].title}
                imgsrc={CourseData[4].imgsrc}
                alt={CourseData[4].alt}
                // desc={CourseData[4].desc}
                link={CourseData[4].link}
              />
              <CourseCard
                title={CourseData[5].title}
                imgsrc={CourseData[5].imgsrc}
                alt={CourseData[5].alt}
                // desc={CourseData[5].desc}
                link={CourseData[5].link}
              />
              <h1>Training</h1>
              <CourseCard
                title={CourseData[6].title}
                imgsrc={CourseData[6].imgsrc}
                alt={CourseData[6].alt}
                // desc={CourseData[6].desc}
                link={CourseData[6].link}
              />
              <CourseCard
                title={CourseData[7].title}
                imgsrc={CourseData[7].imgsrc}
                alt={CourseData[7].alt}
                // desc={CourseData[7].desc}
                link={CourseData[7].link}
              />
              <CourseCard
                title={CourseData[8].title}
                imgsrc={CourseData[8].imgsrc}
                alt={CourseData[8].alt}
                // desc={CourseData[8].desc}
                link={CourseData[8].link}
              />
              <CourseCard
                title={CourseData[9].title}
                imgsrc={CourseData[9].imgsrc}
                alt={CourseData[9].alt}
                // desc={CourseData[9].desc}
                link={CourseData[9].link}
              />
              <CourseCard
                title={CourseData[10].title}
                imgsrc={CourseData[10].imgsrc}
                alt={CourseData[10].alt}
                // desc={CourseData[10].desc}
                link={CourseData[10].link}
              />
              <CourseCard
                title={CourseData[11].title}
                imgsrc={CourseData[11].imgsrc}
                alt={CourseData[11].alt}
                // desc={CourseData[11].desc}
                link={CourseData[11].link}
              />
              <CourseCard
                title={CourseData[12].title}
                imgsrc={CourseData[12].imgsrc}
                alt={CourseData[12].alt}
                // desc={CourseData[12].desc}
                link={CourseData[12].link}
              />
              <CourseCard
                title={CourseData[13].title}
                imgsrc={CourseData[13].imgsrc}
                alt={CourseData[13].alt}
                // desc={CourseData[13].desc}
                link={CourseData[13].link}
              />
              <CourseCard
                title={CourseData[14].title}
                imgsrc={CourseData[14].imgsrc}
                alt={CourseData[14].alt}
                // desc={CourseData[14].desc}
                link={CourseData[14].link}
              />
              <CourseCard
                title={CourseData[15].title}
                imgsrc={CourseData[15].imgsrc}
                alt={CourseData[15].alt}
                // desc={CourseData[15].desc}
                link={CourseData[15].link}
              />
              <CourseCard
                title={CourseData[16].title}
                imgsrc={CourseData[16].imgsrc}
                alt={CourseData[16].alt}
                // desc={CourseData1[16].desc}
                link={CourseData[16].link}
              />
              <CourseCard
                title={CourseData[17].title}
                imgsrc={CourseData[17].imgsrc}
                alt={CourseData[17].alt}
                // desc={CourseData[17].desc}
                link={CourseData[17].link}
              />
              <CourseCard
                title={CourseData[18].title}
                imgsrc={CourseData[18].imgsrc}
                alt={CourseData[18].alt}
                // desc={CourseData[18].desc}
                link={CourseData[18].link}
              />
              <div className="clearfix"></div>
              <h1>Corporate and Services</h1>

              <CourseCard
                title={CourseData[19].title}
                imgsrc={CourseData[19].imgsrc}
                alt={CourseData[19].alt}
                // desc={CourseData[19].desc}
                link={CourseData[19].link}
              />
              <CourseCard
                title={CourseData[20].title}
                imgsrc={CourseData[20].imgsrc}
                alt={CourseData[20].alt}
                // desc={CourseData[20].desc}
                link={CourseData[20].link}
              />
              <CourseCard
                title={CourseData[21].title}
                imgsrc={CourseData[21].imgsrc}
                alt={CourseData[21].alt}
                // desc={CourseData[21].desc}
                link={CourseData[21].link}
              />

              <CourseCard
                title={CourseData[22].title}
                imgsrc={CourseData[22].imgsrc}
                alt={CourseData[22].alt}
                // desc={CourseData[22].desc}
                link={CourseData[22].link}
              />
              <CourseCard
                title={CourseData[23].title}
                imgsrc={CourseData[23].imgsrc}
                alt={CourseData[23].alt}
                // desc={CourseData[23].desc}
                link={CourseData[23].link}
              />
              <CourseCard
                title={CourseData[24].title}
                imgsrc={CourseData[24].imgsrc}
                alt={CourseData[24].alt}
                // desc={CourseData[24].desc}
                link={CourseData[24].link}
              />
              <div className="clearfix"></div>
              <h1>IT Training</h1>
              <CourseCard
                title={CourseData[25].title}
                imgsrc={CourseData[25].imgsrc}
                alt={CourseData[25].alt}
                // desc={CourseData[25].desc}
                link={CourseData[25].link}
              />

              <CourseCard
                title={CourseData[26].title}
                imgsrc={CourseData[26].imgsrc}
                alt={CourseData[26].alt}
                // desc={CourseData[26].desc}
                link={CourseData[26].link}
              />
              <CourseCard
                title={CourseData[27].title}
                imgsrc={CourseData[27].imgsrc}
                alt={CourseData[27].alt}
                // desc={CourseData[27].desc}
                link={CourseData[27].link}
              />
              <CourseCard
                title={CourseData[28].title}
                imgsrc={CourseData[28].imgsrc}
                alt={CourseData[28].alt}
                // desc={CourseData[28].desc}
                link={CourseData[28].link}
              />
              <CourseCard
                title={CourseData[29].title}
                imgsrc={CourseData[29].imgsrc}
                alt={CourseData[29].alt}
                // desc={CourseData[29].desc}
                link={CourseData[29].link}
              />
              <CourseCard
                title={CourseData[30].title}
                imgsrc={CourseData[30].imgsrc}
                alt={CourseData[30].alt}
                // desc={CourseData[30].desc}
                link={CourseData[30].link}
              />
              <CourseCard
                title={CourseData[31].title}
                imgsrc={CourseData[31].imgsrc}
                alt={CourseData[31].alt}
                // desc={CourseData[31].desc}
                link={CourseData[31].link}
              />
              <CourseCard
                title={CourseData[32].title}
                imgsrc={CourseData[32].imgsrc}
                alt={CourseData[32].alt}
                // desc={CourseData[32].desc}
                link={CourseData[32].link}
              />
              <div className="clearfix"></div>
              <h1>NON-IT Training</h1>

              <CourseCard
                title={CourseData[33].title}
                imgsrc={CourseData[33].imgsrc}
                alt={CourseData[33].alt}
                // desc={CourseData[33].desc}
                link={CourseData[33].link}
              />
              <CourseCard
                title={CourseData[34].title}
                imgsrc={CourseData[34].imgsrc}
                alt={CourseData[34].alt}
                // desc={CourseData[34].desc}
                link={CourseData[34].link}
              />
              <CourseCard
                title={CourseData[35].title}
                imgsrc={CourseData[35].imgsrc}
                alt={CourseData[35].alt}
                // desc={CourseData[35].desc}
                link={CourseData[35].link}
              />
              <CourseCard
                title={CourseData[36].title}
                imgsrc={CourseData[36].imgsrc}
                alt={CourseData[36].alt}
                // desc={CourseData[36].desc}
                link={CourseData[36].link}
              />
              <CourseCard
                title={CourseData[37].title}
                imgsrc={CourseData[37].imgsrc}
                alt={CourseData[37].alt}
                // desc={CourseData[37].desc}
                link={CourseData[37].link}
              />

              <CourseCard
                title={CourseData[38].title}
                imgsrc={CourseData[38].imgsrc}
                alt={CourseData[38].alt}
                // desc={CourseData[38].desc}
                link={CourseData[38].link}
              />
              <CourseCard
                title={CourseData[39].title}
                imgsrc={CourseData[39].imgsrc}
                alt={CourseData[39].alt}
                // desc={CourseData[39].desc}
                link={CourseData[39].link}
              />
              <CourseCard
                title={CourseData[40].title}
                imgsrc={CourseData[40].imgsrc}
                alt={CourseData[40].alt}
                // desc={CourseData[40].desc}
                link={CourseData[40].link}
              />
              <CourseCard
                title={CourseData[41].title}
                imgsrc={CourseData[41].imgsrc}
                alt={CourseData[41].alt}
                // desc={CourseData[41].desc}
                link={CourseData[41].link}
              />
              <CourseCard
                title={CourseData[42].title}
                imgsrc={CourseData[42].imgsrc}
                alt={CourseData[42].alt}
                // desc={CourseData[42].desc}
                link={CourseData[42].link}
              />
              <CourseCard
                title={CourseData[43].title}
                imgsrc={CourseData[43].imgsrc}
                alt={CourseData[43].alt}
                // desc={CourseData[43].desc}
                link={CourseData[43].link}
              />
              <CourseCard
                title={CourseData[44].title}
                imgsrc={CourseData[44].imgsrc}
                alt={CourseData[44].alt}
                // desc={CourseData[44].desc}
                link={CourseData[44].link}
              />
              <CourseCard
                title={CourseData[45].title}
                imgsrc={CourseData[45].imgsrc}
                alt={CourseData[45].alt}
                // desc={CourseData[45].desc}
                link={CourseData[45].link}
              />
              <CourseCard
                title={CourseData[46].title}
                imgsrc={CourseData[46].imgsrc}
                alt={CourseData[46].alt}
                // desc={CourseData[46].desc}
                link={CourseData[46].link}
              />
              <CourseCard
                title={CourseData[47].title}
                imgsrc={CourseData[47].imgsrc}
                alt={CourseData[47].alt}
                // desc={CourseData[47].desc}
                link={CourseData[47].link}
              />

              <div className="clearfix"></div>
              <div className="clearfix"></div>
            </div>
            <Testimonials />
            <Brandlogo />
          </div>
          <div className="col-md-4 col-md-4a">
            <HomeSideBar />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
