import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import { Helmet } from "react-helmet";

export class EngineeringServices extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Top most engineering services institute in India.</title>
          <meta
            name="classification"
            content="MECCI Engineers, ISO 9001:2015 Certified EPC Training Institute presents various customized courses for working persons and fresher engineers. We offer Civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course in India, Sudan, Nigeria, Egypt, Bangladesh, South Sudan &amp; South Africa, Oman, Qatar, Dubai, Kuwait, UAE."
          />
          <meta
            name="keywords"
            content="top most engineering services in India,  Civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course "
          />
          <meta
            name="classification"
            content="Engineering Design &amp; Power Training Institute in Noida- MECCI Engineers, ISO 9001:2015 Certified EPC Training Institute presents various customized courses for working persons and fresher engineers. We offer Civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course in India, Sudan, Nigeria, Egypt, Bangladesh, South Sudan &amp; South Africa, Oman, Qatar, Dubai, Kuwait, UAE."
          />
        </Helmet>
        <Helmet>
          <link rel="canonical" href="https://www.mecciengineer.com/engineering-services" />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Engineering Design Courses"
            imgsrc="assets/images/course/course-title-img/engineering-services.jpg"
            alt="engineering-design-courses"
            title="Engineering Design Courses"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <h3>Plant Engineering Services :</h3>
                <p>
                  We, the MECCI Engineers working as one of the emerging Plant
                  Engineering organization, we provide our services to various
                  process industrial facilities i.e. Oil & Gas, Chemical, Pulp &
                  Paper Plant, Power Plant, Synthetic Fuel Plant, Offshore
                  Platforms, Pipeline Installation, Water Treatment Facilities,
                  Environmental Waste Disposal and etc.
                </p>
                <p>
                  Our engineering services include, engineering solutions
                  related to Process, Piping, Mechanical Equipment, HVAC,
                  Electrical & Instrumentation, Civil & Structural Engineering.
                  Further our expertise extends to provide Planning and Project
                  Management Services.
                </p>
                <p>
                  MECCI Engineers has professional engineers having relevant
                  industrial experience in all kind of projects. Our excellence
                  stems from a service suite that shortens lead times, extends
                  capacity and delivers time, cost and engineering efficiencies.
                </p>
                <p>
                  Our plant engineering services Providing a Business Advantage
                  to Organizations in the Power & Process facilities.
                </p>
                <ul>
                  <li>Conceptual studies</li>
                  <li>Feasibility Studies</li>
                  <li>Basic Engineering</li>
                  <li>
                    Design Engineering Services
                    <ul>
                      <li>Green field Engineering</li>
                      <li>Brown field Engineering</li>
                    </ul>
                  </li>
                  <li>Conceptual studies</li>
                  <li>Procurement Assistance</li>
                  <li>Commissioning assistance</li>
                  <li>Project management services.</li>
                </ul>
                <h3>Feature:</h3>
                <ul>
                  <li>Plant capacity optimization</li>
                  <li>Throughput analysis</li>
                  <li>De-bottlenecking</li>
                  <li>Lesser R&D pilot trials</li>
                  <li>Accurate FEED basis</li>
                  <li>Cost analysis</li>
                  <li>Equipment design basis</li>
                  <li>
                    Improving and accelerating process innovation, thereby
                    reducing
                  </li>
                  <li>time-to-market for new products</li>
                  <li>
                    Optimizing plant performance and reducing process
                    variability
                  </li>
                  <li>
                    given the trade-offs between capacity, yield and energy
                  </li>
                  <li>
                    Standardizing manufacturing workflow around best practices
                    for
                  </li>
                  <li>high quality and high yield</li>
                  <li>
                    Improving capital efficiency through maximum asset
                    utilization
                  </li>
                  <li>Reducing environmental risk</li>
                  <li>Reduce waste and energy consumption</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EngineeringServices;
