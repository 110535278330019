import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";
export class WebDesigningTraining extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Top most web designing institute in India</title>
          <meta
            name="classification"
            content="MECCI Engineers- Top most web designing training institute for various customized courses for working persons and fresher engineers. We offer Civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course in India, Sudan, Nigeria, Egypt, Bangladesh, South Sudan &amp; South Africa, Oman, qatar, Dubai, kuwait, UAE."
          />
          <meta
            name="keywords"
            content="Web designing course in India,  Civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course "
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/web-designing-training"
          />
        </Helmet>
        <div className="container-fluid">
          <PageTitle
            page_header_title="Best Web Development Courses"
            imgsrc="assets/images/course/course-title-img/web-development-courses.jpg"
            alt="Best Web Development Courses"
            title="Best Web Development Courses"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  Web designing course Online and In-ClassName modes of training
                  in India for Professionals and beginners to learn latest web
                  creation skills. Web designing training is necessary for all
                  those who want to make their own web site or work as
                  professional making career as a web developer.
                </p>
                <p>
                  Our website designing training in Noida is planned for
                  students by keeping in mind the technical requirements and for
                  overall development of them. A web designer in terms of web
                  designing is regularly in charge of the format, shading plan,
                  and general outline of a site. We assure for knowledge, so
                  once your get job then your training will end.
                </p>

                <hr />
                <Table
                  caption="Offered Courses in Web Development Courses Training"
                  disctitle="Course Related to Web Development Courses Training"
                  disc1="Web Development Courses Training (Full Time)"
                  pdf1="assets/images/course/courses-pdf/web-designing-training.pdf"
                  duration1="45 Days"
                  disc2="Web Development Courses Training (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/web-designing-training.pdf"
                  duration2="3 Months"
                  disc3="Web Development Courses Training (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/web-designing-training.pdf"
                  duration3="8 Days"
                  disc4="Web Development Courses Training (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/web-designing-training.pdf"
                  duration4="6 Months"
                />

                <hr />

                <h3>Note: All above coures avaliable in ONLINE</h3>

                <h3>What You Will Learn</h3>
                <ul>
                  <li>A Quick Overview of Web Development</li>
                  <li>ntroduction to HTML</li>
                  <li>Paragraphs, Headings and Text and HTML Links</li>
                  <li>HTML Images & HTML Lists</li>
                  <li>HTML Tables & HTML Forms</li>
                  <li>Crash Course in CSS</li>
                  <li>CSS Fonts & CSS Text</li>
                  <li>
                    Colors and Backgrounds, CSS and Links & Custom Cursors
                  </li>
                  <li>Borders, Margins and Padding</li>
                  <li>
                    Overview of Java Script & Building a Java Script Program
                  </li>
                  <li>Basic Syntax Used in Java Script Commands</li>
                  <li>Variables & Functions</li>
                  <li>
                    Flow Control Structures, Operators & String Processing
                  </li>
                  <li>Overview of J-QUERY(JavaScript-framework)</li>
                  <li>GIF Image Animator</li>
                  <li>DreameViewer, LogoMaker & Photoshop</li>
                  <li>Hosting-panel & Filezilla</li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>
                    Help employees clearly understand what training programs can
                    speed up their career progress in their desired direction.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>
                    Preparation for Interview and Mock Interview Sessions.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WebDesigningTraining;
