import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import PageTitle from "../components/PageTitle";
import Table from "../components/TableCard";
import { Helmet } from "react-helmet";

export class Seotraining extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Top most SEO training institute in India.</title>
          <meta
            name="classification"
            content="MECCI Engineers, Top Most SEO Training Institute presents various customized courses for working persons and fresher engineers. We offer Civil Design Course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course in India, Sudan, Nigeria, Egypt, Bangladesh, South Sudan and South Africa, Oman, Qatar, Dubai, Kuwait, UAE."
          />
          <meta
            name="keywords"
            content="SEO training, Digital marketing, civil Design course, Piping Design Course, Process Design course, Electrical Design course, HVAC design Course, Instrumentation design course "
          />
        </Helmet>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.mecciengineer.com/seo-traning"
          />
        </Helmet>

        <div className="container-fluid">
          <PageTitle
            page_header_title="SEO Training Course"
            imgsrc="assets/images/course/course-title-img/seo-training.jpg"
            alt="SEO Training Course"
            title="SEO Training Course"
          />
          <div className="row">
            <div className="col-md-8 col-md-8a">
              <div className="colright">
                <p>
                  SEO or Search Engine Optimization is the medium by which any
                  website, however ordinary can pull itself up to the top. All
                  search engine have the algorithm on which base it shows the
                  results and website must go through that algorithms. All
                  search engine works in calculation of the website pagesâ€™
                  authority score to give its ranking as it has crawled and
                  indexed that page, which provided in the best searches for the
                  keyword input.
                </p>
                <p>
                  SEO Course is not only done by professionals and jobs seekers,
                  it is of the most recommended course for Startups & Business
                  Owners. Search Engine Optimization can help you to gain more
                  targeted visitors for your websites through Google and other
                  search engines. It also includes enhancing the quality and
                  quantity of your website or web page. If your website is
                  ranked higher on the search results engine and is shown more
                  frequently in a web search results list, then it increases the
                  number of visuits from the search engine's users which will
                  help to convert those visitors into customers.
                </p>

                <hr />
                <Table
                  caption="Offered Courses in SEO Training Course"
                  disctitle="Course Related to SEO Training"
                  disc1="SEO Training Course (Full Time)"
                  pdf1="assets/images/course/courses-pdf/seo-training-course.pdf"
                  duration1="45 Days"
                  disc2="SEO Training Course (Only Sunday)"
                  pdf2="assets/images/course/courses-pdf/seo-training-course.pdf"
                  duration2="3 Months"
                  disc3="SEO Training Course (Fastrack Batch)"
                  pdf3="assets/images/course/courses-pdf/seo-training-course.pdf"
                  duration3="8 Days"
                  disc4="SEO Training Course (Correspondence)"
                  pdf4="assets/images/course/courses-pdf/seo-training-course.pdf"
                  duration4="6 Months"
                />

                <hr />

                <h3>Note: All above coures avaliable in ONLINE</h3>
                <h3>What You Will Learn</h3>
                <ul>
                  <li>SEO concepts, need for SEO</li>
                  <li>Website Analysis</li>
                  <li>SEO Competition Analysis</li>
                  <li>Keyword Research & Keyword Finalize</li>
                  <li>Initial Ranking analysis</li>
                  <li>Meta tag creation & optimization</li>
                  <li>Robots.txt Optimization & RSS Feed Generation</li>
                  <li>Web master tools & Google Base Optimization</li>
                  <li>Article submission & SEO Content writing</li>
                  <li>
                    Blog commenting, Forum posting & Press Release Creation
                  </li>
                  <li>
                    Social Networking Profile Optimizations & Content Sharing
                    Optimization
                  </li>
                  <li>
                    Search Engine Marketing Research & Pay per click (PPC)
                    campaigns
                  </li>
                  <li>Profile optimization & Relationship building</li>
                  <li>
                    CTR Improvements tactics & ROI conversion optimization
                  </li>
                  <li>Website Analysis Tools & Trend Analysis Tools</li>
                </ul>
                <h3>Training Features</h3>
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Job Oriented Training Program based on current Industry
                    Demand.
                  </li>
                  <li>
                    We Offer the best SEO Programming training and dedicated
                    placement assistance in Noida with properly planned training
                    modules and course content.
                  </li>
                  <li>Expert and Experienced Faculty from the Industry.</li>
                  <li>Smart Labs with Real Latest Equipmentâ€™s.</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-md-4a">
              <Sidebar />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Seotraining;
